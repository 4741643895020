import useUser from "../hooks/useUser";
import {
    Box, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import React, {PropsWithChildren, useState} from "react";
import useApiCall from "../hooks/CancellableApiCall";
import {useDebounce} from "usehooks-ts";
import {BlockUi} from "../components/BlockUi";
import {visuallyHidden} from '@mui/utils';
import {RankChip} from "../components/RankChip";
import {PlpdRankSummary} from "../data/UserSummary";
import {PoliceUser} from "../components/PoliceUser";


interface UserInfoSearchSummary {
    communityId: string,
    nick: string,
    rpName: string,
    badgeNumber?: number,
    policeRank?: PlpdRankSummary,
    divisions?: string,
    specialistRoles?: string
}

interface UserSearchResponse {
    page: number,
    pageSize: number,
    totalHits: number,
    data: UserInfoSearchSummary[]
}

interface SortableTableHeaderProps extends PropsWithChildren {
    onSortPressed: (ascending: boolean) => void,
    ascending: boolean,
    active: boolean
}

const SortableTableHeader = (props: SortableTableHeaderProps) => {
    let direction: "desc" | "asc" = "desc"
    if (props.ascending) {
        direction = "asc"
    }

    return (
        <TableCell>
            <TableSortLabel active={props.active} direction={props.active ? direction : "asc"}
                            onClick={_ => {
                                if (props.active) {
                                    props.onSortPressed(!props.ascending)
                                } else {
                                    props.onSortPressed(true)
                                }
                            }}
            >
                {props.children}
                {props.active ? (
                    <Box component="span" sx={visuallyHidden}>
                        {props.ascending ? 'sorted ascending' : 'sorted descending'}
                    </Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    )
}

type availableSortFields = "badge_number" | "nick" | "rp_name" | "police_rank"

interface EmployeesTableHeaderProps {
    onSortSelected: (sortField: availableSortFields, ascending: boolean) => void,
    selectedSortField: availableSortFields,
    ascending: boolean
}

const EmployeesTableHeader = (props: EmployeesTableHeaderProps) => {
    return (
        <TableHead>
            <TableRow>
                <SortableTableHeader ascending={props.ascending}
                                     active={props.selectedSortField === "nick"}
                                     onSortPressed={(e) => props.onSortSelected("nick", e)}>
                    Steam Name</SortableTableHeader>
                <SortableTableHeader ascending={props.ascending}
                                     active={props.selectedSortField === "rp_name"}
                                     onSortPressed={(e) => props.onSortSelected("rp_name", e)}>
                    In-Game Name</SortableTableHeader>
                <SortableTableHeader ascending={props.ascending}
                                     active={props.selectedSortField === "badge_number"}
                                     onSortPressed={(e) => props.onSortSelected("badge_number", e)}>
                    Badge Number</SortableTableHeader>
                <SortableTableHeader ascending={props.ascending}
                                     active={props.selectedSortField === "police_rank"}
                                     onSortPressed={(e) => props.onSortSelected("police_rank", e)}>
                    Police Rank</SortableTableHeader>
                <TableCell>Divisions</TableCell>
                <TableCell>Specialist Roles</TableCell>
            </TableRow>
        </TableHead>
    )
}

interface EmployeeRowProps {
    employee: UserInfoSearchSummary
}

const EmployeeRow = ({employee}: EmployeeRowProps) => {
    return (
        <TableRow>
            <TableCell><PoliceUser user={employee}/></TableCell>
            <TableCell>{employee.rpName}</TableCell>
            <TableCell>{employee.badgeNumber}</TableCell>
            <TableCell>{employee.policeRank &&
                <RankChip rank={employee.policeRank.displayName} color={employee.policeRank.color}/>}</TableCell>
            <TableCell>{employee.divisions ?? "None"}</TableCell>
            <TableCell>{employee.specialistRoles ?? "None"}</TableCell>
        </TableRow>
    )
}

export const EmployeesPage = () => {
    useUser()

    const [searchQuery, setSearchQuery] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [ascending, setAscending] = useState(false)
    const [sortField, setSortField] = useState<availableSortFields>("police_rank")
    const debouncedSearchQuery = useDebounce(searchQuery, 200)

    let queryParams = new URLSearchParams({
        page: page.toString(),
        query: debouncedSearchQuery,
        ascending: ascending.toString(),
        sort_by: sortField,
        page_size: pageSize.toString()
    })

    const apiCall = useApiCall<UserSearchResponse>({
        method: "POST",
        url: `/api/user/search?${queryParams.toString()}`
    })

    const employees = apiCall.data?.data

    return (
        <Paper variant={"outlined"} sx={{p: 2}}>
            <BlockUi open={apiCall.isLoading}>
                <Grid container spacing={2}>
                    <Grid sx={{p: 2}} item xs={12} sm={12} md={12} lg={6}>
                        <TextField fullWidth variant="standard" label="Search..." value={searchQuery}
                                   onChange={(e) => {
                                       setPage(1)
                                       setSearchQuery(e.target.value)
                                   }}
                        />
                        <div style={{flexGrow: 1}}/>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <TablePagination count={apiCall.data?.totalHits ?? 0}
                                         page={(apiCall.data?.page ?? 1) - 1}
                                         component="div"
                                         onPageChange={(e, newPage) => {
                                             setPage(newPage + 1)
                                         }}
                                         rowsPerPage={pageSize}
                                         onRowsPerPageChange={(e) => {
                                             setPageSize(parseInt(e.target.value))
                                             setPage(1)
                                         }}
                        />

                    </Grid>
                    <Grid sx={{pl: 2, pr: 2, overflowX: "auto"}} item xs={12}>
                        <Table>
                            <EmployeesTableHeader ascending={ascending} onSortSelected={(field, ascending) => {
                                setAscending(ascending)
                                setSortField(field)
                            }} selectedSortField={sortField}/>
                            <TableBody>
                                {employees && employees.map((e) => {
                                    return <EmployeeRow key={e.communityId} employee={e}/>
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </BlockUi>
        </Paper>
    );

}