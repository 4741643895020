import React, {useEffect, useState} from "react";
import {Box, Pagination, Paper, Stack} from "@mui/material";
import {ComplaintTable} from "./InternalAffairs";
import {useSearchParams} from "react-router-dom";
import {InternalAffairsArchivePageinationResponse} from "../../data/internal-affairs/InternalAffairsArchivePageinationResponse";

export const InternalAffairsArchivedComplaintsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [complaintArchive, setComplaintArchive] = useState<InternalAffairsArchivePageinationResponse>({
        totalComplaints: 1,
        totalPages: 1,
        complaints: []
    });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/internal-affairs/archive/${page}`)
                const data = await response.json()
                setComplaintArchive(data)
            } catch (error) {
                console.error('Error fetching archive:', error)
            }
        };
        fetchData()
    }, [page])

    const totalPages = complaintArchive.totalPages

    return <Stack spacing={1}>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <ComplaintTable header={"Archived Complaints"} complaints={complaintArchive.complaints}/>
        </Paper>
        <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
            <Pagination color={'secondary'} count={totalPages} page={page} onChange={handleChange}/>
        </Box>
    </Stack>
}