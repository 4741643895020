import {Box, Table, TableBody, TableRow, TableCell, TableHead, IconButton, Tooltip, Paper} from "@mui/material";
import useApiCall from "../hooks/CancellableApiCall";
import {SuspensionsResponse} from "../data/SuspensionsResponse";
import {PoliceUser} from "../components/PoliceUser";
import {Policy} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {formatTimestamp} from "../utils";
import {makeApiCall} from "../hooks/CancellableApiCall";

export const ActiveSuspensionsPage = () => {
    const loadSuspensions = useApiCall<SuspensionsResponse[]>({
        url: `/api/records/suspensions`
    })

    const navigate = useNavigate()


return (
    <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
            }}>
            <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                       <TableCell>User</TableCell>
                        <TableCell>Roleplay Name</TableCell>
                        <TableCell>Badge Number</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadSuspensions.data && loadSuspensions.data.map((s) => {
                        let lastLogin = "Never"
                        if (s.lastLogin) {
                            lastLogin = formatTimestamp(s.lastLogin)
                        }
                        return <TableRow key={s.communityId}>
                            <TableCell><PoliceUser user={{nick: s.nick, communityId: s.communityId}}/></TableCell>
                            <TableCell>{s.rpName}</TableCell>
                            <TableCell>{s.badgeNumber}</TableCell>
                            <TableCell>{lastLogin}</TableCell>
                            <TableCell>
                            <Tooltip title={"Visit Infractions Page"}>
                            <IconButton size="small" color="error" onClick={() =>
                                makeApiCall({
                                    url: `/api/records/search/${s.communityId}`,
                                    method: "POST",
                                    body: {
                                        reason: "Suspension Query",
                                        target: s.communityId
                                    },
                                    onLoadedCallback: () => {
                                        navigate(`/records/${s.communityId}/infractions`)
                                    },
                                    onError: () => {
                                    }
                                })
                            }>
                            <Policy color={"error"} />
                            </IconButton>
                        </Tooltip>
                                    </TableCell>
                        </TableRow>
                    })
                    }
                </TableBody>
            </Table>
            </Box>
            </Paper>
        )}