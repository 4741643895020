import {Box, Table, TableBody, TableRow, TableCell, TableHead, Paper, Pagination} from "@mui/material";
import {VerboseLogsPageinationResponse} from "../data/VerboseLogsPageinationResponse";
import {PoliceUser} from "../components/PoliceUser";
import {useSearchParams} from "react-router-dom";
import {formatTimestamp} from "../utils";
import React, {useEffect, useState} from "react";
import {UserActionTypes} from "../data/UserActionTypes";

export const VerboseLogsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [verboseLogs, setVerboseLogs] = useState<VerboseLogsPageinationResponse>({
        totalLogs: 1,
        totalPages: 1,
        logs: []
    });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/user/verbose/logs/${page}`)
                const data = await response.json()
                setVerboseLogs(data)
            } catch (error) {
                console.error('Error fetching logs:', error)
            }
        };
        fetchData()
    }, [page])

    const totalPages = verboseLogs.totalPages


return (
    <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
                }}>
            <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                       <TableCell>Admin</TableCell>
                       <TableCell>Employee</TableCell>
                        <TableCell>Action Type</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {verboseLogs.logs && verboseLogs.logs.map((vl) => {
                        return <TableRow key={vl.adminCommunityId}>
                            <TableCell><PoliceUser user={{communityId: vl.adminCommunityId, nick: vl.adminNick}}/></TableCell>
                            <TableCell>
                                {vl.userCommunityId !== null ? <PoliceUser user={{communityId: vl.userCommunityId, nick: vl.userNick}}/> : <></>}
                            </TableCell>
                            <TableCell>{UserActionTypes.get(vl.type)?.displayName ?? "Unknown Action Type"}</TableCell>
                            <TableCell>{formatTimestamp(vl.date)}</TableCell>
                        </TableRow>
                    })
                    }
                </TableBody>
            </Table>
            </Box>
            <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
                <Pagination color={'secondary'} count={totalPages} page={page} onChange={handleChange}/>
            </Box>
        </Paper>
    )}
