import {createContext, PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import {ApiCallResponseData, makeApiCall} from "./CancellableApiCall";
import {MiniProfileResponse} from "../data/MiniProfileResponse";

export interface MiniProfileContextProps {
    miniProfile?: MiniProfileResponse
    onElementHovered: (elem: HTMLElement, hovered: boolean, communityId: string) => void
    loadMiniProfile: (communityId: string) => void
    registerForHoverEvents: (identifier: string, handler: (target?: MiniProfileTarget) => void) => () => void
    registerForUnmountEvents: (identifier: string, handler: (elem: HTMLElement) => void) => () => void
    onTargetUnmounted: (elem: HTMLElement) => void
}

export const MiniProfileContext = createContext<MiniProfileContextProps>({
    onElementHovered: () => {
    },
    loadMiniProfile: () => {
    },
    registerForHoverEvents: () => () => {
    },
    registerForUnmountEvents: () => () => {
    },
    onTargetUnmounted: () => {
    }
})

export interface MiniProfileTarget {
    elem: HTMLElement
    communityId: string
}

export const MiniProfileProvider = ({children}: PropsWithChildren) => {
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [miniProfile, setMiniProfile] = useState<MiniProfileResponse>()
    const hoverEventListeners = useRef<Map<string, (target?: MiniProfileTarget) => void>>(new Map())
    const unmountEventListeners = useRef<Map<string, (elem: HTMLElement) => void>>(new Map())


    useEffect(() => {
        return () => apiCall?.cancel()
    }, [apiCall]);

    const onElementHovered = useCallback((elem: HTMLElement, hovered: boolean, communityId: string) => {
        const target = (hovered ? {elem, communityId} : undefined)
        hoverEventListeners.current.forEach(handler => handler(target))
    }, [])

    const loadMiniProfile = useCallback((communityId: string) => {
        if (communityId === miniProfile?.communityId) {
            return
        }
        setMiniProfile(undefined)
        setApiCall(makeApiCall({
            url: `/api/user/${communityId}/mini-profile`,
            onLoadedCallback: (data: MiniProfileResponse) => setMiniProfile(data),
            onError: () => setMiniProfile(undefined)
        }))
    }, [miniProfile?.communityId])

    const registerForHoverEvents = useCallback((identifier: string, handler: (target?: MiniProfileTarget) => void): () => void => {
        hoverEventListeners.current.set(identifier, handler)
        return () => hoverEventListeners.current.delete(identifier)
    }, [])

    const registerForUnmountEvents = useCallback((identifier: string, handler: (elem: HTMLElement) => void): () => void => {
        unmountEventListeners.current.set(identifier, handler)
        return () => unmountEventListeners.current.delete(identifier)
    }, [])

    const onTargetUnmounted = useCallback((elem: HTMLElement) => {
        unmountEventListeners.current.forEach(handler => handler(elem))
    }, [])

    return <MiniProfileContext.Provider value={{
        miniProfile,
        onElementHovered,
        onTargetUnmounted,
        loadMiniProfile,
        registerForHoverEvents,
        registerForUnmountEvents,
    }}>
        {children}
    </MiniProfileContext.Provider>
}