interface InternalAffairsComplaintStatusInter {
    key: string
    displayName: string
    action: boolean
    neededPermission?: string
    blockReplies: boolean // internalAffairs:extendedComplaintActions overrides block replies
}

export const internalAffairsComplaintStatus: InternalAffairsComplaintStatusInter[] = [
    {
        key: "awaiting_investigator",
        displayName: "Awaiting Investigator",
        action: true,
        neededPermission: "internalAffairs:extendedComplaintActions",
        blockReplies: true,
    },
    {
        key: "investigator_assigned",
        displayName: "Investigator Assigned",
        action: true,
        neededPermission: "internalAffairs:extendedComplaintActions",
        blockReplies: false,
    },
    {
        key: "pending_statement_response",
        displayName: "Pending Statement Response",
        action: true,
        blockReplies: false,
    },
    {
        key: "pending_creator_response",
        displayName: "Pending Creator Response",
        action: true,
        blockReplies: false,
    },
    {
        key: "complaint_frozen",
        displayName: "Complaint Frozen",
        action: true,
        neededPermission: "internalAffairs:extendedComplaintActions",
        blockReplies: false,
    },
    {
        key: "final_reported",
        displayName: "Final Report Sent for Review",
        action: false,
        blockReplies: false,
    },
    {
        key: "closed",
        displayName: "Closed",
        action: false,
        blockReplies: true,
    },
    {
        key: "re_voting",
        displayName: "Complaint Committee Re-Vote",
        action: false,
        blockReplies: true,
    },
]

export const InternalAffairsComplaintStatus: Map<string, InternalAffairsComplaintStatusInter> = new Map(internalAffairsComplaintStatus.map(i => [i.key, i]))