import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {InternalAffairsComplaintResponse} from "../../data/internal-affairs/InternalAffairsComplaintResponse";
import {Alert, Button, Divider, Input, InputLabel, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import {LoadingContent} from "../../components/LoadingContent";
import React, {useRef, useState} from "react";
import {TextEditor} from "../../components/editor/TextEditor";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import {EditorContent} from "../../components/editor/EditorContent";
import useUser, {UserContextProps} from "../../hooks/useUser";
import {infractionTypes} from "../../components/record/IssueInfractionForm";
import {divisionIdentifierToDisplayName} from "../../utils";
import {internalAffairsComplaintOutcome} from "../../data/internal-affairs/InternalAffairsComplaintOutcome";
import {ConfirmationDialog} from "../../components/ConfirmationDialog";
import {InternalAffairsFinalReport} from "./InternalAffairsFinalReport";

interface finalReportProps {
    complaint: InternalAffairsComplaintResponse,
    user: UserContextProps
}

function FinalReport(props: finalReportProps) {
    const [returnConfirm, setReturnConfirm] = useState(false)
    const [disputeConfirm, setDisputeConfirm] = useState(false)
    const [infraction, setInfraction] = useState("reprimand")
    const [outcome, setOutcome] = useState("")
    const [reason, setReason] = useState("")
    const seniorCCEditorRef = useRef<LexicalEditor>(null)
    const navigate = useNavigate()

    const returnFinalReport = (confirm: boolean) => {
        if (confirm) {
            makeApiCall({
                url: `/api/internal-affairs/return-final-report/${props.complaint.displayId}`,
                method: "POST",
                onLoadedCallback: () => {
                    navigate(`/complaint/${props.complaint.displayId}`)
                },
                onError: () => {
                }
            })
        }
        setReturnConfirm(false)
    }

    const dispute = (confirm: boolean) => {
        if (confirm) {
            makeApiCall({
                url: `/api/internal-affairs/complaint/${props.complaint.displayId}/dispute`,
                method: "PATCH",
                onLoadedCallback: () => {
                    navigate(`/complaint/${props.complaint.displayId}`)
                },
                onError: () => {
                }
            })
        }
        setDisputeConfirm(false)
    }

    const initialSeniorCCContent =  `<p class="editor-paragraph" dir="ltr"><b><strong class="editor-text-bold" style="white-space: pre-wrap;">Final Decision Manual Details</strong></b></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Unfounded: </span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Exonerated: </span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Not Sustained:</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Sustained:</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Employee Record at least the past 3 months:</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Recent promotions and achievements:</span></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Intention:</span></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Reason for Infraction:</span></p>`
    const additionalComplaintDetailAddition = `<p class="editor-paragraph" dir="ltr"><b><strong class="editor-text-bold" style="white-space: pre-wrap;">Final Decision Automatic Details:</strong></b></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr"><b><strong class="editor-text-bold" style="white-space: pre-wrap;">Officer: </strong></b><a href="/user/${props.complaint.defendantCommunityId}" rel="noreferrer" target="_blank" class="editor-link"><span style="white-space: pre-wrap;">${props.complaint.defendantName}</span></a></p><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><b><strong class="editor-text-bold" style="white-space: pre-wrap;">Complaint ID: </strong></b><a href="/complaint/${props.complaint.displayId}" rel="noreferrer" target="_blank" class="editor-link"><span style="white-space: pre-wrap;">${props.complaint.displayId}</span></a></p><p class="editor-paragraph" dir="ltr"><br></p><b><strong class="editor-text-bold" style="white-space: pre-wrap;"><a href="https://sites.google.com/view/professionalstandards/professional-standards/resources/psd-pm-templates?authuser=0" rel="noreferrer" target="_blank" class="editor-link" style="text-decoration: none; color: inherit;">Professional Standards Department Inbox Templates</a></strong></b><p class="editor-paragraph" dir="ltr"><br></p><p class="editor-paragraph" dir="ltr"><b><strong class="editor-text-bold" style="white-space: pre-wrap;">Action: </strong></b><span style="white-space: pre-wrap;">${divisionIdentifierToDisplayName(infraction)}</span></p><p class="editor-paragraph"><br></p>`

    let seniorCCPanel = <></>
    if (props.user.hasPermissions("internalAffairs:complaintSeniorReviewBoard")) {
        seniorCCPanel = <>
            <Typography sx={{pt:2}} variant="h6" gutterBottom>{`Final Decision for Complaint ${props.complaint.displayId} against Officer ${props.complaint.defendantRpName}`}</Typography>
            <Paper variant={"outlined"} style={{
                padding: "16px"
            }}>
                <Stack sx={{overflowX:"auto"}} spacing={2}>
                    <TextEditor ref={seniorCCEditorRef} initialContent={initialSeniorCCContent} />
                    <InputLabel id="outcome-select-label">Complaint Outcome</InputLabel>
                    <Select size="small" sx={{width: 500}} id="outcome-select" value={outcome} variant="standard"
                            onChange={(event) => setOutcome(event.target.value)}
                    >
                        {internalAffairsComplaintOutcome.map((outcome) =>
                            <MenuItem key={outcome.key} value={outcome.key}>{outcome.displayName}</MenuItem>
                        )}
                    </Select>
                    {outcome === "sustained" ? <>
                        <InputLabel id="infraction-select-label">Infraction Type</InputLabel>
                        <Select size="small" sx={{width: 500}} id="infraction-select" value={infraction} variant="standard"
                                onChange={(event) => setInfraction(event.target.value)}
                        >
                            {infractionTypes.map((infractionType) =>
                                <MenuItem key={infractionType.id} value={infractionType.identifier}>{infractionType.displayName}</MenuItem>
                            )}
                        </Select>
                    </> : <></>}
                    <Input multiline rows={2} placeholder={"Reason..."} size="small" sx={{width: 1000}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                    <Button color="success" onClick={() => {
                        if (seniorCCEditorRef.current) {
                            seniorCCEditorRef.current.getEditorState().read(() => {
                                makeApiCall({
                                    url: `/api/internal-affairs/final-decision/${props.complaint.displayId}`,
                                    method: "POST",
                                    body: {
                                        detailedReason: additionalComplaintDetailAddition + $generateHtmlFromNodes(seniorCCEditorRef.current!),
                                        infraction: infraction,
                                        outcome: outcome,
                                        reason: reason
                                    },
                                    onLoadedCallback: () => {
                                        navigate(`/internal-affairs`)
                                    },
                                    onError: () => {
                                    }
                                })
                            })
                        }
                    }}>Submit Final Decision</Button>
                    <Button color="warning" component={RouterLink} to={`/complaint/${props.complaint.displayId}`}>Return to
                        complaint</Button>
                </Stack>
            </Paper>
        </>
    }

    if (!props.complaint.finalReport || props.complaint.finalReport.isDraft) {
        return <InternalAffairsFinalReport />
    } else if (props.complaint.finalDecision === null) {
        return <Stack spacing={1}>
            <Typography sx={{pt:2}} variant="h6" gutterBottom>{`Investigator Final Report for Complaint ${props.complaint.displayId} against Officer ${props.complaint.defendantName}`}</Typography>
            <Paper variant={"outlined"} style={{
                padding: "16px"
            }}>
                <EditorContent content={props.complaint.finalReport.content}/>
                <Divider/>
                <Button component={RouterLink} to={`/complaint/${props.complaint.displayId}`} color="info">Return</Button>
                <ConfirmationDialog title={"Send Back Final Report"} description={"This action cannot be undone"} open={returnConfirm} onClose={returnFinalReport} />
                <Button color="error" disabled={!props.complaint.complaintPermissions.includes("open_dispute")} onClick={() => {setReturnConfirm(true)}}>Send back Final Report</Button>
            </Paper>
            {seniorCCPanel}
        </Stack>
    } else {
        if (props.user.hasPermissions("internalAffairs:complaintSeniorReviewBoard")) {
            seniorCCPanel = <>
                <Typography sx={{pt:2}} variant="h6" gutterBottom>{`Final Decision for Complaint ${props.complaint.displayId} against Officer ${props.complaint.defendantRpName}`}</Typography>
                <Paper variant={"outlined"} style={{
                    padding: "16px"
                }}>
                    <Stack spacing={2}>
                        <EditorContent content={props.complaint.finalDecision}/>
                        <ConfirmationDialog title={"Reopen Complaint"} description={"This action cannot be undone"} open={returnConfirm} onClose={returnFinalReport} />
                        <Button color="error" disabled={!props.complaint.complaintPermissions.includes("open_dispute")} onClick={() => {setReturnConfirm(true)}}>Reopen Complaint</Button>
                        <ConfirmationDialog title={"Send Complaint to a re-vote"} description={"This action cannot be undone"} open={disputeConfirm} onClose={dispute} />
                        <Button color="error" disabled={!props.complaint.complaintPermissions.includes("open_dispute")} onClick={() => {setDisputeConfirm(true)}}>Send Complaint to a re-vote</Button>
                    </Stack>
                    <Button color="warning" component={RouterLink} to={`/complaint/${props.complaint.displayId}`}>Return to
                        complaint</Button>
                </Paper>
            </>
        }
        return <Stack spacing={1}>
            <Typography sx={{pt:2}} variant="h6" gutterBottom>{`Investigator Final Report for Complaint ${props.complaint.displayId} against Officer ${props.complaint.defendantRpName}`}</Typography>
            <Paper variant={"outlined"} style={{
                padding: "16px"
            }}>
                <EditorContent content={props.complaint.finalReport.content}/>
            </Paper>
            {seniorCCPanel}
        </Stack>
    }
}

export const InternalAffairsFinalReportPage = () => {
    const params = useParams()
    const complaintData = useApiCall<InternalAffairsComplaintResponse>({
        url: `/api/internal-affairs/complaint/${params.id}`
    })

    const user = useUser()

    let pageContent

    if (complaintData.isError) {
        pageContent =
            <Alert severity="error">You cannot access this complaint's final report, if this is a mistake contact the
                Professional Standards Department</Alert>
    } else if (complaintData.data && user.user) {
        pageContent = <FinalReport complaint={complaintData.data} user={user}/>
    }

    return (
        <LoadingContent isLoading={
            complaintData.isLoading
        }>
            {pageContent}
        </LoadingContent>
    )
}