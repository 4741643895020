import {InitialConfigType, LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {AutoFocusPlugin} from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
import {ListItemNode, ListNode} from "@lexical/list";
import {CodeHighlightNode, CodeNode} from "@lexical/code";
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {TRANSFORMERS} from "@lexical/markdown";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import './editor-content.css';

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import EditorTheme from "./themes/EditorTheme";
import {Box, Button, useTheme} from "@mui/material";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import Typography from "@mui/material/Typography";
import ImagesPlugin from "./plugins/ImagePlugin";
import {ImageNode} from "./nodes/ImageNode";
import {ForwardedRef, forwardRef, useEffect, useRef} from "react";
import {$getRoot, $insertNodes, EditorState, LexicalEditor} from "lexical";
import {sanitizeHtml} from "../../utils";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";

function Placeholder() {
    const theme = useTheme()
    const textColor = theme.palette.mode === "light" ? theme.palette.grey["600"] : theme.palette.grey["500"]

    return <Typography sx={{
        overflow: "hidden",
        position: "absolute",
        textOverflow: "ellipsis",
        display: "inline-block",
        color: textColor,
        userSelect: "none",
        pointerEvents: "none",
        top: "15px",
        left: "11px"
    }}>Enter some text...</Typography>;
}

const editorConfig: InitialConfigType = {
    // The editor theme
    theme: EditorTheme,
    namespace: "",
    // Handling of errors during update
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        ImageNode
    ]
};

interface SubmitButtonProps {
    text?: string
    enabled?: boolean
    onSubmitPressed: (html: string) => void
}


function SubmitButton(props: SubmitButtonProps) {
    const [editor] = useLexicalComposerContext()
    let enabled = true
    if (props.enabled === false) {
        enabled = props.enabled
    }

    return (
        <Box sx={{pt: 2, display: "flex"}}>
            <Button sx={{marginLeft: "auto"}} variant="contained" disabled={!enabled} onClick={() => {
                editor.getEditorState().read(() => {
                    props.onSubmitPressed($generateHtmlFromNodes(editor))
                })
            }}>
                {props.text ?? "Submit"}
            </Button>
        </Box>
    )
}

const ForwardRefPlugin = forwardRef((_, ref: ForwardedRef<LexicalEditor>) => {
    const [editor] = useLexicalComposerContext()
    useEffect(() => {
        if (ref !== null) {
            if (typeof ref === 'function') {
                ref(editor)
            } else {
                ref.current = editor
            }
        }
        return () => {
            if (ref !== null) {
                if (typeof ref === 'function') {
                    ref(null)
                } else {
                    ref.current = null
                }
            }
        }
    }, [editor, ref]);
    return null
})

interface InitialContentProps {
    initialContent?: string
}

const InitialContentPlugin = (props: InitialContentProps) => {
    const [editor] = useLexicalComposerContext()
    const hasLoadedContent = useRef(false)
    useEffect(() => {
        if (props.initialContent && !hasLoadedContent.current) {
            editor.update(() => {
                hasLoadedContent.current = true
                const parser = new DOMParser()
                const dom = parser.parseFromString(props.initialContent ?? "", "text/html")

                const nodes = $generateNodesFromDOM(editor, dom)

                $getRoot().clear()
                $getRoot().select()
                $insertNodes(nodes)
            })
        }
    }, [editor, props]);
    return null
}

interface TextEditorProps {
    onSubmitPressed?: (html: string) => void
    submitButtonEnabled?: boolean
    submitButtonText?: string
    initialContent?: string
    autoFocus?: boolean
    onBlur?: () => void
    onChange?: (editorState: EditorState) => void
}

export const TextEditor = forwardRef((props: TextEditorProps, ref: ForwardedRef<LexicalEditor>) => {
    const theme = useTheme()
    let contentClassName = "editor-input"
    if (theme.palette.mode === "dark") {
        contentClassName = "editor-input editor-content dark-editor-content"
    }

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div>
                <ToolbarPlugin/>
                <div style={{position: "relative"}}>
                    <RichTextPlugin
                        contentEditable={
                            <div className="editor-scroller">
                                <div className="editor" onBlur={() => {
                                    if (props.onBlur) {
                                        props.onBlur()
                                    }
                                }}>
                                    <ContentEditable className={contentClassName}/>
                                </div>
                            </div>
                        }
                        placeholder={<Placeholder/>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <HistoryPlugin/>
                    <ImagesPlugin/>
                    {props.autoFocus && <AutoFocusPlugin/>}
                    <CodeHighlightPlugin/>
                    <ListPlugin/>
                    <LinkPlugin/>
                    <AutoLinkPlugin/>
                    <ListMaxIndentLevelPlugin maxDepth={7}/>
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS}/>
                    <ForwardRefPlugin ref={ref}/>
                    {props.onChange ? <OnChangePlugin ignoreSelectionChange onChange={props.onChange}/> : <></>}
                    <InitialContentPlugin
                        initialContent={props.initialContent ? sanitizeHtml(props.initialContent) : undefined}/>
                </div>
                {(props.onSubmitPressed !== undefined) ?
                    <SubmitButton onSubmitPressed={props.onSubmitPressed} text={props.submitButtonText}/> : <></>}
            </div>
        </LexicalComposer>
    );
})
