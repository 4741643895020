interface UserRankChangeTypeInter {
    key: string
    displayName: string
}

export const userRankChangeType: UserRankChangeTypeInter[] = [
    { key: "promotion", displayName: "Promotion" },
    { key: "step_down", displayName: "Stepped Down" },
    { key: "application_pass", displayName: "Application Passed" },
    { key: "reinstatement", displayName: "Reinstatement" },
    { key: "demotion", displayName: "Demotion" },
]

export const UserRankChangeType: Map<string, UserRankChangeTypeInter> = new Map(userRankChangeType.map(i => [i.key, i]))