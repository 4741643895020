import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface ConfirmationDialogProps {
    title: string
    description: string
    open: boolean
    onClose: (confirmed: boolean) => void
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    return <Dialog open={props.open} onClose={() => props.onClose(false)}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.onClose(false)}>Cancel</Button>
            <Button onClick={() => props.onClose(true)} autoFocus variant="contained">
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
}