export enum QuestionType {
    MULTIPLE_CHOICE = 1,
    SELECT_MULTIPLE,
    SHORT_RESPONSE,
    PRACTICAL,
}

export const QuestionTypes = [QuestionType.MULTIPLE_CHOICE, QuestionType.SELECT_MULTIPLE, QuestionType.SHORT_RESPONSE, QuestionType.PRACTICAL]

export const QuestionTypeStrings = new Map<QuestionType, String>([
    [QuestionType.MULTIPLE_CHOICE, "Multiple Choice"],
    [QuestionType.SELECT_MULTIPLE, "Select Multiple"],
    [QuestionType.SHORT_RESPONSE, "Short Response"],
    [QuestionType.PRACTICAL, "Practical"]
])