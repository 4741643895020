import {Link, LinkProps, Stack} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {RankChip} from "./RankChip";
import React from "react";
import {MiniProfileTarget} from "./MiniProfileTarget";

interface User {
    nick: string
    communityId: string
}

interface Rank {
    displayName: string
    color: string
}

interface PoliceUserProps {
    user: User
    rank?: Rank
    spacing?: number
    linkProps?: LinkProps
}

export const PoliceUser = (props: PoliceUserProps) => {
    return <Stack direction="row" spacing={props.spacing ?? 1}>
        <MiniProfileTarget communityId={props.user.communityId}>
            <Link underline="none" {...props.linkProps} component={RouterLink} to={`/user/${props.user.communityId}`}>
                {props.user.nick}
            </Link>
        </MiniProfileTarget>
        {props.rank ? <RankChip rank={props.rank.displayName} color={props.rank.color}/> : <></>}

    </Stack>
}