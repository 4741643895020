import {Link, useNavigate, useParams} from "react-router-dom";
import {AssessmentInfoResponse} from "../data/assessment/AssessmentInfoResponse";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {LoadingContent} from "../components/LoadingContent";
import React, {useCallback, useEffect, useState} from "react";
import {AlertType} from "../data/AlertType";
import {
    Alert,
    Button,
    Grid,
    Paper,
    Snackbar,
    Stack
} from "@mui/material";
import {AssessmentQuestionInfoResponse} from "../data/assessment/AssessmentQuestionInfoResponse";
import {QuestionList} from "../components/assessment/QuestionList";
import {AssessmentEditor} from "../components/assessment/AssessmentEditor";

export const EditAssessmentPage = () => {
    const params = useParams()
    const assessmentData = useApiCall<AssessmentInfoResponse>({
        url: `/api/careers/assessments/${params.id}`
    })
    const questionsData = useApiCall<AssessmentQuestionInfoResponse[]>({
        url: `/api/careers/assessments/${params.id}/questions`
    })

    return <LoadingContent
        isLoading={assessmentData.isLoading || !assessmentData.data || questionsData.isLoading || !questionsData.data}>
        <EditAssessmentPageContent assessment={assessmentData.data!!} questions={questionsData.data!!}/>
    </LoadingContent>
}

interface EditAssessmentPageProps {
    assessment: AssessmentInfoResponse
    questions: AssessmentQuestionInfoResponse[]
}

const EditAssessmentPageContent = (props: EditAssessmentPageProps) => {
    const navigate = useNavigate()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const updateAssessment = (assessment: AssessmentInfoResponse) => {
        setApiCall(makeApiCall({
            url: `/api/assessments/${props.assessment.id}`,
            method: "PATCH",
            body: assessment,
            onLoadedCallback: _ => {
                navigate(`/careers`)
            },
            onError: () => {
                setAlert("error", "Failed to update assessment")
            }
        }))
    }

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);


    return <Paper variant={"outlined"} style={{marginTop: "16px", padding: "16px"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
                <AssessmentEditor editing handleSubmit={updateAssessment} assessment={props.assessment}/>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Paper variant="outlined" sx={{padding: "16px"}}>
                    <Stack spacing={2}>
                        <Button variant="contained" component={Link} disabled={props.assessment.isPublished}
                                to={`/assessments/${props.assessment.id}/edit/question/new`}>Add Question</Button>
                        <QuestionList questions={props.questions}
                                      linkUrlBuilder={question =>
                                          `/assessments/${props.assessment.id}/edit/question/${question.id}`}/>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}