import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {Alert, Button, Input, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, Tooltip} from "@mui/material";
import {LoadingContent} from "../../components/LoadingContent";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {RankResponse} from "../../data/RankResponse";
import {DivisionRoles} from "../../data/DivisionRoles";
import {Division} from "../../data/Division";
import {applicationRequirement} from "./NewApplicationPage";
import {ApplicationRequirements} from "./ApplicationRequierments";
import {allRequirements} from "./RankRequirements";
import {ApplicationEditResponse} from "../../data/applications/ApplicationEditResponse";
import {CertificationResponse} from "../../data/CertificationResponse";

interface EditApplicationPageContentProps {
    divisionRoles: DivisionRoles[]
    ranks: RankResponse[]
    divisions: Division[]
    certifications: CertificationResponse[]
    applicationData: ApplicationEditResponse
    type: string
    id: string
}

type alertType = "success" | "error" | "info"

function EditApplicationPageContent(props: EditApplicationPageContentProps) {
    // eslint-disable-next-line
    const [newRequirements, setNewRequirements] = useState<applicationRequirement[]>([])
    const [newDescription, setNewDescription] = useState(props.applicationData.description)
    const [newRankOnPassId, setNewRankOnPassId] = useState(props.applicationData.rankOnPassId)
    const [newMarkerRoleId, setNewMarkerRoleId] = useState(props.applicationData.markerRoleId)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")
    const navigate = useNavigate()

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    return <Stack spacing={2}>
        <Paper style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Stack spacing={2}>
                {props.type === "division-role" ? <>
                    <InputLabel id="new-marker-role-label">Marker Role</InputLabel>
                    <Select id="new-marker-role-select" value={newMarkerRoleId} onChange={(event) => {
                        setNewMarkerRoleId(event.target.value as number)
                    }}>
                        {props.divisionRoles.map((role) =>
                            <MenuItem key={role.id} value={role.roleId}>{role.displayName}</MenuItem>
                        )}
                    </Select> </> : <></>}
                <Tooltip title="Marker Role" arrow>
                    <InputLabel id="new-role-mark-select-label">Rank the Applicant will receive on successful application</InputLabel>
                </Tooltip>
                <Select id="rankId-selection" value={newRankOnPassId} label="Marker Role" onChange={(event) => {
                    setNewRankOnPassId(event.target.value as number)
                }}>
                    {props.ranks.map((role) =>
                        <MenuItem key={role.rankId} value={role.rankId}>{role.displayName}</MenuItem>
                    )}
                </Select>
                <InputLabel id="new-description">Application Description</InputLabel>
                <Input multiline rows={4} value={newDescription}
                       onChange={(event) => setNewDescription(event.target.value)}/>
            </Stack>
        </Paper>
        {props.type === "taser" ? <ApplicationRequirements
            requirementTable={newRequirements}
            ranks={props.ranks}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements.filter(requirement =>
                !requirement.shouldUseDivisionRoles && !requirement.shouldUseDivisions && !(requirement.requirementIdentifier === "minimum_division_playtime") && !(requirement.requirementIdentifier === "taser_requirement")
            )}
            existingRequirements={props.applicationData.requirements}
        /> : <></>}
        {props.type === "division-role" ? <ApplicationRequirements
            requirementTable={newRequirements}
            ranks={props.ranks}
            divisionRoles={props.divisionRoles}
            divisions={props.divisions}
            certifications={props.certifications}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements}
            existingRequirements={props.applicationData.requirements}
        /> : <></>}
        {props.type === "certification" ? <ApplicationRequirements
            requirementTable={newRequirements}
            divisionRoles={props.divisionRoles}
            certifications={props.certifications}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements.filter(requirement =>
                !requirement.shouldUseDivisions  && !requirement.shouldUseRanks
            )}
            existingRequirements={props.applicationData.requirements}
        /> : <></>}
        {props.type === "rank" ? <ApplicationRequirements
            requirementTable={newRequirements}
            ranks={props.ranks}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements.filter(requirement =>
                !requirement.shouldUseDivisionRoles && !requirement.shouldUseDivisions && !(requirement.requirementIdentifier === "minimum_division_playtime")
            )}
            existingRequirements={props.applicationData.requirements}
        /> : <></>}
        <Button variant="contained" onClick={() => {
            makeApiCall({
                url: `/api/careers/${props.id}/edit`,
                method: "POST",
                body: {
                    newDescription: newDescription,
                    newRankOnPassId: newRankOnPassId,
                    newMarkerRoleId: newMarkerRoleId,
                    newRequirements: newRequirements
                },
                onLoadedCallback: () => {
                    setAlert("success", "Application Edited")
                    navigate("/careers")
                },
                onError: () => {
                    setAlert("error", "Application failed to edit due to a issue")
                }
            })
        }}>
            Update Application
        </Button>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Stack>
}

export const EditApplicationPage = () => {
    const params = useParams()
    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: "/api/rank/subordinates"
    })

    const loadDivisionRoles = useApiCall<DivisionRoles[]>({
        initialUrl: `/api/division/roles/${params.divisionIdentifier?.replaceAll("-", "_")}`
    })

    const loadDivisions = useApiCall<Division[]>({
        url: `/api/division/list-divisions`
    })

    const loadCertifications = useApiCall<CertificationResponse[]>({
        url: `/api/certifications/`
    })

    const applicationData = useApiCall<ApplicationEditResponse>({
        url: `/api/careers/${params.applicationId}/edit`
    })

    return <LoadingContent isLoading={
            loadRanks.isLoading || !loadRanks.data ||
            loadDivisions.isLoading || !loadDivisions.data ||
            loadDivisionRoles.isLoading || !loadDivisionRoles.data ||
            applicationData.isLoading || !applicationData.data ||
            loadCertifications.isLoading || !loadCertifications.data
        }> <EditApplicationPageContent
                divisionRoles={loadDivisionRoles.data!!}
                ranks={loadRanks.data!!}
                divisions={loadDivisions.data!!}
                certifications={loadCertifications.data!!}
                type={params.applicationType ?? ""}
                id={params.applicationId ?? "-1"}
                applicationData={applicationData.data!!}
            />
    </LoadingContent>
}