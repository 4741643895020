import {Box, Pagination, Stack} from "@mui/material"
import {Link as RouterLink, useSearchParams} from "react-router-dom"
import React from "react"
import {AnnouncementPaginatedResponse} from "../data/AnnouncementPaginatedResponse"
import {AnnouncementPreview} from "../components/announcement/AnnouncementPreview"
import useApiCall from "../hooks/CancellableApiCall";
import useUser from "../hooks/useUser";
import Button from "@mui/material/Button";


export const AnnouncementMenuPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useUser()
    const page = parseInt(searchParams.get("page") ?? "1")
    const loadAnnouncements = useApiCall<AnnouncementPaginatedResponse>({
        url: `/api/announcement/pages?page=${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    const totalPages = loadAnnouncements.data?.pages ?? 1

    let newAnnouncementButton = <></>
    if (user.user?.permissions.find(p => {return p.includes("announcement:create")}) !== undefined) {
        newAnnouncementButton =  <Button component={RouterLink}
                                         to={`/announcement/create`}
                                         variant={"outlined"}
                                         color="success">New Announcement</Button>
    }

    return (
        <>
            <Stack spacing={3}>
                {newAnnouncementButton}
                {loadAnnouncements.data && loadAnnouncements.data.announcements.map((announcement) => {
                    return <AnnouncementPreview user={user.user!!} key={announcement.id} announcement={announcement}/>
                })}
            </Stack>
            <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
                <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                            onChange={handleChange}/>
            </Box>
        </>
    )
}