import React from 'react';
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {Shoutbox} from "../components/Shoutbox";
import useUser from "../hooks/useUser";
import RecentActivity from "../components/recent-activity/RecentActivity";
import {LoadingContent} from "../components/LoadingContent";
import useApiCall from "../hooks/CancellableApiCall";
import {AnnouncementResponse} from "../data/AnnouncementResponse";
import {AnnouncementPreview} from "../components/announcement/AnnouncementPreview";
import {Link as RouterLink} from "react-router-dom";

export const MainPage = () => {
    const userData = useUser()
    const announcementData = useApiCall<AnnouncementResponse[]>({
        url: `/api/announcement`
    })


    return (
        <LoadingContent isLoading={userData.isLoading || !userData.user}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Stack spacing={2} style={{width: "100%"}}>
                        <Shoutbox user={userData.user!}/>

                        <Stack sx={{pt:2}} direction="row">
                            <Typography variant="h6">Announcements <Button size={"small"} component={RouterLink} to={`/announcements`} variant="text" >See more</Button></Typography>
                        </Stack>

                        <Paper variant="outlined" sx={{padding: '15px'}}>
                            <Grid container spacing={2}>
                                {announcementData.data && announcementData.data.map((announcement) => {
                                    return <AnnouncementPreview user={userData.user!!} key={announcement.id} announcement={announcement}/>
                                })}
                            </Grid>
                        </Paper>

                    </Stack>
                </Grid>
                <Grid item lg={4}>
                        <Typography variant="h6" gutterBottom>Recent Activity <Button size={"small"} component={RouterLink} to={`/timeline`} variant="text" >See more</Button></Typography>
                        <RecentActivity/>
                </Grid>
            </Grid>
        </LoadingContent>
    )
}
