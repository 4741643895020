import useApiCall from "../hooks/CancellableApiCall";
import {UserSummaryResponse} from "../data/UserSummary";
import {Box, Button, Divider, Grid, Paper, Stack, Typography, useTheme} from "@mui/material";
import {styled} from '@mui/material/styles';
import {LoadingContent} from "../components/LoadingContent";
import {DivisionSummaryWithHead} from "../data/Division";
import React from "react";
import {RankChip} from "../components/RankChip";
import {MiniProfileTarget} from "../components/MiniProfileTarget";
import {generateProfilePath} from "../utils";
import {Link as RouterLink} from "react-router-dom";

const headBlocksSrc = {
    "Office of the Chiefs of Department": "/images/chiefs.png",
    "Office of Operations Services": "/images/operations.png",
    "Office of Professional Standards": "/images/professional.png"
}

interface DivisionEntryProps {
    division: DivisionSummaryWithHead
}

interface HeadChipProps {
    head: UserSummaryResponse | null
}

interface HeadBlockProps {
    user: UserSummaryResponse | null
    division: string
}

interface DivisionChipProps {
    user: UserSummaryResponse
}

const TextBracket = styled('div')`
        margin-left:25px;
        display: flex;
        height:100%;
        width:100%;
        justify-content:center;
        flex-direction:column;
        align-content:center;
    `

const HeadBlock = ({user, division}: HeadBlockProps) => {
    const theme = useTheme()
    let src = headBlocksSrc[division as keyof typeof headBlocksSrc]
    let title = division.split("Office of")
    return (
    <Box sx={{
        height: "120px",
        display: "flex",
        width: "100%"
    }}>
        <TextBracket>
            {user ? <>
                <HeadChip head={user}></HeadChip>
                <Typography sx={{pb:1}} color={theme.palette.text.secondary} variant={"body2"}>
                    Head of {title}
                </Typography>
            </> : <Typography variant={"h5"} sx={{}}>
                Vacant
            </Typography>}
        </TextBracket>
        <Box
            component={"img"}
            src={src}
            sx={{
                width:"120px",
                height:"120px",
                padding:"15px",
                float:"right",
                display: {xs: "none", sm: "block"}
            }}
        />
    </Box>)
}

const DivisionChip = ({user}: DivisionChipProps) => {
    return <Stack sx={{pt:1}} direction="row" spacing={1}>
        <RankChip rank={user.policeRank.displayName} color={user.policeRank.color}/>
        <MiniProfileTarget communityId={user.communityId}>
            <RankChip rank={user.nick} color="23C6C8" link={generateProfilePath(user.communityId)}/>
        </MiniProfileTarget>
    </Stack>
}

const HeadChip = ({head}: HeadChipProps) => {
    let title = `${head?.policeRank.displayName} ${head?.nick}`
    if (head) {
        return(
                <Typography sx={{fontSize: {xs:"1rem", sm:"1rem", md:"1.2rem", xl:"1.2rem"}}} variant="h5">{title} <Button component={RouterLink} to={`/user/${head.communityId}`} variant={"text"} size={"small"}>Profile</Button></Typography>
    );
    } else {
        return <Typography variant="h5">Vacant</Typography>;
    }
}

const DivisionEntry = ({division}: DivisionEntryProps) => {
    const theme = useTheme();
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Paper variant={"outlined"}>
                <Box sx={{
                    height: "120px",
                    display: "flex",
                    width: "100%"
                }}>
                    <TextBracket>
                            <HeadChip head={division.head}/>
                        <Typography sx={{pb:1}} color={theme.palette.text.secondary} variant={"body2"}>
                            Head of {division.division.displayName}
                        </Typography>
                    </TextBracket>
                    <Box
                        component={"img"}
                        src={division.division.badge}
                        sx={{
                            width:"120px",
                            height:"120px",
                            padding:"15px",
                            float:"right",
                            display: {xs: "none", sm: "block"}
                        }}
                    />
                </Box>
                <Divider />
                <Box sx={{overflowX: "auto"}}>
                    <Typography sx={{p:2, color: theme.palette.text.secondary, height:{sm: "180px", md: "170px", lg: "160px", xl:"140px"}}} variant="body2">{division.division.description ?? ""}</Typography>
                </Box>
            </Paper>
        </Grid>
    )
}

interface DivisionLineCardProps {
    divisionLine: DivisionLineResponse,
}

const DivisionLineCard = ({divisionLine}: DivisionLineCardProps) => {
    const loadedHead = useApiCall<UserSummaryResponse>({
        url: `/api/division-line/${divisionLine.id}/head`
    })
    const loadedStaff = useApiCall<UserSummaryResponse[]>({
        url: `/api/division-line/${divisionLine.id}/staff`
    })
    let staff = loadedStaff.data ?? []
    const head = loadedHead.data
    staff = staff.filter(s => s.communityId !== head?.communityId)

    const divisions = useApiCall<DivisionSummaryWithHead[]>({
        url: `/api/division-line/${divisionLine.id}/divisions`
    })

    let staffSection
    if (staff.length > 0) {
        staffSection = <>
            <Divider />
            <Box sx={{p:3}}>
                <Typography variant="subtitle1">Staff:</Typography>
                {staff.map((staff) => {
                    return <Stack direction="row" key={staff.communityId} alignItems={"center"}>
                        <DivisionChip user={staff} key={staff.communityId}/>
                    </Stack>
                })}
            </Box>
        </>
    } else {
        staffSection = <></>
    }

    return (

        <Grid item xs={12}>
            <Divider sx={{pt:2, pb:3}}>{divisionLine.name}</Divider>
            {/*<Typography variant="h6" gutterBottom>{divisionLine.name}</Typography>*/}
            <Paper sx={{mb:2}} variant={"outlined"}>
                <HeadBlock division={divisionLine.name} user={head} />
                {staffSection}
            </Paper>

            <Grid sx={{pt:1}} container spacing={2}>
                    <LoadingContent isLoading={divisions.isLoading}>
                            {(divisions.data || []).map((division) => {
                                return <DivisionEntry key={division.division.id} division={division}/>
                            })}
                    </LoadingContent>
            </Grid>
        </Grid>
    )
}

interface DivisionLineResponse {
    id: number,
    identifier: string,
    name: string,
    description: string
}

export const DivisionLinesPage = () => {
    const allLinesApiCall = useApiCall<DivisionLineResponse[]>({
        url: "/api/division-line"
    })
    const divisionLines = allLinesApiCall.data ?? []

    const divisionLineElements = divisionLines.map((divisionLine) => {
        return (
            <DivisionLineCard key={divisionLine.id} divisionLine={divisionLine}/>
        )
    })

    return (
        <>
            <LoadingContent isLoading={allLinesApiCall.isLoading}>
                <Grid container spacing={2}>
                    {divisionLineElements}
                </Grid>
            </LoadingContent>
        </>
    )
}