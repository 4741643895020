import {Grow, Link, Paper, Skeleton, Stack, Typography} from "@mui/material";
import {PoliceAvatar} from "../PoliceAvatar";
import {Link as RouterLink} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {RankChip} from "../RankChip";
import {MiniAward} from "../Award";
import {MiniProfileResponse} from "../../data/MiniProfileResponse";
import {PopperArrow, StyledPopper} from "../StyledPopper";
import {MiniProfileContext, MiniProfileTarget} from "../../hooks/useMiniProfile";

type MiniProfileState = "opening" | "closing" | "closed"

const defaultAnimationPeriod = 250

export const MiniProfilePopper = () => {
    const {
        miniProfile,
        loadMiniProfile,
        registerForHoverEvents,
        registerForUnmountEvents
    } = useContext(MiniProfileContext)
    const [target, setTarget] = useState<MiniProfileTarget>()
    const targetRef = useRef<MiniProfileTarget | null>(null)
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
    const [anchor, _setAnchor] = useState<HTMLElement | null>(null)
    const anchorRef = useRef<HTMLElement | null>(null)
    const [profileHovered, setProfileHovered] = useState<boolean>(false)
    const [state, setState] = useState<MiniProfileState>("closed")
    const hoverStartTimer = useRef<NodeJS.Timeout>()
    const miniProfileExitTimer = useRef<NodeJS.Timeout>()
    const gracePeriod = 500
    const openingDelay = 250
    const [animationPeriod, setAnimationPeriod] = useState(defaultAnimationPeriod)

    const setAnchor = (anchor: HTMLElement | null) => {
        anchorRef.current = anchor
        _setAnchor(anchor)
    }

    const clearOldTimers = () => {
        const hoverTimer = hoverStartTimer.current
        if (hoverTimer) {
            clearTimeout(hoverTimer)
        }
        const profileExitTimer = miniProfileExitTimer.current
        if (profileExitTimer) {
            clearTimeout(profileExitTimer)
        }
    }

    const hoverEventHandler = useCallback((target?: MiniProfileTarget) => {
        targetRef.current = target ?? null
        setTarget(target)
    }, [])

    const unmountEventHandler = useCallback((elem: HTMLElement) => {
        if (elem === anchorRef.current || elem === targetRef.current?.elem) {
            clearOldTimers()
            setAnimationPeriod(0)
            setTarget(undefined)
            setState(s => s !== "closed" ? "closing" : "closed")
        }
    }, [])

    useEffect(() => {
        const unregister = registerForHoverEvents(window.location.href, hoverEventHandler)
        return () => unregister()
    }, [hoverEventHandler, registerForHoverEvents]);

    useEffect(() => {
        const unregister = registerForUnmountEvents(window.location.href, unmountEventHandler)
        return () => unregister()
    }, [registerForUnmountEvents, unmountEventHandler]);

    const showMiniProfile = useCallback(() => {
        clearOldTimers()
        setAnimationPeriod(defaultAnimationPeriod)
        setAnchor(target?.elem ?? null)
        if (target?.communityId) {
            loadMiniProfile(target.communityId)
        }
        setState("opening")
    }, [loadMiniProfile, target])

    const startShowMiniProfile = useCallback(() => {
        clearOldTimers()
        hoverStartTimer.current = setTimeout(() => {
            showMiniProfile()
        }, openingDelay)
    }, [showMiniProfile])

    const hideMiniProfile = useCallback(() => {
        clearOldTimers()
        if (state === "closing") return
        setState("closing")
    }, [state])

    const startHideMiniProfileTimer = useCallback(() => {
        if (state === "closed" || state === "closing") return
        clearOldTimers()
        miniProfileExitTimer.current = setTimeout(() => {
            hideMiniProfile()
        }, gracePeriod)
    }, [state, hideMiniProfile])

    useEffect(() => {
        if (profileHovered) {
            clearOldTimers()
            return clearOldTimers
        }
        if (target?.communityId && (anchor && target.elem !== anchor)) {
            startHideMiniProfileTimer()
        } else if (!target?.communityId) {
            startHideMiniProfileTimer()
        } else if (state === "closed" && target?.communityId && target.elem) {
            startShowMiniProfile()
        }
        return clearOldTimers
    }, [target, state, anchor, showMiniProfile, startHideMiniProfileTimer, hideMiniProfile, startShowMiniProfile, profileHovered])

    return (
        <StyledPopper open={state === "opening"} anchorEl={anchor} placement="bottom" disablePortal={false}
                      modifiers={[
                          {
                              name: 'flip',
                              enabled: true,
                              options: {
                                  altBoundary: true,
                                  tether: true,
                                  rootBoundary: 'document',
                                  boundary: document.getElementById("mainContainer"),
                                  padding: 8,
                              },
                          },
                          {
                              name: 'preventOverflow',
                              enabled: true,
                              options: {
                                  altAxis: true,
                                  altBoundary: true,
                                  tether: true,
                                  rootBoundary: 'document',
                                  boundary: document.getElementById("mainContainer"),
                                  padding: 8,
                              },
                          },
                          {
                              name: 'arrow',
                              enabled: true,
                              options: {
                                  element: arrowRef,
                              },
                          },
                      ]}
                      onMouseEnter={_ => {
                          setProfileHovered(true)
                      }}
                      onMouseLeave={_ => {
                          setProfileHovered(false)
                      }}
                      transition
        >
            {({TransitionProps}) => (
                <Grow {...TransitionProps} timeout={animationPeriod} unmountOnExit onExited={() => {
                    if (state === "closing") {
                        setState("closed")
                        setAnchor(null)
                    }
                }}>
                    <Paper variant={"outlined"}  style={{padding: 8}}>
                        <PopperArrow ref={setArrowRef}/>
                        <MiniProfile miniProfile={miniProfile}/>
                    </Paper>
                </Grow>
            )}
        </StyledPopper>
    )
}

interface MiniProfileProps {
    miniProfile?: MiniProfileResponse
}

export const MiniProfile = ({miniProfile}: MiniProfileProps) => {
    let content;
    if (miniProfile) {
        content = <MiniProfileContent miniProfile={miniProfile}/>
    } else {
        content = <Stack direction="row" spacing={1}>
            <Skeleton variant="circular" width={64} height={64}/>
            <Skeleton variant="rounded" width={128} height={64}/>
        </Stack>
    }
    return content
}

interface MiniProfileContentProps {
    miniProfile: MiniProfileResponse
}

const MiniProfileContent = ({miniProfile}: MiniProfileContentProps) => {
    return <Stack direction="row" spacing={1}>
        <div style={{alignSelf: "center"}}>
            <PoliceAvatar online={miniProfile.online} avatarUrl={miniProfile.mediumAvatarLink}
                          color={miniProfile.rank?.color ?? "#F5F7F7"} dimensions={64}
                          userCommunityId={miniProfile.communityId}/>
        </div>
        <Stack alignItems="start">
            <Link underline="none" component={RouterLink} variant="body1" sx={{marginLeft: "8px"}}
                  to={`/user/${miniProfile.communityId}`}>{miniProfile.nick}</Link>
            <Typography variant="body2" sx={{fontSize: "0.75em", paddingBottom: "4px", marginLeft: "8px"}}>
                {miniProfile.pronouns}
            </Typography>
            <Typography variant="body2" sx={{fontSize: "0.75em", paddingBottom: "4px", marginLeft: "8px"}}>
                ({miniProfile.badgeNumber}) {miniProfile.rpName}
            </Typography>
            <RankChip rank={miniProfile.rank?.displayName ?? "Civilian"}
                      color={miniProfile.rank?.color ?? "#F5F7F7"}/>
        </Stack>
        <Stack spacing={0.5} direction="row" style={{width: 56}} useFlexGap flexWrap="wrap">
            {miniProfile.awards.slice(0, 9).map((award) =>
                <MiniAward award={award} key={award.id}/>
            )}
        </Stack>
    </Stack>
}
