import {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Alert, Checkbox, FormControlLabel, Input, Tooltip} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {ProfileResponse} from "../../data/ProfileResponse";

interface IssueRecordNoteFormProps {
    employee: ProfileResponse,
    searchRoot: string
}

export const IssueRecordNoteForm = (props: IssueRecordNoteFormProps) => {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("")
    const [hidden, setHidden] = useState(false)
    const navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant={"outlined"} color="info" onClick={handleClickOpen}>Add new Note</Button>
            <Dialog open={open} onClose={handleClose}>
                <Alert sx={{m:2}} variant={"outlined"} severity="warning">Record notes should never hide a mistake from the employee, if the note is being used to track wrongdoing on behalf of the employee it should not be hidden</Alert>
                <DialogTitle>Issue Note</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Reason for Note
                    </DialogContentText>
                    <Input multiline rows={3} placeholder={"Enter reason..."} size="small" sx={{pt:2, width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogContent>
                    <Tooltip
                        title={"Hides the note from the employee's view"}>
                        <FormControlLabel label={"Hidden"} control={
                            <Checkbox onChange={(_, checked) => setHidden(checked)} checked={hidden}/>
                        }/>
                    </Tooltip>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant={"contained"} color={"error"} onClick={() =>
                        makeApiCall({
                            url: `/api/records/action/add-record-note`,
                            method: "POST",
                            body: {
                                employeeCommunityId: props.employee.communityId,
                                reason: reason,
                                hidden: hidden
                            },
                            onLoadedCallback: () => {
                                navigate(`/records/${props.searchRoot}`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Issue</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}