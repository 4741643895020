import {UserAssessmentFeedbackResponse} from "../../data/UserAssessmentFeedbackResponse";
import {UserAssessmentVariant} from "./UserAssessmentQuestion";
import {Box, Button, Checkbox, Chip, FormControlLabel, Paper, Stack, Typography} from "@mui/material";
import {PoliceAvatar} from "../PoliceAvatar";
import {EditorContent} from "../editor/EditorContent";
import {TextEditor} from "../editor/TextEditor";
import {$getRoot, LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import React, {useCallback, useRef, useState} from "react";
import {UserAssessmentQuestionResponse} from "../../data/UserAssessmentQuestionResponse";
import {PoliceUser} from "../PoliceUser";
import {MiniProfileTarget} from "../MiniProfileTarget";

interface UserAssessmentFeedbackProps {
    question: UserAssessmentQuestionResponse
    canDeleteFeedback?: boolean
    doSubmitFeedback?: (question: UserAssessmentQuestionResponse, feedback: string, isPrivate: boolean, onSuccess: (response: UserAssessmentFeedbackResponse) => void) => void
    doDeleteFeedback?: (question: UserAssessmentQuestionResponse, id: number, onSuccess: () => void) => void
    variant?: UserAssessmentVariant
}

export const UserAssessmentFeedback = (props: UserAssessmentFeedbackProps) => {
    const editorRef = useRef<LexicalEditor>(null)
    const [feedback, setFeedback] = useState(props.question.feedback)
    const [isPrivate, setIsPrivate] = useState(true)

    const onSuccess = useCallback((response: UserAssessmentFeedbackResponse) => {
        if (editorRef.current) {
            editorRef.current.update(() => $getRoot().clear())
        }
        const newFeedback = feedback.map(f => f)
        newFeedback.push(response)
        setFeedback(newFeedback)
    }, [feedback])

    const handleSubmit = useCallback(() => {
        if (editorRef.current && props.doSubmitFeedback) {
            const editor = editorRef.current
            const doSubmitFeedback = props.doSubmitFeedback
            editorRef.current.getEditorState().read(() => {
                let message = $generateHtmlFromNodes(editor)
                doSubmitFeedback(props.question, message, isPrivate, onSuccess)
            })
        }
    }, [isPrivate, onSuccess, props.doSubmitFeedback, props.question])

    const deleteFeedback = (id: number) => {
        if (props.doDeleteFeedback) {
            const doDeleteFeedback = props.doDeleteFeedback
            return () => doDeleteFeedback(props.question, id, () => setFeedback(feedback.filter(f => f.id !== id)))
        } else {
            return () => {
            }
        }
    }

    return <>
        <Typography variant="h5">Feedback:</Typography>
        {feedback.map(f => {
            return <FeedbackMessage key={f.id} feedback={f} deleteFeedback={deleteFeedback(f.id)}
                                    canDeleteFeedback={props.canDeleteFeedback} variant={props.variant}/>
        })}
        {props.variant === "grader" ? <>
            <TextEditor ref={editorRef}/>
            <FormControlLabel
                control={<Checkbox checked={isPrivate} onChange={(_, checked) => setIsPrivate(checked)}/>}
                label={"Private"}/>
            <Button variant="contained" onClick={handleSubmit}>
                Submit
            </Button>
        </> : <></>
        }
    </>
}

interface FeedbackMessageProps {
    feedback: UserAssessmentFeedbackResponse
    canDeleteFeedback?: boolean
    deleteFeedback: () => void
    variant?: UserAssessmentVariant
}

const FeedbackMessage = (props: FeedbackMessageProps) => {
    const [hovered, setHovered] = useState(false)

    const deleteButton =
        <Button variant="contained" size="small" color="error" onClick={props.deleteFeedback}>Delete</Button>

    return <Paper elevation={4} sx={{padding: "8px"}} onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}>
        <Stack spacing={1} direction="row" alignItems="flex-start">
            <MiniProfileTarget communityId={props.feedback.user.communityId}>
                <PoliceAvatar online={false} userCommunityId={props.feedback.user.communityId}
                              avatarUrl={`/api/user/${props.feedback.user.communityId}/avatar`}
                              color={props.feedback.user.policeRank.color}/>
            </MiniProfileTarget>
            <Box width={"100%"}>
                <Stack direction="row" justifyContent="space-between" flexWrap="wrap" sx={{minHeight: "32px"}}>
                    <Stack direction="row" spacing={1}>
                        <PoliceUser user={props.feedback.user} rank={props.feedback.user.policeRank}/>
                        {props.feedback.isPrivate ? <Chip color="warning" label={"Private"} size="small"/> : <></>}
                    </Stack>
                    {hovered && props.canDeleteFeedback ? deleteButton : <></>}
                </Stack>
                <EditorContent content={props.feedback.feedback}/>
            </Box>
        </Stack>
    </Paper>

}
