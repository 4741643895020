import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Input, InputLabel, MenuItem, Select} from "@mui/material";
import {useState} from "react";
import useUser from "../../hooks/useUser";
import {useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {ProfileResponse} from "../../data/ProfileResponse";
import {RankResponse} from "../../data/RankResponse";

interface infractionType {
    id: number,
    identifier: string,
    displayName: string,
    permission: string
}

export const infractionTypes: infractionType[] = [
    {
        id: 0,
        identifier: "blacklist",
        displayName: "Blacklist",
        permission: "professionalStandards:blacklist"
    },
    {
        id: 1,
        identifier: "suspension",
        displayName: "Suspension",
        permission: "professionalStandards:suspend"
    },
    {
        id: 2,
        identifier: "reprimand",
        displayName: "Reprimand",
        permission: "professionalStandards:issueReprimand"
    },
    {
        id: 3,
        identifier: "written_warning",
        displayName: "Written Warning",
        permission: "professionalStandards:issueWrittenWarning"
    },
    {
        id: 4,
        identifier: "demotion_to_probationary_officer",
        displayName: "Demotion to Probationary Officer",
        permission: "professionalStandards:demoteToProbationaryOfficer"
    },
    {
        id: 5,
        identifier: "demotion",
        displayName: "Demotion",
        permission: "professionalStandards:demote"
    },
    {
        id: 6,
        identifier: "role-removal",
        displayName: "Division Role Removal",
        permission: "professionalStandards:revokeDivisionRole"
    },
    {
        id: 7,
        identifier: "discharge",
        displayName: "Discharge",
        permission: "professionalStandards:discharge"
    },
    {
        id: 8,
        identifier: "comment_card",
        displayName: "Comment Card",
        permission: ""
    }
]

interface IssueInfractionFormProps {
    employee: ProfileResponse,
    searchRoot: string,
    ranks: RankResponse[]
}

export default function IssueInfractionForm(props: IssueInfractionFormProps) {
    const [open, setOpen] = useState(false);
    const [infraction, setInfraction] = useState("")
    const [reason, setReason] = useState("")
    const [param, setParam] = useState<string>("")
    const user = useUser()
    const navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let paramSelector = <></>
    if (infraction === "role-removal") {
        paramSelector = <DialogContent>
            <DialogContentText>
                Role to Remove
            </DialogContentText>
            <Select size="small" sx={{width: 500}} id="param-select" value={param} variant="standard"
                    onChange={(event) => setParam(event.target.value)}
            >
                {
                    props.employee.roles.map(role => {
                        return <MenuItem key={role.identifier} value={role.identifier}>{role.displayName}</MenuItem>
                    })
                }
            </Select>
        </DialogContent>
    } else if (infraction === "demotion") {
        paramSelector = <DialogContent>
            <DialogContentText>
                Rank to Demote to
            </DialogContentText>
            <Select size="small" sx={{width: 500}} id="param-select" value={param} variant="standard"
                    onChange={(event) => setParam(event.target.value)}
            >
                {
                    props.ranks.filter(rank => {
                        if (props.employee.rank?.immunity === undefined) { return false }
                        return rank.immunity < props.employee.rank?.immunity
                    }).map(rank => {
                        return <MenuItem key={rank.identifier} value={rank.identifier}>{rank.displayName}</MenuItem>
                    })
                }
            </Select>
        </DialogContent>
    }

    return (
        <React.Fragment>
            <Button variant={"outlined"} color="error" onClick={handleClickOpen}>Issue new Infraction</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Issue Infraction</DialogTitle>
                <DialogContent>
                        <InputLabel id="infraction-select-label">Infraction Type</InputLabel>
                        <Select size="small" sx={{width: 500}} id="infraction-select" value={infraction} variant="standard"
                                onChange={(event) => setInfraction(event.target.value)}
                        >
                            {infractionTypes.filter(infraction => {
                                return user.hasPermissions(infraction.permission)}).map((infractionType) =>
                                <MenuItem key={infractionType.id} value={infractionType.identifier}>{infractionType.displayName}</MenuItem>
                            )}
                        </Select>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        Reason for Infraction
                    </DialogContentText>
                    <Input multiline rows={3} placeholder={"Enter reason..."} size="small" sx={{width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                {paramSelector}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color={"error"} variant={"contained"} onClick={() =>
                        makeApiCall({
                            url: `/api/records/action/${infraction}`,
                            method: "POST",
                            body: {
                                employeeCommunityId: props.employee.communityId,
                                reason: reason,
                                param: param
                            },
                            onLoadedCallback: () => {
                                navigate(`/records/${props.searchRoot}`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Issue</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}