import React, {useRef, useState} from "react";
import {Button, Divider, Input, InputLabel, MenuItem, Paper, Select, Stack, Typography} from "@mui/material";
import {LexicalEditor} from "lexical";
import {TextEditor} from "../../components/editor/TextEditor";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import {$generateHtmlFromNodes} from '@lexical/html';
import {DetailedResourceResponse} from "../../data/DetailedResourceResponse";

export const NewResourcePage = () => {
    const params = useParams()
    const editorRef = useRef<LexicalEditor>(null)
    const [type, setType] = useState("content")
    const [privacy, setPrivacy] = useState(0)
    const [title, setTitle] = useState("")
    const [url, setUrl] = useState("")
    const navigate = useNavigate()

    let entryType
    if (type === "content") {
        entryType = <TextEditor ref={editorRef}/>
    } else {
        entryType = <>
            <InputLabel>Resource URL</InputLabel>
            <Input placeholder={"Url..."} size="small"
                   sx={{width: 500}}
                   onChange={(event) => setUrl(event.target.value)} />
            <Divider />
        </>
    }

    const divisionIdentifierLink = params.divisionIdentifier?.replaceAll("-", "_")

    return (
        <Stack spacing={1}>
            <Typography variant="h6" gutterBottom>Resource Creation</Typography>

            <Paper variant="outlined" style={{
                marginTop: "16px",
                padding: "16px"
            }}>
                <Input placeholder={"Title..."} size="small"
                       sx={{width: 500, pt:1}}
                       onChange={(event) => setTitle(event.target.value)}
                ></Input>
                <InputLabel sx={{pt:2}}>Private</InputLabel>
                <Select
                    id={`select-privacy`}
                    value={Number(privacy)}
                    sx={{width: 500}}
                    onChange={(event) => {
                        setPrivacy(event.target.value as number)
                    }}
                    variant="standard"
                    label="Private Resource"
                >
                    <MenuItem key={1} value={1}>Yes</MenuItem>
                    <MenuItem key={0} value={0}>No</MenuItem>
                </Select>
                <InputLabel sx={{pt:2}}>Type</InputLabel>
                <Select
                    id={`select-type`}
                    value={type}
                    sx={{width: 500, mb:2}}
                    onChange={(event) => {
                        setType(event.target.value as string)
                    }}
                    variant="standard"
                    label="Private Resource"
                >
                    <MenuItem key={"content"} value={"content"}>Content</MenuItem>
                    <MenuItem key={"link"} value={"link"}>Link</MenuItem>
                </Select>
                {entryType}
                <Button disabled={title === null} color="success" onClick={() => {
                    if (editorRef.current && type === "content") {
                        editorRef.current.getEditorState().read(() => {
                            makeApiCall<DetailedResourceResponse>({
                                url: `/api/resource/${divisionIdentifierLink}/create`,
                                method: "POST",
                                body: {
                                    title: title,
                                    type: type,
                                    content: $generateHtmlFromNodes(editorRef.current!),
                                    privateResource: Boolean(privacy)
                                },
                                onLoadedCallback: (data) => {
                                    navigate(`/resource/${params.divisionIdentifier}/${data.identifier}`)
                                },
                                onError: () => {

                                }
                            })
                        })
                    } else {
                        makeApiCall({
                            url: `/api/resource/${divisionIdentifierLink}/create`,
                            method: "POST",
                            body: {
                                title: title,
                                type: type,
                                content: url,
                                privateResource: Boolean(privacy)
                            },
                            onLoadedCallback: () => {
                                navigate(`/resources/${divisionIdentifierLink}`)
                            },
                            onError: () => {

                            }
                        })
                    }
                }}>Create</Button>
                <Button component={RouterLink} to={`/resources/${params.divisionIdentifier?.replaceAll("_", "-")}`}
                        color="warning">Return</Button>
            </Paper>
        </Stack>
    )
}