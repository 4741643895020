import {LoadingContent} from "../components/LoadingContent";
import useApiCall from "../hooks/CancellableApiCall";
import useUser from "../hooks/useUser";
import {HelpdeskTicketResponse} from "../data/helpdesk/HelpdeskTicketResponse";
import {AccountResponse} from "../data/AccountResponse";
import {
    Box,
    Button,
    Chip,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {formatTimestamp} from "../utils";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {PoliceUser} from "../components/PoliceUser";
import {HelpdeskTicketStatus} from "../data/helpdesk/HelpdeskTicketStatus";
import {HelpdeskPageninatedResponse} from "../data/helpdesk/HelpdeskPageninatedResponse";

interface HelpdeskPageContentProps {
    ticketData: HelpdeskPageninatedResponse,
    user: AccountResponse
}

interface HelpdeskTicketRowProps {
    ticket: HelpdeskTicketResponse
}

function HelpdeskTicketRow(props: HelpdeskTicketRowProps) {
    let ticketStatus = HelpdeskTicketStatus.get(props.ticket.status)
    let statusColor
    if (ticketStatus?.displayName === "Unclaimed") {
        statusColor = "red"
    } else if (ticketStatus?.displayName === "Awaiting Submitter") {
        statusColor = "green"
    } else if (ticketStatus?.displayName === "Reassigned") {
        statusColor = "#21cc96"
    } else if (ticketStatus?.displayName === "Awaiting Assignee Response") {
        statusColor = "#bb3578"
    } else if (ticketStatus?.displayName === "Closed") {
        statusColor = "#7521cc"
    } else {
        statusColor = "#f8ac59"
    }

    let assigneeRank: { displayName: string, color: string } | undefined = undefined
    if (props.ticket.assigneeRankDisplayName && props.ticket.assigneeRankColor) {
        assigneeRank = {displayName: props.ticket.assigneeRankDisplayName, color: props.ticket.assigneeRankColor}
    }

    let creatorRank: { displayName: string, color: string } | undefined = undefined
    if (props.ticket.creatorRankDisplayName && props.ticket.creatorRankColor) {
        creatorRank = {displayName: props.ticket.creatorRankDisplayName, color: props.ticket.creatorRankColor}
    }

    return <TableRow>
        <TableCell> {props.ticket.id} </TableCell>
        <TableCell> <Chip color={"primary"} style={{backgroundColor: statusColor, height: "20px"}}
                          label={ticketStatus?.displayName}/> </TableCell>
        <TableCell> {formatTimestamp(props.ticket.date)} </TableCell>
        <TableCell> <Box display="flex" flexWrap="wrap" gap={1}>
            <PoliceUser user={{nick: props.ticket.creatorNick, communityId: props.ticket.creatorCommunityId}}
                        rank={creatorRank}/>
        </Box> </TableCell>
        <TableCell> {props.ticket.title} </TableCell>
        <TableCell> {props.ticket.messages?.length ?? 0} </TableCell>
        <TableCell> {props.ticket.divisionDisplayName} </TableCell>
        <TableCell> <Box display="flex" flexWrap="wrap" gap={1}>
            {props.ticket.assigneeNick && props.ticket.assigneeCommunityId ?
                <PoliceUser user={{nick: props.ticket.assigneeNick, communityId: props.ticket.assigneeCommunityId}}
                            rank={assigneeRank}/> :
                "Unassigned"
            }
        </Box> </TableCell>
        <TableCell> <Button component={RouterLink}
                            to={`/helpdesk/ticket/${props.ticket.id}`}
                            color="success">View
            Ticket</Button> </TableCell>
    </TableRow>
}

export function HelpdeskPageContent(props: HelpdeskPageContentProps) {
    return <Box sx={{overflowX:"auto"}}><Table>
        <TableHead>
            <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Creator</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Messages</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Assignee</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                props.ticketData?.tickets.map(ticket => {
                        return <HelpdeskTicketRow ticket={ticket} key={ticket.id}/>
                    }
                )
            }
        </TableBody>
    </Table></Box>
}

export const HelpdeskPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const ticketData = useApiCall<HelpdeskPageninatedResponse>({
        url: `/api/helpdesk/?page=${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    const totalPages = ticketData.data?.pages ?? 1

    const userData = useUser()

    return <LoadingContent isLoading={userData.isLoading || ticketData.isLoading}>
        <Typography variant={"h6"} gutterBottom>Paralake Police Helpdesk</Typography>
        <Paper variant={"outlined"} style={{
            padding: "16px"
        }}>
            <HelpdeskPageContent user={userData.user!!} ticketData={ticketData.data!!}/>
            <Button component={RouterLink} to={`/helpdesk/create`} color="success">New Ticket</Button>
            <Button component={RouterLink} to={`/helpdesk/archived`} color="info">View Archive</Button>
        </Paper>
        <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
            <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                        onChange={handleChange}/>
        </Box>
    </LoadingContent>
}