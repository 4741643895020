import {
    createContext,
    Dispatch,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {ApiCallResponseData, makeApiCall} from "./CancellableApiCall";
import {browserHasWebSocketSupport, createWebSocket} from "../utils";
import useUser from "./useUser";

interface NavBarState {
    unreadConversationCount: number,
    unreadNotificationCount: number
}

export interface NavBarStateContextProps {
    data: NavBarState | null
    isLoading: boolean
    isError: boolean
    refresh: () => void
    sidebarOpen: boolean
    setSidebarOpen: (state: boolean) => void
    useSidebarMenuState: (category: string) => [boolean, Dispatch<boolean>]
}

export const NavBarStateContext = createContext<NavBarStateContextProps>({
    data: null,
    isLoading: false,
    isError: false,
    refresh: () => {},
    sidebarOpen: false,
    setSidebarOpen: () => {},
    useSidebarMenuState: () => [false, () => {}],
})

export const NavBarStateProvider = ({children}: PropsWithChildren) => {
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [data, setData] = useState<NavBarState>()
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [sidebarMenuState, setSidebarMenuState] = useState<Map<string, boolean>>(new Map())
    const loadingData = useRef(false)
    const webSocket = useRef<WebSocket | null>(null)
    const webSocketRestartTimer = useRef<NodeJS.Timer | null>(null)
    const fallbackUpdateTimer = useRef<NodeJS.Timer | null>(null)
    const userData = useUser({requireUser: false})

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall])

    const stopRefreshing = () => {
        if (!browserHasWebSocketSupport()) {
            if (fallbackUpdateTimer.current) {
                clearInterval(fallbackUpdateTimer.current)
            }
        } else {
            const timer = webSocketRestartTimer.current
            const ws = webSocket.current
            if (timer) {
                clearTimeout(timer)
            }
            if (ws) {
                ws.onmessage = () => {}
                ws.onclose = () => {}
                ws.close()
            }
            webSocket.current = null
        }
    }

    const restartRefreshing = useCallback(() => {
        if (!browserHasWebSocketSupport()) {
            fallbackUpdateTimer.current = setInterval(() => {
                refresh()
            }, 10000)
        } else {
            stopRefreshing()
            const ws = createWebSocket("/api/navbar/ws")
            if (!ws) return
            const timer = webSocketRestartTimer.current
            ws.onmessage = () => {
                refresh()
            }
            ws.onclose = () => {
                if (timer) {
                    clearTimeout(timer)
                }
                webSocketRestartTimer.current = setTimeout(() => {
                    restartRefreshing()
                }, 5000)
            }
            webSocket.current = ws
        }
    }, [])

    useEffect(() => {
        if (userData.user) {
            restartRefreshing()
        }
        return () => {
            stopRefreshing()
        }
    }, [userData.user, restartRefreshing])

    const refresh = () => {
        if (loadingData.current) {
            return
        }
        loadingData.current = true
        setIsLoading(true)
        setApiCall(makeApiCall({
            url: "/api/navbar/state",
            onLoadedCallback: (response: NavBarState) => {
                loadingData.current = false
                setIsLoading(false)
                setData(response)
            },
            onError: () => {
                loadingData.current = false
                setIsError(true)
                setIsLoading(false)
            }
        }))
    }

    return <NavBarStateContext.Provider value={{
        data: data ?? null,
        isLoading: isLoading,
        isError: isError,
        refresh: refresh,
        sidebarOpen: sidebarOpen,
        setSidebarOpen: setSidebarOpen,
        useSidebarMenuState: (category: string) => [
            sidebarMenuState.get(category) ?? false,
            (newState: boolean) => {
                // For now, we only allow a single menu to be opened, and all others will be closed
                const newMap = new Map()
                newMap.set(category, newState)
                setSidebarMenuState(newMap)
            }
        ]
    }}>
        {children}
    </NavBarStateContext.Provider>
}

export function useNavBarState(): NavBarStateContextProps {
    const context = useContext(NavBarStateContext)

    useEffect(() => {
        const currentNotifications = context.data
        if (!context.isLoading && !context.isError && !currentNotifications) {
            context.refresh()
        }
    }, [context]);

    return {
        data: context.data,
        isLoading: context.isLoading,
        isError: context.isError,
        refresh: context.refresh,
        sidebarOpen: context.sidebarOpen,
        setSidebarOpen: context.setSidebarOpen,
        useSidebarMenuState: context.useSidebarMenuState
    }
}