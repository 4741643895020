import {PropsWithChildren} from "react";
import {CircularProgress, Skeleton} from "@mui/material";


interface LoadingContentProps extends PropsWithChildren {
    isLoading: boolean,
    showLoadingSkeleton?: boolean
}

export const LoadingContent = (props: LoadingContentProps) => {
    let content;
    if (props.isLoading) {
        if (props.showLoadingSkeleton) {
            content = <>
                <Skeleton width={210} height={20}/>
                <Skeleton width={160} height={20}/>
                <Skeleton width={100} height={20}/>
            </>
        } else {
            content = <CircularProgress/>
        }
    } else {
        content = <>{props.children}</>
    }
    return content
}