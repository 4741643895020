import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import React, {PropsWithChildren, RefObject, useCallback, useEffect, useRef, useState} from "react";
import {
    $createParagraphNode,
    $getSelection,
    $isRangeSelection, BaseSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    LexicalEditor,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from "lexical";
import {$isLinkNode, TOGGLE_LINK_COMMAND} from "@lexical/link";
import {$isAtNodeEnd, $setBlocksType} from "@lexical/selection";
import {$getNearestNodeOfType, mergeRegister} from "@lexical/utils";
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from "@lexical/list";
import {$createHeadingNode, $createQuoteNode, $isHeadingNode} from "@lexical/rich-text";
import {RangeSelection} from "lexical/LexicalSelection";
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import LinkIcon from '@mui/icons-material/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import TitleIcon from '@mui/icons-material/Title';
import CodeIcon from '@mui/icons-material/Code';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SegmentIcon from '@mui/icons-material/Segment';
import {
    ClickAwayListener,
    Divider,
    IconButton,
    MenuItem,
    Paper,
    Popper,
    PopperProps,
    Stack,
    styled,
    TextField,
    useTheme
} from "@mui/material";
import {Dropdown} from '@mui/base/Dropdown';
import {MenuButton} from '@mui/base/MenuButton'
import {Menu} from '@mui/base/Menu';
import {grey} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import {InsertImageDialog} from "./ImagePlugin";

const LowPriority = 1;

const supportedBlockTypes = new Set([
    "paragraph",
    "quote",
    "h1",
    "h2",
    "ul",
    "ol"
]);

const blockTypeToBlockName = {
    code: "Code Block",
    h1: "Large Heading",
    h2: "Small Heading",
    h3: "Heading",
    h4: "Heading",
    h5: "Heading",
    ol: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
    ul: "Bulleted List"
};

const blockTypeIcons = {
    code: <CodeIcon fontSize="small" sx={{mr: 1}}/>,
    h1: <TitleIcon fontSize="small" sx={{mr: 1}}/>,
    h2: <TitleIcon fontSize="small" sx={{mr: 1}}/>,
    h3: <TitleIcon fontSize="small" sx={{mr: 1}}/>,
    h4: <TitleIcon fontSize="small" sx={{mr: 1}}/>,
    h5: <TitleIcon fontSize="small" sx={{mr: 1}}/>,
    ol: <FormatListNumberedIcon fontSize="small" sx={{mr: 1}}/>,
    paragraph: <SegmentIcon fontSize="small" sx={{mr: 1}}/>,
    quote: <FormatQuoteIcon fontSize="small" sx={{mr: 1}}/>,
    ul: <FormatListBulletedIcon fontSize="small" sx={{mr: 1}}/>
}

interface FloatingLinkEditorProps {
    editor: LexicalEditor
}

function FloatingLinkEditor({editor}: FloatingLinkEditorProps) {
    const inputRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null)
    const [linkUrl, setLinkUrl] = useState("https://");
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState<null | BaseSelection>(null);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl("");
            }
        }
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            nativeSelection &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect: DOMRect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild as HTMLElement;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }
            if (!anchorEl) {
                setAnchorEl({
                    getBoundingClientRect(): DOMRect {
                        return rect
                    }
                })
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== "link-input") {
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl("");
            setAnchorEl(null)
        }

        return true;
    }, [anchorEl, editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            (inputRef.current as HTMLInputElement).focus();
        }
    }, [isEditMode]);

    let editIcon
    if (isEditMode) {
        editIcon = <CancelIcon fontSize="small"/>
    } else {
        editIcon = <EditIcon fontSize="small"/>
    }

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={(e) => {
            if (("button" in e && e.button === 1) || "touches" in e) {
                setAnchorEl(null)
            }
        }}>
            <Popper style={{zIndex: 10000}} open={anchorEl !== null} anchorEl={anchorEl}>
                <Paper elevation={3} sx={{p: 1}}>
                    <Stack direction="row">
                        <TextField value={linkUrl} variant="standard" type="url" disabled={!isEditMode} inputProps={{
                            autoFocus: isEditMode,
                            sx: {
                                textDecoration: "underline",
                                color: "#556cd6",
                                minWidth: 300,
                            }
                        }} onChange={(event) => {
                            setLinkUrl(event.target.value)
                        }}
                                   onKeyDown={(event) => {
                                       if (event.key === "Enter") {
                                           event.preventDefault();
                                           if (lastSelection !== null) {
                                               if (linkUrl !== "") {
                                                   editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                                               }
                                               setEditMode(false);
                                           }
                                       } else if (event.key === "Escape") {
                                           event.preventDefault();
                                           setEditMode(false);
                                       }
                                   }}>

                        </TextField>
                        <IconButton onClick={() => setEditMode(!isEditMode)}>
                            {editIcon}
                        </IconButton>
                    </Stack>
                </Paper>
            </Popper>
        </ClickAwayListener>
    )
}

function getSelectedNode(selection: RangeSelection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

interface BlockOptionsDropdownListProps {
    editor: LexicalEditor,
    blockType: keyof typeof blockTypeToBlockName,
    toolbarRef: RefObject<HTMLDivElement>
}


const StyledListbox = styled('ul')(
    ({theme}) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 16px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  z-index: 1;
  `,
);


function BlockOptionsDropdownList({
                                      editor,
                                      blockType,
                                      toolbarRef
                                  }: BlockOptionsDropdownListProps) {
    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const {top, left} = toolbar.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;
        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle: EventListenerOrEventListenerObject = (event) => {
                const target = event.target;

                if (!dropDown.contains(target as Node) && !toolbar.contains(target as Node)) {
                }
            };
            document.addEventListener("click", handle);

            return () => {
                document.removeEventListener("click", handle);
            };
        }
    }, [dropDownRef, toolbarRef]);

    const formatParagraph = () => {
        if (blockType !== "paragraph") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createParagraphNode());
                }
            });
        }
    };

    const formatLargeHeading = () => {
        if (blockType !== "h1") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode("h1"));
                }
            });
        }
    };

    const formatSmallHeading = () => {
        if (blockType !== "h2") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode("h2"));
                }
            });
        }
    };

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatQuote = () => {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
    }

    return (
        <Menu slots={{listbox: StyledListbox}}>
            <MenuItem onClick={formatParagraph}>
                {blockTypeIcons.paragraph}
                <span className="text">Normal</span>
                {blockType === "paragraph" && <span className="active"/>}
            </MenuItem>
            <MenuItem className="item" onClick={formatLargeHeading}>
                {blockTypeIcons.h1}
                <span className="text">Large Title</span>
                {blockType === "h1" && <span className="active"/>}
            </MenuItem>
            <MenuItem className="item" onClick={formatSmallHeading}>
                {blockTypeIcons.h2}
                <span className="text">Small Title</span>
                {blockType === "h2" && <span className="active"/>}
            </MenuItem>
            <MenuItem className="item" onClick={formatBulletList}>
                {blockTypeIcons.ul}
                <span className="text">Bullet List</span>
                {blockType === "ul" && <span className="active"/>}
            </MenuItem>
            <MenuItem className="item" onClick={formatNumberedList}>
                {blockTypeIcons.ol}
                <span className="text">Numbered List</span>
                {blockType === "ol" && <span className="active"/>}
            </MenuItem>
            <MenuItem className="item" onClick={formatQuote}>
                {blockTypeIcons.quote}
                <span className="text">Quote</span>
                {blockType === "quote" && <span className="active"/>}
            </MenuItem>
        </Menu>
    );
}


interface ToolbarButtonProps extends PropsWithChildren {
    onClick?: () => void
    active?: boolean
    disabled?: boolean
}


function ToolbarButton(props: ToolbarButtonProps) {
    const theme = useTheme()
    let sx: SxProps<Theme> = {
        marginLeft: "4px",
        marginRight: "4px",
        "&:hover": {}
    }
    if (props.active) {
        let grey = theme.palette.grey[300]
        let hoveredGrey = theme.palette.grey[400]
        if (theme.palette.mode === "dark") {
            grey = theme.palette.grey[800]
            hoveredGrey = theme.palette.grey[700]
        }
        sx.background = grey
        sx["&:hover"] = {
            background: hoveredGrey
        }
    }
    return (
        <IconButton onClick={props.onClick} disabled={props.disabled} sx={sx}>
            {props.children}
        </IconButton>
    )
}

const TriggerButton = styled(MenuButton)(
    ({theme}) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 0.875rem;
  min-width: 200px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }
  `,
);

export default function ToolbarPlugin() {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef<HTMLDivElement>(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState<keyof typeof blockTypeToBlockName>("paragraph");
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [insertImageDialogOpen, setInsertImageDialogOpen] = useState(false)

    let insertImageDialog
    if (insertImageDialogOpen) {
        insertImageDialog = <InsertImageDialog activeEditor={editor} onClose={() => setInsertImageDialogOpen(false)}/>
    }

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type as keyof typeof blockTypeToBlockName);
                }
            }
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));
            //setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, _) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    let dropdownIcon
    if (dropdownOpen) {
        dropdownIcon = <KeyboardArrowUpIcon fontSize="small"/>
    } else {
        dropdownIcon = <KeyboardArrowDownIcon fontSize="small"/>
    }

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    return (
        <Stack direction="row" sx={{
            paddingLeft: "6px",
            paddingTop: "6px",
            paddingBottom: "6px",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: "4px 4px 0 0",
            overflow: "auto",
            flexWrap: "wrap",
            alignItems: "center"
        }}>
            <ToolbarButton
                disabled={!canUndo}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
            >
                <UndoIcon fontSize="small"/>
            </ToolbarButton>
            <ToolbarButton
                disabled={!canRedo}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined);
                }}
            >
                <RedoIcon fontSize="small"/>
            </ToolbarButton>
            <Divider orientation="vertical" variant="middle" flexItem/>
            {supportedBlockTypes.has(blockType) && (
                <>
                    <Dropdown open={dropdownOpen} onOpenChange={(_, open) => setDropdownOpen(open)}>
                        <TriggerButton>
                            <Stack direction="row">
                                {blockTypeIcons[blockType]}
                                <Typography>
                                    {blockTypeToBlockName[blockType]}
                                </Typography>
                                <div style={{flexGrow: 1}}></div>
                                {dropdownIcon}
                            </Stack>
                        </TriggerButton>
                        <BlockOptionsDropdownList
                            editor={editor}
                            blockType={blockType}
                            toolbarRef={toolbarRef}
                        />
                    </Dropdown>
                    <Divider orientation="vertical" variant="middle" flexItem/>
                </>
            )}
            <>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                    }}
                    active={isBold}
                >
                    <FormatBoldIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                    }}
                    active={isItalic}
                >
                    <FormatItalicIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                    }}
                    active={isUnderline}
                >
                    <FormatUnderlinedIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                    }}
                    active={isStrikethrough}
                >
                    <FormatStrikethroughIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                    }}
                    active={isCode}
                >
                    <CodeIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={insertLink}
                    active={isLink}
                >
                    <LinkIcon fontSize="small"/>
                </ToolbarButton>
                {isLink && <FloatingLinkEditor editor={editor}/>}
                <Divider orientation="vertical" variant="middle" flexItem/>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                    }}
                    active={false}
                >
                    <FormatAlignLeftIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                    }}
                    active={false}
                >
                    <FormatAlignCenterIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                    }}
                    active={false}
                >
                    <FormatAlignRightIcon fontSize="small"/>
                </ToolbarButton>
                <ToolbarButton
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                    }}
                    active={false}
                >
                    <FormatAlignJustifyIcon fontSize="small"/>
                </ToolbarButton>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <ToolbarButton
                    onClick={() => {
                        setInsertImageDialogOpen(true)
                    }}
                    active={false}
                >
                    <ImageIcon fontSize="small"/>
                </ToolbarButton>
                {insertImageDialog}
                {" "}
            </>
        </Stack>
    );
}
