import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {DialogContent, Link} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import {InternalAffairsStatementResponse} from "../../data/internal-affairs/InternalAffairsStatementResponse";
import {Link as RouterLink} from "react-router-dom";
import {EditorContent} from "../../components/editor/EditorContent";

interface InternalAffairsStatementViewFormProps {
    statement: InternalAffairsStatementResponse
}

export default function InternalAffairsStatementViewForm(props: InternalAffairsStatementViewFormProps) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button onClick={() => {
                setOpen(true)
            }}>View</Button>
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText>
                        Provider
                    </DialogContentText>
                    <DialogContentText>
                        <Link underline="none"
                              component={RouterLink}
                              to={`/user/${props.statement.providerCommunityId}`}
                        > {props.statement.providerRpName} ({props.statement.providerNick}) </Link>
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        Status
                    </DialogContentText>
                    <DialogContentText>
                        {props.statement.status}
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                   <EditorContent content={props.statement.request} />
                </DialogContent>
                <DialogContent>
                    {props.statement.questions && props.statement.questions.map((q) => {
                        return <>
                            <EditorContent content={q.question}/>
                            <EditorContent content={q.response ?? ""}/>
                        </>
                    })}
                </DialogContent>
                <DialogContent>
                    <Button color={"info"} onClick={() => handleClose()}>Close</Button>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
