import * as React from 'react';
import {Timeline, timelineItemClasses} from '@mui/lab';
import {Paper} from "@mui/material";
import {RecentActivityResponse} from "../../data/RecentActivityResponse";
import useApiCall from "../../hooks/CancellableApiCall";
import PoliceEvent from "./PoliceEvent";
import {RankResponse} from "../../data/RankResponse";
import useUser from "../../hooks/useUser";

export default function RecentActivity() {
    const recentActivity = useApiCall<RecentActivityResponse[]>({
        initialUrl: `/api/recent-activity/recent`,
        initialData: []
    })
    const policeRanks = useApiCall<RankResponse[]>({
        url: `/api/rank/list/`,
    })
    const user = useUser()

    return (
        <Paper variant="outlined" style={{padding: 16}}>
            <Timeline sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}>
                {recentActivity.data!.map((event) => (
                    <PoliceEvent recentActivityApiCall={recentActivity} user={user} key={event.id} event={event} policeRanks={policeRanks?.data ?? undefined}/>
                ))}
            </Timeline>
        </Paper>
    );
}