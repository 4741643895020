import {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {formatDate} from "../../utils";
import {DivisionApplicationSummary} from "../../data/DivisionApplicationSummary";
import {PoliceUser} from "../PoliceUser";

interface ApplicationsProps {
    divisionIdentifier: string
}

interface ApplicationsRowProps {
    application: DivisionApplicationSummary
    divisionIdentifier: string
}

const ApplicationsRow = (props: ApplicationsRowProps) => {
    const [cooldown, setCooldown] = useState(props.application.isCooldownVoided)

    return <TableRow>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/careers/mark/${props.application.id}/${props.application.communityId}`}>
                {props.application.id}
            </Link>
        </TableCell>
        <TableCell>
            <PoliceUser user={{nick: props.application.nick, communityId: props.application.communityId}} rank={props.application.policeRank}/>
        </TableCell>
        <TableCell>{props.application.roleDisplayName}</TableCell>
        <TableCell>{formatDate(props.application.dateFailed)}</TableCell>
        <TableCell>
            <FormControlLabel label={"Voided"} control={
                <Checkbox onChange={(_) => {
                    makeApiCall({
                        url: `/api/division/${props.divisionIdentifier}/applications/cooldown`,
                        method: "PATCH",
                        body: {
                            applicationId: props.application.userRoleApplicationId,
                            cooldown: !cooldown
                        },
                        onLoadedCallback: () => {
                            setCooldown(!cooldown)
                        },
                        onError: () => {}
                    })
                }}
                          checked={cooldown}/>
            }/>
        </TableCell>
    </TableRow>
}

export const Applications = (props: ApplicationsProps) => {
    const [applications, setApplications] = useState<DivisionApplicationSummary[]>([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/division/${props.divisionIdentifier}/applications/${filter}`)
                const data = await response.json()
                setApplications(data)
            } catch (error) {
                console.error('Error fetching Apps:', error)
            }
        };
        fetchData()
    }, [filter, props.divisionIdentifier])

    return <Stack spacing={4}>
            <Box sx={{overflowX: "auto"}}>
                <TextField fullWidth variant="standard" label="Search..." value={filter}
                           onChange={(event) => {
                               setFilter(event.target.value)
                           }}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Application ID</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Role Applied For</TableCell>
                            <TableCell>Date Failed</TableCell>
                            <TableCell>Cooldown</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingContent showLoadingSkeleton={true} isLoading={applications.length === 0}>
                            {applications && applications.map((application) => {
                                return <ApplicationsRow key={application.id} divisionIdentifier={props.divisionIdentifier} application={application}/>
                            })}
                        </LoadingContent>
                    </TableBody>
                </Table>
            </Box>
        </Stack>
}