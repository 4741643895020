import {Stack, Typography, useTheme} from "@mui/material";
import {AwardedAwardResponse} from "../data/AwardResponse";
import {formatDate} from "../utils";

interface AwardProps {
    award: AwardedAwardResponse
}

export const Award = (props: AwardProps) =>
    <Stack alignItems="center">
        <img src={`/api/awards/${props.award.awardId}/image`} alt={props.award.name} style={{maxWidth: 128, maxHeight: 128}}/>
        <Typography sx={{p:1}} variant="body2">{props.award.name}</Typography>
    </Stack>

export const MiniAward = (props: AwardProps) =>
    <img src={`/api/awards/${props.award.awardId}/image`} alt={props.award.name} style={{objectFit: "contain", maxWidth: 16, maxHeight: 16, marginBottom: 0, marginTop: 0}}/>

export const ProfileAward = (props: AwardProps) => {
    const theme = useTheme()
    let messageQuote = (props.award.message === "") ? <></> : <>"{props.award.message}"<br/></>;

    return <Stack alignItems="center">
        <img src={`/api/awards/${props.award.awardId}/image`} alt={props.award.name}
             style={{maxWidth: 128, maxHeight: 128, marginBottom:4}}/>
        <Typography variant="h6"><strong>{props.award.name}</strong></Typography>
        <Typography variant="body1" sx={{textAlign:"center"}} color={theme.palette.text.secondary}>{messageQuote}</Typography>
        <Typography sx={{pt:2}} variant="body2">- {props.award.issuerRank} {props.award.issuerName}, {formatDate(props.award.dateAwarded)}</Typography>
    </Stack>
}