import {AppBar, Box, ClickAwayListener, IconButton, Link, Toolbar, Tooltip, useTheme} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, {useState} from "react";
import Badge from "@mui/material/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import {AccountCircle} from "@mui/icons-material";
import useUser from "../hooks/useUser";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {generateProfilePath} from "../utils";
import {useNavBarState} from "../hooks/useNavBarState";
import {NotificationMenu} from "./NotificationMenu";
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {usePaletteModeSwitcher} from "../hooks/usePaletteModeSwitcher";
import {InboxMenu} from "./InboxMenu";

import navbarLogo from '../assets/navbar-logo.png';
import plpdLogo from '../assets/plpd.png';

import './component-edits.css';



interface NavBarProps {
    drawerWidth: number
    setSidebarOpen: (open: boolean) => void
    sidebarOpen: boolean
}

export const NavBar = (props: NavBarProps) => {
    const userData = useUser()
    const navBarStateData = useNavBarState()
    const navigate = useNavigate()
    const theme = useTheme()
    const [notificationAnchor, setNotificationAnchor] = useState<HTMLElement | null>(null)
    const [inboxAnchor, setInboxAnchor] = useState<HTMLElement | null>(null)
    const [showNotifications, setShowNotifications] = useState(false)
    const [showInbox, setShowInbox] = useState(false)
    const setPaletteMode = usePaletteModeSwitcher()

    let lightMode: any;
    lightMode = undefined;

    lightMode = theme.palette.mode === "light";

    return (
        <AppBar position="fixed"
                className={"custom-navbar mode-" + (lightMode ? 'light' : 'dark')}
                sx={{
                    width: {md: `calc(100% - ${props.drawerWidth}px)`},
                    ml: {md: `${props.drawerWidth}px`},
                    // color: (theme) => theme.palette.text.primary,
                    background: (theme) => theme.palette.background.default,
                    boxShadow: "none"
                }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
                    sx={{mr: 2, display: {md: 'none'}}}
                >
                    <MenuIcon/>
                </IconButton>

                <Link component={RouterLink} underline="none" to={"/"}>
                    <Box
                        component="img"
                        sx={{
                            height: 50,
                            cursor:"pointer",
                            display: "block",
                            padding: {xs: "8px", sm: 0},
                            content: {
                                xs: `url(${plpdLogo})`, //img src from xs up to md
                                sm: `url(${navbarLogo})`,  //img src from md and up
                            }
                        }}
                        alt="logo" />
                </Link>
                {/*<Link component={RouterLink} color="inherit" underline="none" to={"/"} variant={"h5"}*/}
                {/*      sx={{display: {xs: 'none', md: 'block'}}}>*/}
                {/*    PLPD Online*/}
                {/*</Link>*/}

                <Box sx={{flexGrow: 1}}/>
                <Box className={"nav-button-row"} sx={{display: "flex"}}>
                    <ClickAwayListener mouseEvent="onClick" touchEvent="onTouchStart" onClickAway={(e) => {
                        if (("button" in e && e.button === 0) || "touches" in e) {
                            setShowInbox(false)
                        }
                    }}>
                        <div>
                            <Tooltip title="Conversations" enterDelay={700} enterNextDelay={700}>
                                <IconButton size="large" color="inherit" onClick={(event) => {
                                    setInboxAnchor(event.currentTarget)
                                    setShowInbox(!showInbox)
                                }}>
                                    <Badge badgeContent={navBarStateData.data?.unreadConversationCount ?? 0}
                                           color="error">
                                        <MailIcon/>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <InboxMenu open={showInbox} onClose={() => setShowInbox(false)}
                                       anchorEl={inboxAnchor}/>
                        </div>
                    </ClickAwayListener>
                    <ClickAwayListener mouseEvent="onClick" touchEvent="onTouchStart" onClickAway={(e) => {
                        if (("button" in e && e.button === 0) || "touches" in e) {
                            setShowNotifications(false)
                        }
                    }}>
                        <div>
                            <Tooltip title="Notifications" enterDelay={700} enterNextDelay={700}>
                                <IconButton
                                    size="large"
                                    aria-label="show new notifications"
                                    color="inherit"
                                    onClick={(event) => {
                                        setNotificationAnchor(event.currentTarget)
                                        setShowNotifications(!showNotifications)
                                    }}
                                >
                                    <Badge badgeContent={navBarStateData.data?.unreadNotificationCount ?? 0}
                                           color="error">
                                        <NotificationsIcon/>
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <NotificationMenu open={showNotifications} onClose={() => setShowNotifications(false)}
                                              anchorEl={notificationAnchor}/>
                        </div>
                    </ClickAwayListener>
                    <Tooltip title="My Profile">
                        <IconButton
                            size="large"
                            onClick={() => {
                                const communityId = userData.user?.communityId
                                if (!communityId) return
                                navigate(generateProfilePath(communityId))
                            }}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Switch Theme">
                        <IconButton
                            size="large"
                            onClick={() => {
                                setPaletteMode((theme.palette.mode === "light") ? "dark" : "light")
                            }}
                            color="inherit"
                        >
                            {(theme.palette.mode === "light") ? <DarkModeIcon/> : <LightModeIcon/>}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <IconButton
                            size="large"
                            edge="end"
                            onClick={() => {
                                window.location.replace("/api/auth/logout")
                            }}
                            color="inherit"
                        >
                            <LogoutIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </AppBar>
    )
}