import React from "react";
import {
    Box,
    Button,
    Chip,
    Link,
    MenuItem,
    Paper,
    Select, Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {LimitedResourceResponse} from "../../data/LimitedResourceResponse";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import useUser, {UserContextProps} from "../../hooks/useUser";
import DeleteResourcePrompt from "./DeleteResourcePrompt";
import EditResourceForm from "./EditResourceForm";
import {formatTimestamp} from "../../utils";

interface DivisionResourcePageContentProps {
    resources: LimitedResourceResponse[]
    user: UserContextProps
    divisionIdentifier: string
}

function DivisionResourcePageContent(props: DivisionResourcePageContentProps) {
    const navigate = useNavigate()
    let newResourceButton = <></>
    if (props.user.hasPermissions(`${props.divisionIdentifier}-resource:create`)) {
        newResourceButton = <Button component={RouterLink}
                                    to={`/resources/new/${props.divisionIdentifier?.replaceAll("_", "-")}`}
                                    color="success">New Resource</Button>
    }
    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Access Type</TableCell>
                        <TableCell>Last Edit Date</TableCell>
                        <TableCell>Last Edited By</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.resources?.map(resource => {
                                let accessType
                                if (!props.user.hasPermissions(`${props.divisionIdentifier}-resource:modify`)) {
                                    if (!resource.privateResource) {
                                        accessType = <Chip color={"primary"} style={{backgroundColor: "green", height: "20px"}}
                                                           label={"Public"}/>
                                    } else {
                                        accessType = <Chip color={"primary"} style={{backgroundColor: "red", height: "20px"}}
                                                           label={"Private"}/>
                                    }
                                } else {
                                    accessType = <Select
                                        id={`select-privacy-${resource.id}`}
                                        value={Number(resource.privateResource)}
                                        onChange={(event) => {
                                            makeApiCall({
                                                url: `/api/resource/${props.divisionIdentifier}/${resource.id}/privacy`,
                                                method: "PATCH",
                                                body: {
                                                    newPrivacy: Boolean(event.target.value)
                                                },
                                                onLoadedCallback: () => {
                                                    navigate(`/resources/`)
                                                },
                                                onError: () => {

                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem key={1} value={1}>Private</MenuItem>
                                        <MenuItem key={0} value={0}>Public</MenuItem>
                                    </Select>
                                }
                                let resourceModification = <></>
                                if (props.user.hasPermissions(`${props.divisionIdentifier}-resource:modify`)) {
                                    resourceModification = <>
                                        <EditResourceForm content={resource.content}
                                                          divisionIdentifier={props.divisionIdentifier}
                                                          resourceIdentifier={resource.identifier}
                                                          id={resource.id}
                                        />
                                    </>
                                }
                            let resourceDelete = <></>
                            if (props.user.hasPermissions(`${props.divisionIdentifier}-resource:delete`)) {
                                resourceDelete = <>
                                    <DeleteResourcePrompt divisionIdentifier={props.divisionIdentifier}
                                                          id={resource.id}
                                    />
                                </>
                            }
                                let viewButton
                                if (resource.type === "content") {
                                    viewButton = <Button component={RouterLink}
                                                         to={`/resource/${props.divisionIdentifier?.replaceAll("_","-")}/${resource.identifier}`}
                                                         color="success">View Resource</Button>
                                } else {
                                    viewButton = <Stack spacing={1} direction="row" alignItems="center">
                                        <Button href={resource.content}>Goto Resource</Button>
                                        <Chip color={"primary"} style={{backgroundColor: "red", height: "20px"}}
                                              label={"External Page"}/>
                                    </Stack>
                                }
                                return <TableRow>
                                    <TableCell> {resource.title} </TableCell>
                                    <TableCell> {accessType} </TableCell>
                                    <TableCell> {formatTimestamp(resource.date)} </TableCell>
                                    <TableCell> <Link underline="none"
                                                      component={RouterLink}
                                                      to={`/user/${resource.editorCommunityId}`}
                                    > {resource.editorNick} </Link>
                                    </TableCell>
                                    <TableCell> {resourceModification} {resourceDelete} </TableCell>
                                    <TableCell> {viewButton} </TableCell>
                                </TableRow>
                            }
                        )
                    }
                </TableBody>
            </Table>
        </Box>
        {newResourceButton}
        <Button component={RouterLink}
                to={`/resources`}
                color="info">Return</Button>
    </Paper>
}

export const DivisionResourcePage = () => {
    const params = useParams()
    const user = useUser()

    const resources = useApiCall<LimitedResourceResponse[]>({
        url: `/api/resource/division/${params.divisionIdentifier?.replaceAll("-", "_")}`
    })

    return <LoadingContent
        isLoading={(resources.isLoading || !resources)}>
        <DivisionResourcePageContent divisionIdentifier={params.divisionIdentifier?.replaceAll("-", "_")!!} user={user}
                                     resources={resources.data!!}/>
    </LoadingContent>
}