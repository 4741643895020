import React from "react";
import {Box, Button, Grid, Link, Paper, Typography, useTheme} from "@mui/material";
import {AutoStories} from "@mui/icons-material/";

import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Division} from "../../data/Division";
import {styled} from '@mui/material/styles';
import {Link as RouterLink} from "react-router-dom";
import useUser from "../../hooks/useUser";

interface ResourcePageContentProps {
    divisions: Division[]
}

function ResourcePageContent(props: ResourcePageContentProps) {
    const theme = useTheme()
    const user = useUser()

    const TextBracket = styled('div')`
        margin-left:5px;
        padding-top:12px;
        display: flex;
        height:100%;
        width:100%;
        justify-content:center;
        flex-direction:column;
        align-content:center;
    `

    return <Grid
        container spacing={2}
    >
        <Grid item xs={12}>
            <Link sx={{textDecoration:"none"}}>
                <Paper sx={{p:1, height:"230px"}} variant={"outlined"}>
                    <Box sx={{textAlign:"center", width:"100%"}}>
                        <AutoStories sx={{
                            fontSize:"80px",
                        }} />
                        <Typography variant={"h6"} sx={{p:1, fontSize:"40px"}}>Officer Handbook</Typography>
                        <Button href={"https://sites.google.com/view/officers-handbook/home"}>Open Handbook</Button> <Button href={"https://sites.google.com/view/officers-handbook/9-general-policies?authuser=0"}>General Policies</Button>
                    </Box>
                </Paper>
            </Link>
        </Grid>

        {props.divisions && props.divisions.map(division => {
                return <Grid key={division.identifier} item xs={6}>
                    <Paper sx={{p:2, overflowX: "auto", wordWrap:"break-word", height: "100%"}} variant={"outlined"}>
                        <Box sx={{
                            height: "auto",
                            display: "flex",
                            width:"100%"
                        }}>
                            {<Box
                                component={"img"}
                                src={division.badge}
                                sx={{
                                    width:"200px",
                                    height:"200px",
                                    padding:"15px",
                                    display: {xs: "none", sm: "none", md: "block"}
                                }}
                            />}
                            <TextBracket>
                                <Typography variant={"h6"} gutterBottom>
                                    {division.displayName}
                                </Typography>
                                <Typography sx={{pb:1}} color={theme.palette.text.secondary} variant={"body2"}>
                                    {division.description}
                                </Typography>
                                {user.hasPermissions(`${division.identifier}-management:panel`) ? <Button
                                    component={RouterLink} to={`/division-management/${division.identifier?.replaceAll("_", "-")}`}
                                    size={"small"} sx={{width:150, mt:2}} variant="outlined">
                                        Manage Division</Button> : <></>}
                                <Button component={RouterLink} to={`/resources/${division.identifier?.replaceAll("_", "-")}`} size={"small"} sx={{width:150, mt:2}} variant="outlined">See Resources</Button>
                            </TextBracket>
                        </Box>
                    </Paper>
                </Grid>

            }
        )}
    </Grid>
}

export const ResourcePage = () => {
    const divisions = useApiCall<Division[]>({
        url: `/api/division/list-divisions`
    })

    return <LoadingContent
        isLoading={(divisions.isLoading || !divisions)}>
        <ResourcePageContent divisions={divisions.data!!}/>
    </LoadingContent>
}