import {
    Box,
    Divider,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {LoadingContent} from "../LoadingContent";
import React, {useState} from "react";
import {AccountResponse} from "../../data/AccountResponse";
import useApiCall from "../../hooks/CancellableApiCall";
import {InactivityNoticeResponse} from "../../data/InactivityNoticeResponse";
import {formatTimestamp} from "../../utils";
import {PoliceUser} from "../PoliceUser";
import CreateInactivityNoticeForm from "./CreateInactivityNoticeForm";
import EditActivityNoticeForm from "./EditActivityNoticeForm";
import DeleteActivityNoticePrompt from "./DeleteActivityNoticePrompt";
import {OfficerDivisionPlaytimeResponse} from "../../data/OfficerPlayTimeSummaryResponse";
import {TabContext, TabList, TabPanel} from "@mui/lab";

interface EmployeeActivityProps {
    communityId: string,
    self: AccountResponse,
    isOwn: boolean
}

interface EmployeeActivityContentProps extends EmployeeActivityProps {
    notices: InactivityNoticeResponse[],
    activity: OfficerDivisionPlaytimeResponse[]
}

function ActivityRow(props: { notice: InactivityNoticeResponse, self: AccountResponse, isOwn: boolean }) {
    return <TableRow>
        <TableCell> {props.notice.note} </TableCell>
        <TableCell> {formatTimestamp(props.notice.startDate)} </TableCell>
        <TableCell> {formatTimestamp(props.notice.endDate)} </TableCell>
        <TableCell> {props.notice.issuerNick && props.notice.issuerCommunityId ?
            <PoliceUser user={{
                nick: props.notice.issuerNick,
                communityId: props.notice.issuerCommunityId
            }}/> : <></>} </TableCell>
        <TableCell>
            {props.self.permissions.includes("inactivityNotice:edit") || props.isOwn ?
                <EditActivityNoticeForm id={props.notice.id} content={props.notice.note}
                                        communityId={props.notice.employeeCommunityId}/> : <></>
            }
            {props.self.permissions.includes("inactivityNotice:delete") ?
                <DeleteActivityNoticePrompt id={props.notice.id}
                                            communityId={props.notice.employeeCommunityId}/> : <></>
            }
        </TableCell>
    </TableRow>
}


interface EmployeeActivityTableProps {
    divisionPlaytime: OfficerDivisionPlaytimeResponse
}

function EmployeeActivityTable(props: EmployeeActivityTableProps) {
    return <TableContainer component={Paper}>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Duration</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.divisionPlaytime.playtimeEntries.map((entry) => {
                    const date = new Date(entry.date).toLocaleDateString("en-GB", {
                        year: 'numeric',
                        month: 'long',
                        timeZone: 'UTC'
                    })
                    let minutes = Math.floor( (entry.playtime / 60) % 60).toString()
                    if (minutes.length <= 1) {
                        minutes = "0" + minutes
                    }
                    const hours = Math.floor(entry.playtime / 3600)
                    return <TableRow key={entry.date}>
                        <TableCell>{date}</TableCell>
                        <TableCell>{`${hours}h ${minutes}m`}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

function EmployeeActivityContent(props: EmployeeActivityContentProps) {
    const [selectedTab, setSelectedTab] = useState(props.activity[0]?.divisionIdentifier)

    return <Stack spacing={2}>
        <Typography variant={"h6"}>Inactivity Notices <CreateInactivityNoticeForm communityId={props.communityId}/></Typography>
        <Box sx={{overflowX:"auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Note</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Issuer</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.notices?.map(notice => {
                            return <ActivityRow notice={notice} self={props.self} key={notice.id} isOwn={props.isOwn}/>
                        }
                    )
                }
            </TableBody>
        </Table>
        </Box>
        <Divider/>
        <Typography variant={"h6"}>Playtime By Division</Typography>
        <TabContext value={selectedTab}>
            <>
                <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
                    {props.activity && props.activity.map(divisionPlaytime => {
                        return <Tab key={divisionPlaytime.divisionIdentifier} value={divisionPlaytime.divisionIdentifier} label={divisionPlaytime.divisionName}/>
                    })}
                </TabList>
                {props.activity && props.activity.map(divisionPlaytime => {
                    return <TabPanel key={divisionPlaytime.divisionIdentifier} value={divisionPlaytime.divisionIdentifier}>
                        <EmployeeActivityTable divisionPlaytime={divisionPlaytime}></EmployeeActivityTable>
                    </TabPanel>
                })}
            </>
        </TabContext>


    </Stack>

}

export const EmployeeActivity = (props: EmployeeActivityProps) => {
    const notices = useApiCall<InactivityNoticeResponse[]>({
        url: `/api/activity/${props.communityId}`,
    })

    const activity = useApiCall<OfficerDivisionPlaytimeResponse[]>({
        url: `/api/activity/${props.communityId}/monthly-activity`,
    })

    return <LoadingContent isLoading={
        notices.isLoading || !notices ||
        activity.isLoading || !activity
    }>
        <EmployeeActivityContent notices={notices.data!!} communityId={props.communityId} self={props.self}
                                 isOwn={props.isOwn} activity={activity.data!!}/>
    </LoadingContent>
}