import {
    Button,
    darken,
    IconButton,
    lighten,
    Link,
    Paper,
    Stack,
    Typography,
    useTheme,
    InputAdornment,
    OutlinedInput, Chip, Box
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {ShoutboxMessageResponse} from "../data/ShoutboxMessageResponse";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {BlockUi} from "./BlockUi";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {browserHasWebSocketSupport, createWebSocket, formatTimestamp} from "../utils";
import {AccountResponse, userHasPermissions} from "../data/AccountResponse";
import {PoliceAvatar} from "./PoliceAvatar";
import {RankResponse} from "../data/RankResponse";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import {LinkIt, urlRegex} from "react-linkify-it";
import {PoliceUser} from "./PoliceUser";
import {MiniProfileTarget} from "./MiniProfileTarget";
import useUser from "../hooks/useUser";
import SendIcon from '@mui/icons-material/Send';
import {Link as RouterLink} from "react-router-dom";

interface ShoutboxInputProps {
    onMessageSent: () => void
}

const ShoutboxInput = (props: ShoutboxInputProps) => {
    let [canSend, setCanSend] = useState(true)
    let [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const user = useUser()
    const hasSendPermission = user.hasPermissions("shoutbox:send") && !user.user?.suspended && !user.user?.blacklisted

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall])

    let [message, setMessage] = useState("")

    const sendMessage = () => {
        if (message.length === 0) return;
        apiCall?.cancel()
        setCanSend(false)
        setApiCall(makeApiCall({
            url: "/api/shoutbox/messages",
            method: "post",
            body: {message: message},
            onLoadedCallback: () => {
                setCanSend(true)
                props.onMessageSent()
            },
            onError: () => {
                setCanSend(true)
            }
        }))
        setMessage("")
    }


    return (
        <div style={{width: "100%", display: "flex"}}>
            <OutlinedInput placeholder="Your message goes here.." onChange={(e) => setMessage(e.target.value)}
                   style={{width: "auto", flexGrow: 1, marginRight: 8, marginBottom: 2}} value={message}
                   disabled={!hasSendPermission}
                   onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    sendMessage()
                }
            }} endAdornment={
                <InputAdornment position="end">
                    <Button size="medium" disabled={!canSend || !hasSendPermission} onClick={sendMessage} endIcon={<SendIcon/>}>SEND</Button>
                </InputAdornment>
            }></OutlinedInput>
        </div>
    )
}

interface ShoutboxMessageProps {
    message: ShoutboxMessageResponse
    user: AccountResponse
    ranks: RankResponse[] | null
    rankId: number
    adminRank: string
    pinned: boolean
    gracePeriod: number
}

interface ShoutboxMessageActionProps {
    canPin: boolean
    canDelete: boolean
    messagePinned: boolean
    deleteFunction: () => void
    pinFunction: () => void
    unpinFunction: () => void
}

const ShoutboxMessageActions = (props: ShoutboxMessageActionProps) => {
    let deleteButton = <></>
    let pinButton = <></>
    let unpinButton = <></>

    if (props.canDelete) {
        deleteButton = <IconButton size="small" onClick={() => props.deleteFunction()}>
            <HighlightOffIcon fontSize="inherit" color="error"/>
        </IconButton>
    }

    if (props.canPin && !props.messagePinned) {
        pinButton = <IconButton size="small" onClick={() => props.pinFunction()}>
            <CampaignOutlinedIcon fontSize="inherit" color="info"/>
        </IconButton>
    }

    if (props.canPin && props.messagePinned) {
        unpinButton = <IconButton size="small" onClick={() => props.unpinFunction()}>
            <VolumeDownOutlinedIcon fontSize="inherit" color="info"/>
        </IconButton>
    }

    return <div>
        {deleteButton}
        {pinButton}
        {unpinButton}
    </div>
}

const ShoutboxMessage = (props: ShoutboxMessageProps) => {
    const message = props.message
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const canDeleteDefault = userHasPermissions(props.user, "shoutbox:delete") || props.user.communityId === message.communityId
    const [canDelete, setCanDelete] = useState(canDeleteDefault)
    const [hovered, setHovered] = useState(false)
    const theme = useTheme()
    let isOwnMessage = props.user?.communityId === message.communityId
    let rankData = props.ranks?.find(rank => rank["rankId"] === props.rankId)
    let announcementText = <></>
    const canPin = userHasPermissions(props.user, "shoutbox:pin")

    if (props.pinned) {
        announcementText = <Chip size="small" label={"Announcement"} color={"primary"}
                                  sx={{
                                      backgroundColor: theme.palette.warning.main,
                                      padding:"1.7px",
                                      display: {xs:"none", md:"block"}
        }}
        />
    }

    useEffect(() => {
        apiCall?.cancel()
    }, [apiCall])

    const deleteMessage = () => {
        setCanDelete(false)
        let route = "/api/shoutbox/messages/own/"
        if (!isOwnMessage) {
            route = "/api/shoutbox/messages/"
        }
        setApiCall(makeApiCall({
            url: route + props.message.id,
            method: "DELETE",
            onLoadedCallback: () => {
                setCanDelete(canDeleteDefault)
            },
            onError: () => {
                setCanDelete(canDeleteDefault)
            }
        }))
    }

    const pinMessage = () => {
        let route = "/api/shoutbox/messages/pin/"
        setApiCall(makeApiCall({
            url: route + props.message.id,
            method: "PUT",
            onLoadedCallback: () => {

            },
            onError: () => {

            }
        }))
    }

    const unpinMessage = () => {
        let route = "/api/shoutbox/messages/unpin/"
        setApiCall(makeApiCall({
            url: route + props.message.id,
            method: "PUT",
            onLoadedCallback: () => {

            },
            onError: () => {

            }
        }))
    }

    let actions = <></>
    let pinnedIcon = <></>
    let style: React.CSSProperties = {}
    if (props.pinned) {
        pinnedIcon = <PushPinIcon fontSize="small"/>
        if (theme.palette.mode === "light") {
            style.background = darken(theme.palette.background.default, 0.1)
        } else {
            style.background = lighten(theme.palette.background.default, 0.1)
        }
    }

    if (hovered && (canPin || canDelete)) {
        actions = <ShoutboxMessageActions
            canDelete={canDelete}
            messagePinned={props.pinned}
            canPin={canPin}
            deleteFunction={deleteMessage}
            pinFunction={pinMessage}
            unpinFunction={unpinMessage}/>
    }

    let rankColor
    let rankChip
    if (props.adminRank === "development_services_senior_developer" ||
        props.adminRank === "development_services_head" ||
        rankData?.identifier === "chief_of_department" ||
        rankData?.identifier === "deputy_chief_of_department") {
        rankColor = "ff0000"
        rankChip = <Chip size="small" label={"Site Administrator"} color={"primary"}
                         sx={{
                             backgroundColor: theme.palette.error.main,
                             padding:"1.7px",
                             display: {xs:"none", md:"block"}}}/>
    } else if (props.adminRank === "site_moderator") {
        rankColor = "ff1493"
        rankChip = <Chip size="small" label={"Site Moderator"}
                         sx={{
                             backgroundColor: "deeppink",
                             color: "white",
                             padding:"1.7px",
                             display: {xs:"none", md:"block"}}}/>
    } else if (props.adminRank === "community_management") {
        rankColor = "008080"
        rankChip = <Chip size="small" label={"Community Management"}
                         sx={{
                             backgroundColor: "teal",
                             color: "white",
                             padding:"1.7px",
                             display: {xs:"none", md:"block"}}}/>
    } else {
        rankColor = rankData?.color ?? "F5F7F7"
    }

    return (
        <Paper elevation={0} sx={{padding: "8px", "&:hover": {background: theme.palette.action.hover}}}
               onMouseEnter={() => setHovered(true)}
               onMouseLeave={() => setHovered(false)}
               style={style}
        >
            <Stack sx={{overflowX:"auto"}} spacing={1} direction="row" alignItems="flex-start">
                <Box sx={{display:{xs:"none", sm:"block"}}}>
                    <MiniProfileTarget communityId={message.communityId}>
                        <PoliceAvatar online={false} avatarUrl={`/api/user/${message.communityId}/avatar`}
                                      color={rankColor}
                                      userCommunityId={message.communityId}></PoliceAvatar>
                    </MiniProfileTarget>
                </Box>
                <Box sx={{display:{xs:"block", sm:"none"}}}>
                    <MiniProfileTarget communityId={message.communityId}>
                        <PoliceAvatar online={false} avatarUrl={`/api/user/${message.communityId}/avatar`}
                                      color={rankColor}
                                      userCommunityId={message.communityId} dimensions={45}></PoliceAvatar>
                    </MiniProfileTarget>
                </Box>
                <div style={{width: "100%"}}>
                    <Stack direction="row" justifyContent="space-between" flexWrap="wrap" sx={{minHeight: "32px", overflowX:"auto"}}>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{display:{xs:"none", sm:"block"}}}>
                            <PoliceUser user={message} rank={rankData}/>
                            </Box>
                            <Box sx={{ml:"0!important", display:{xs:"block", sm:"none"}}}>
                                <Link underline="none" component={RouterLink} to={`/user/${message.communityId}`}>
                                    {message.nick}
                                </Link>
                            </Box>
                            {rankChip}
                            {announcementText}
                            <Typography variant="caption" sx={{
                                display: {xs: "none", md:"block"}
                            }} color={theme.palette.text.secondary}>{formatTimestamp(message.date)}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            {actions}
                            {pinnedIcon}
                        </Stack>
                    </Stack>
                    <Typography variant="body1" sx={{wordBreak: "break-word"}}>
                        <LinkIt regex={urlRegex} component={(match: string, key: number) => <Link key={key} href={match}
                                                                                                  underline="hover"
                                                                                                  target="_blank">{match}</Link>}>{message.message}</LinkIt>
                        <Typography variant="caption" sx={{
                            display: {xs: "block", md:"none"}
                        }} color={theme.palette.text.secondary}>{formatTimestamp(message.date)}</Typography>
                    </Typography>
                </div>
            </Stack>
        </Paper>
    )
}

interface ShoutboxWebsocketMessage {
    type: string,
    messageId: number
}

class ShoutboxStateHandler {
    private apiCall: ApiCallResponseData | null = null
    private existingIds: Set<number> = new Set()
    private shoutboxMessages: ShoutboxMessageResponse[] = []
    private pinnedMessages: ShoutboxMessageResponse[] = []
    private updateRunning: boolean = false
    private fullUpdateRunning: boolean = false
    private updateQueued: boolean = false
    private fullUpdateQueued: boolean = false
    private webSocket: WebSocket | null = null
    private eventListener: (() => void) | null = null
    private running: boolean = false
    private webSocketRestartTimer: NodeJS.Timer | null = null
    private fallbackFetchTimer: NodeJS.Timer | null = null

    private pinnedMessageUpdateQueued: boolean = false
    private pinnedMessageUpdateRunning: boolean = false
    private pinnedMessagesApiCall: ApiCallResponseData | null = null

    onMessagesUpdated?: (messages: ShoutboxMessageResponse[]) => void
    onUpdateRunningChanged?: (updateRunning: boolean) => void
    onPinnedMessagesUpdated?: (pinnedMessages: ShoutboxMessageResponse[]) => void


    private onPinnedMessagesUpdateCompleted(data?: ShoutboxMessageResponse[]) {
        if (data) {
            this.pinnedMessages = data
        }

        if (this.pinnedMessageUpdateQueued) {
            this.fetchPinnedMessages()
        } else {
            this.pinnedMessageUpdateRunning = false
            this.pinnedMessagesApiCall = null
        }

        if (data && this.onPinnedMessagesUpdated) {
            this.onPinnedMessagesUpdated(this.pinnedMessages)
        }
    }

    private onUpdateCompleted(data?: ShoutboxMessageResponse[]) {
        if (data) {
            if (this.fullUpdateRunning) {
                this.existingIds = new Set()
                this.shoutboxMessages = []
            }
            data.forEach((message) => {
                if (!this.existingIds.has(message.id)) {
                    this.existingIds.add(message.id)
                    this.shoutboxMessages.push(message)
                }
            })
            this.shoutboxMessages.sort((a, b) => {
                return b.id - a.id
            })
        }

        if (this.updateQueued) {
            if (this.fullUpdateQueued) {
                this.fetchMessages(null)
            } else {
                this.fetchLatestMessages()
            }
        } else {
            this.updateRunning = false
            this.fullUpdateRunning = false
            this.apiCall = null
        }

        if (data && this.onMessagesUpdated) {
            this.onMessagesUpdated([...this.shoutboxMessages])
        }
    }

    private fetchLatestMessages() {
        let newAfterId: number | null = null
        if (this.shoutboxMessages.length > 0) {
            newAfterId = this.shoutboxMessages[0].id
        }
        this.fetchMessages(newAfterId)
    }

    private queuePinnedMessageUpdate() {
        if (!this.pinnedMessageUpdateRunning) {
            this.fetchPinnedMessages()
        } else {
            this.pinnedMessageUpdateQueued = true
        }
    }

    queueUpdate(fullUpdate: boolean) {
        this.fullUpdateQueued = fullUpdate
        this.updateQueued = true
        if (!this.updateRunning) {
            if (fullUpdate) {
                this.fetchMessages(null)
            } else {
                this.fetchLatestMessages()
            }
        }
    }

    private fetchPinnedMessages() {
        this.pinnedMessagesApiCall?.cancel()
        this.pinnedMessageUpdateRunning = true
        this.pinnedMessageUpdateQueued = false


        this.pinnedMessagesApiCall = makeApiCall({
            url: "/api/shoutbox/messages/pinned",
            onLoadedCallback: (data: ShoutboxMessageResponse[]) => {
                this.onPinnedMessagesUpdateCompleted(data)
            },
            onError: () => {
                this.onPinnedMessagesUpdateCompleted()
            }
        })
    }

    private fetchMessages(afterId: number | null) {
        this.apiCall?.cancel()
        this.updateRunning = true
        this.updateQueued = false
        this.fullUpdateRunning = afterId == null
        let url = "/api/shoutbox/messages"
        if (afterId) {
            url = "/api/shoutbox/messages/new?after=" + afterId
        }

        this.apiCall = makeApiCall({
            url: url,
            onLoadedCallback: (data: ShoutboxMessageResponse[]) => {
                this.onUpdateCompleted(data)
            },
            onError: () => {
                this.onUpdateCompleted()
            }
        })
    }


    start() {
        let shoutboxThis = this
        this.eventListener = function onHidden() {
            if (document.visibilityState === "visible") {
                shoutboxThis.resume()
            } else {
                shoutboxThis.pause()
            }
        }
        this.resume()
        window.addEventListener("visibilitychange", this.eventListener)
    }

    private closeWebSocket() {
        if (this.webSocketRestartTimer) {
            clearInterval(this.webSocketRestartTimer)
        }
        if (this.webSocket) {
            this.webSocket.onmessage = () => {
            }
            this.webSocket.onclose = () => {
            }
            this.webSocket.close()
        }
        this.webSocket = null
    }

    private restartWebSocket() {
        this.closeWebSocket()
        this.webSocket = createWebSocket("/api/shoutbox/ws")
        if (!this.webSocket) return
        this.webSocket.onmessage = (e: MessageEvent<string>) => {
            const data = JSON.parse(e.data) as ShoutboxWebsocketMessage
            if (data.type === "new_message") {
                this.queueUpdate(false)
            } else if (data.type === "message_deleted") {
                if (this.existingIds.has(data.messageId)) {
                    this.shoutboxMessages = this.shoutboxMessages.filter(value => value.id !== data.messageId)
                    this.existingIds.delete(data.messageId)
                    if (this.onMessagesUpdated) {
                        this.onMessagesUpdated([...this.shoutboxMessages])
                    }
                }
            } else if (data.type === "message_pinned") {
                this.queuePinnedMessageUpdate()
                this.queueUpdate(true)
            } else if (data.type === "message_unpinned") {
                this.queuePinnedMessageUpdate()
                this.queueUpdate(true)
            }
        }
        this.webSocket.onclose = () => {
            if (this.running) {
                if (this.webSocketRestartTimer) {
                    clearTimeout(this.webSocketRestartTimer)
                }
                this.webSocketRestartTimer = setTimeout(() => {
                    if (!this.running) return
                    this.restartWebSocket()
                    this.queueUpdate(false)
                }, 5000)
            }
        }
    }

    private startUpdateTimer() {
        this.fallbackFetchTimer = setInterval(() => {
            this.queueUpdate(false)
        }, 5000)
    }

    private resume() {
        if (this.running) {
            this.pause()
        }
        this.running = true
        this.queueUpdate(true)
        this.queuePinnedMessageUpdate()
        if (browserHasWebSocketSupport()) {
            this.restartWebSocket()
        } else {
            this.startUpdateTimer()
        }
    }

    private pause() {
        this.running = false
        this.apiCall?.cancel()
        this.apiCall = null
        this.updateRunning = false
        this.updateQueued = false
        this.pinnedMessagesApiCall?.cancel()
        this.pinnedMessageUpdateRunning = false
        this.pinnedMessageUpdateQueued = false
        if (browserHasWebSocketSupport()) {
            this.closeWebSocket()
        } else if (this.fallbackFetchTimer) {
            clearInterval(this.fallbackFetchTimer)
        }
    }

    close() {
        this.pause()
        this.shoutboxMessages = []
        this.pinnedMessages = []
        if (this.eventListener) {
            window.removeEventListener("visibilitychange", this.eventListener)
        }
    }
}

interface ShoutboxProps {
    user: AccountResponse
}

export const Shoutbox = (props: ShoutboxProps) => {
    const gracePeriod = 500
    const [updateRunning, setUpdateRunning] = useState(false)
    const [shoutboxMessages, setShoutboxMessages] = useState<ShoutboxMessageResponse[]>([])
    const [pinnedMessages, setPinnedMessages] = useState<ShoutboxMessageResponse[]>([])
    const [shoutboxHandler, setShoutboxHandler] = useState<ShoutboxStateHandler>()

    const pinnedMessageIds = useMemo(() => {
        const pinnedIds = new Set<number>()
        pinnedMessages.forEach((m) => pinnedIds.add(m.id))
        return pinnedIds
    }, [pinnedMessages])

    const loadRankData = useApiCall<RankResponse[]>({
        initialUrl: `/api/rank/list`
    })

    useEffect(() => {
        let newShoutboxHandler = new ShoutboxStateHandler()
        newShoutboxHandler.start()
        setShoutboxHandler(newShoutboxHandler)
        return () => {
            newShoutboxHandler.close()
        }
    }, []);

    useEffect(() => {
        if (!shoutboxHandler) return
        shoutboxHandler.onMessagesUpdated = (data) => {
            setShoutboxMessages(data)
        }
        shoutboxHandler.onUpdateRunningChanged = setUpdateRunning
        shoutboxHandler.onPinnedMessagesUpdated = setPinnedMessages
    }, [shoutboxHandler, setShoutboxMessages, setUpdateRunning, setPinnedMessages]);

    return (
        <BlockUi open={updateRunning}>
            <Typography variant="h6" gutterBottom>Shoutbox</Typography>
            <Paper variant="outlined" sx={{p: 2}}>
                <Stack spacing={1} direction="column" sx={{pl: 2, pr: 2, mt: 1, pb:1}}>
                    <ShoutboxInput onMessageSent={() => {
                        shoutboxHandler?.queueUpdate(false)
                    }}/>
                </Stack>
                <Stack spacing={1} direction="column" sx={{pl: 2, pr: 2, mt: 1, height: 600, overflow: "auto"}}>
                    {
                        pinnedMessages.map((message) => <ShoutboxMessage
                            user={props.user} message={message}
                            key={message.id}
                            pinned={true}
                            ranks={loadRankData.data}
                            rankId={message.rankId}
                            adminRank={message.adminRank}
                            gracePeriod={gracePeriod}
                        />)
                    }
                    {
                        shoutboxMessages.filter((m) => !pinnedMessageIds.has(m.id)).map((message) => <ShoutboxMessage
                            user={props.user} message={message}
                            key={message.id}
                            pinned={false}
                            ranks={loadRankData.data}
                            rankId={message.rankId}
                            adminRank={message.adminRank}
                            gracePeriod={gracePeriod}
                        />)
                    }
                </Stack>
            </Paper>
        </BlockUi>
    );
}