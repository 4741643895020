import {AwardedAwardResponse} from "../../data/AwardResponse";
import {Button, Grid, Paper} from "@mui/material";
import {ProfileAward} from "../Award";
import {LoadingContent} from "../LoadingContent";
import React, {useEffect} from "react";
import {Link as RouterLink} from "react-router-dom";
import useUser from "../../hooks/useUser";

interface EmployeeAwardsProps {
    awards: AwardedAwardResponse[] | null
    startLoading: () => void
}

export const EmployeeAwards = (props: EmployeeAwardsProps) => {
    const user = useUser()
    useEffect(() => {
        props.startLoading()
    }, [props]);

    let newAwardButton = <></>
    if (user.hasPermissions("award:grant")) {
        newAwardButton = <Button component={RouterLink} to={`/give-award`} variant={"contained"} color="success">Add Award</Button>
    }

    return props.awards ?
        <>
            {newAwardButton}
            <Grid container sx={{pt:2}} spacing={4}>
                {props.awards.map((award) =>
                    <Grid item md={6} xs={12} key={award.id}>
                        <Paper sx={{p:3, height:"260px", overflowY:"auto"}} variant={"outlined"}>
                            <ProfileAward award={award}/>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </> :
        <LoadingContent isLoading={!props.awards}/>
}