import * as React from 'react';
import {useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditNoteIcon from "@mui/icons-material/EditNote";
import {TextEditor} from "../../components/editor/TextEditor";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import Button from "@mui/material/Button";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {useNavigate} from "react-router-dom";

interface EditResourceFormProps {
    content: string,
    resourceIdentifier: string,
    divisionIdentifier: string,
    id: number
}

export default function EditResourceForm(props: EditResourceFormProps) {
    const [open, setOpen] = useState(false);
    const editorRef = useRef<LexicalEditor>(null)
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <EditNoteIcon color={"info"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Resource</DialogTitle>
                <DialogContent>
                    <TextEditor ref={editorRef} initialContent={props.content}/>
                </DialogContent>
                <DialogContent>
                    <Button color={"info"} onClick={() => handleClose()}>Close</Button>
                    <Button color={"success"} onClick={() => {
                        if (editorRef.current) {
                            editorRef.current.getEditorState().read(() => {
                                let content = $generateHtmlFromNodes(editorRef.current!)

                                makeApiCall({
                                    url: `/api/resource/${props.divisionIdentifier}/${props.id}/content`,
                                    method: "PATCH",
                                    body: {
                                        newContent: content
                                    },
                                    onLoadedCallback: () => {
                                        navigate(`/resource/${props.divisionIdentifier}/${props.resourceIdentifier}`)
                                    },
                                    onError: () => {
                                        handleClose()
                                    }
                                })
                            })
                        }
                    }}>Edit</Button>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}