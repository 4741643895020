import {
    Autocomplete, Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import {UserResponse} from "../data/UserResponse";
import React, {useCallback, useEffect, useState} from "react";
import {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";


interface UserSelectorProps {
    user: UserResponse | null
    onUserSelected: (user: UserResponse | null) => void
    extendedUserInformation?: boolean
}

export const UserSelector = (props: UserSelectorProps) => {
    const [searchResults, setSearchResults] = useState<UserResponse[]>([])
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [selectedUser, setSelectedUser] = useState<UserResponse | null>(null)

    const cancelOldSearch = useCallback(() => {
        apiCall?.cancel()
    }, [apiCall])

    const searchUsers = (identifier: string) => {
        cancelOldSearch()
        const searchParams = new URLSearchParams({identifier: identifier})
        setApiCall(makeApiCall<UserResponse[]>({
            url: `/api/user/search/identifier?` + searchParams.toString(),
            onLoadedCallback: (data) => {
                if (selectedUser && !data.find((u) => u.communityId === selectedUser.communityId)) {
                    setSelectedUser(null)
                }
                setSearchResults(data)
            },
            onError: () => {
            }
        }))
    }

    useEffect(() => {
        return cancelOldSearch
    }, [cancelOldSearch]);

    return (
        <Autocomplete renderInput={(params) => <TextField {...params} label="User"/>}
                      options={searchResults}
                      getOptionLabel={
                            (option: UserResponse) => {
                                if (props.extendedUserInformation) {
                                    return `${option.rank?.displayName} ${option.nick} (${option.rpName}) ${option.badgeNumber ? `(${option.badgeNumber})` : ''}`;
                                } else {
                                    return `${option.nick}`
                                }
                            }}
                      isOptionEqualToValue={(option, value) => option.communityId === value.communityId}
                      onInputChange={(_, input,) => searchUsers(input)}
                      filterOptions={(x) => x}
                      value={props.user}
                      onChange={(_, value) => {
                          props.onUserSelected(value)
                      }}
                      autoHighlight autoComplete autoSelect
        />
    )
}



interface UserSelectorPopupProps {
    title?: string
    description?: string
    open: boolean
    onClose: () => void
    onUserSelected: (user: UserResponse) => void
}

export const UserSelectorPopup = (props: UserSelectorPopupProps) => {
    const [user, setUser] = useState<UserResponse | null>(null)
    return <Dialog open={props.open} onClose={() => props.onClose()}>
        <DialogTitle>{props.title ?? "Select User"}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.description ?? "Please select a user"}
            </DialogContentText>
            <UserSelector user={user} onUserSelected={setUser}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.onClose()}>Cancel</Button>
            <Button disabled={user === undefined} onClick={() => {
                props.onClose()
                if (user) {
                    props.onUserSelected(user)
                }
            }} autoFocus variant="contained">
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
}