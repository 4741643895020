import {createContext, PropsWithChildren, useContext} from "react";

type ThemeType = "dark" | "light"

interface PaletteModeSwitcherProps extends PropsWithChildren {
    setPaletteMode: (type: ThemeType) => void
}

export const PaletteModeSwitcherContext = createContext<PaletteModeSwitcherProps>({
    setPaletteMode: () => {}
})

export const usePaletteModeSwitcher = () => {
    return useContext(PaletteModeSwitcherContext).setPaletteMode
}