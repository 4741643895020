import {Box, Divider, Grid, Paper, Stack, Tab, Typography} from "@mui/material";
import {Info} from "@mui/icons-material";
import useApiCall from "../hooks/CancellableApiCall";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {ReactElement, ReactNode} from "react";
import {divisionIdentifierUrlPrettifier} from "../utils";
import {LoadingContent} from "../components/LoadingContent";
import {DivisionSummaryWithHeadAndRoles} from "../data/Division";
import {PoliceAvatar} from "../components/PoliceAvatar";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import GavelIcon from '@mui/icons-material/Gavel';
import {AvatarBlock, TextBracket} from "../components/profile/ProfileInfo";
import {PoliceUser} from "../components/PoliceUser";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Roster} from "../components/division-manager/Roster";
import {Activity} from "../components/division-manager/Activity";
import {BanList} from "../components/division-manager/BanList";
import {Applications} from "../components/division-manager/Applications";

interface DivisionManagementContentProps {
    division: DivisionSummaryWithHeadAndRoles
}

interface TabContent {
    title: string
    path?: DivisionManagementTab
    icon: ReactElement
    content: ReactNode
}

type DivisionManagementTab =
    undefined
    | "roster"
    | "activity"
    | "ban-list"
    | "application-bypasses"
    | "stats"

const DivisionManagementContent = (props: DivisionManagementContentProps) => {
    const navigate = useNavigate()
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const tabs: TabContent[] = []
    tabs.push({
        title: "Roster",
        path: "roster",
        icon: <EmojiPeopleIcon/>,
        content: <Roster divisionIdentifier={props.division.division.identifier} />
    })
    tabs.push({
        title: "Activity",
        path: "activity",
        icon: <AccessTimeFilledIcon/>,
        content: <Activity divisionIdentifier={props.division.division.identifier} />
    })
    tabs.push({
        title: "Bans",
        path: "ban-list",
        icon: <GavelIcon/>,
        content: <BanList divisionIdentifier={props.division.division.identifier} />
    })
    tabs.push({
        title: "Application Bypasses",
        path: "application-bypasses",
        icon: <AutoModeIcon/>,
        content: <Applications divisionIdentifier={props.division.division.identifier} />
    })
    tabs.push({
        title: "Statistics",
        path: "stats",
        icon: <Info/>,
        content: <></>
    })

    const tabIndex = tabs.findIndex((t) => t.path === params.tab) ?? 0

    function changeTab(index: number) {
        const tabValue = tabs[index].path
        navigate(`/division-management/${props.division.division.identifier}/${tabValue}`)
    }

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <Paper variant={"outlined"} sx={{padding: '15px'}}>
                    <AvatarBlock>
                        <PoliceAvatar
                            avatarUrl={props.division.division.badge}
                            dimensions={150}
                            rounded={false}
                        ></PoliceAvatar>
                        <TextBracket>
                            <Typography variant="h5" color="text.primary">{props.division.division.displayName}</Typography>
                            {props.division.head ?
                                <Typography>
                                    Headed by <PoliceUser user={props.division.head}/>
                                </Typography> : <></>}
                        </TextBracket>
                    </AvatarBlock>
                </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <Paper variant={"outlined"}
                       sx={{padding: "15px", height: "181px", overflow: "auto", wordWrap: "break-word"}}>
                    <Typography variant="h6" gutterBottom component="span">
                        Description
                    </Typography>
                    <Divider/>
                    <Typography component="span" variant="body1" sx={{pt: 1}}>
                        {props.division.division.description}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <Paper variant={"outlined"}
                       sx={{padding: "15px", height: "181px", overflow: "auto", wordWrap: "break-word"}}>
                    <Stack spacing={1}>
                        <Typography variant="h6" gutterBottom component="span">
                            Command Team
                        </Typography>
                        <Divider/>
                        {props.division.staff && props.division.staff.map(member => {
                            return <Stack direction="row" spacing={1} key={member.communityId}>
                                <Typography>{member.divisionRoleDisplayName} - </Typography><PoliceUser user={member} rank={member.policeRank}/>
                            </Stack>
                        })}
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
        <Grid container sx={{pt:2}} spacing={2}>
            <Grid item xs={12}>
                <TabContext value={tabIndex.toString()}>
                    <Paper sx={{marginBottom:2}} variant={"outlined"}>
                        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                            <TabList onChange={(_, newValue) => {
                                setSearchParams(searchParams)
                                changeTab(parseInt(newValue))
                            }} variant="scrollable"
                                     scrollButtons="auto">
                                {tabs.map((data, idx) =>
                                    <Tab icon={data.icon} label={data.title} value={idx.toString()} key={data.title}/>
                                )}
                            </TabList>
                        </Box>
                    </Paper>
                    <Paper variant={"outlined"}>
                        {tabs.map((data, idx) =>
                            <TabPanel value={idx.toString()} key={data.title}>
                                {tabIndex === idx ? data.content : <></>}
                            </TabPanel>
                        )}
                    </Paper>
                </TabContext>
            </Grid>
        </Grid>
    </>
}

export function DisplayManagementPage() {
    const params = useParams()
    const divisionData = useApiCall<DivisionSummaryWithHeadAndRoles>({
        url: `/api/division/${divisionIdentifierUrlPrettifier(params.divisionIdentifier ?? "", false)}`
    })

    return <LoadingContent isLoading={divisionData.isLoading || !divisionData.data}>
        <DivisionManagementContent division={divisionData.data!}/>
    </LoadingContent>
}