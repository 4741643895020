import {AnnouncementResponse} from "../../data/AnnouncementResponse";
import {Box, Button, Divider, Paper, Stack, Typography, Grid, useTheme} from "@mui/material";
import {formatTimestamp} from "../../utils";
import {EditorContent} from "../editor/EditorContent";
import LoginIcon from "@mui/icons-material/Login";
import React from "react";
import {Link as RouterLink} from 'react-router-dom'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {PoliceUser} from "../PoliceUser";
import {AccountResponse} from "../../data/AccountResponse";

interface AnnouncementPosterLinkProps {
    communityId: string,
    nick: string
}

const AnnouncementPosterLink = (props: AnnouncementPosterLinkProps) => {
    return (
        <>
            <PoliceUser user={props}/>
        </>
    )
}

interface AnnouncementSubtitleProps {
    announcement: AnnouncementResponse
}

export const AnnouncementSubtitle = (props: AnnouncementSubtitleProps) => {
    const theme = useTheme()

    let announcementLink = null
    if (props.announcement.posterCommunityId && props.announcement.posterNick) {
        announcementLink = <>
            <AnnouncementPosterLink communityId={props.announcement.posterCommunityId}
                                    nick={props.announcement.posterNick}/>
        </>
    }
    return (
        <Typography variant="body2" sx={{float: "left", display: "flex", alignItems: "center", pt: 1}}
                    component="span"
                    color={theme.palette.text.secondary}>
            Posted {formatTimestamp(props.announcement.date)} by&nbsp;{announcementLink} {props.announcement.lastEditorCommunityId ?
            <>
                <Typography variant="body2" color={theme.palette.text.secondary}>&nbsp;edited by&nbsp;</Typography>
                    <AnnouncementPosterLink communityId={props.announcement.lastEditorCommunityId}
                                                                nick={props.announcement.lastEditorNick!!}/>
                <Typography variant="body2" color={theme.palette.text.secondary}>&nbsp;at {formatTimestamp(props.announcement.lastEditDate!!)}</Typography>
            </> : <></>} </Typography>
    )
}

interface AnnouncementPreviewProps {
    announcement: AnnouncementResponse,
    user: AccountResponse
}


export const AnnouncementPreview = (props: AnnouncementPreviewProps) => {

    const theme = useTheme()

    return (
        <Grid item xs={12}>
            <Paper variant={"outlined"}>
                <Stack>

                    <Box sx={{p: 2, backgroundColor: theme.palette.action.hover, borderRadius: "4px 4px 0 0"}}>

                        <Typography variant={"h6"}>
                            {props.announcement.title}
                        </Typography>

                    </Box>

                    <Divider/>

                    <EditorContent style={{
                        maxHeight: 200,
                        overflow: "hidden",
                        marginTop: 10,
                        marginBottom: 10,
                        paddingLeft: 32,
                        paddingRight: 32
                    }}
                                   content={props.announcement.content}></EditorContent>

                    <Divider/>

                    <Box sx={{
                        p: 2,
                        display: {xs: "flex", sm: "block"},
                        justifyContent: {xs: "center", sm: "normal"}
                    }}>
                        <AnnouncementSubtitle announcement={props.announcement}/>
                        <Button sx={{
                            display: {xs: "none", sm: "flex"},
                            float: "right"
                        }} size={"medium"} variant="text" component={RouterLink}
                                to={`/announcement/${props.announcement.id}`}
                        >
                            Read More
                            <LoginIcon/>
                        </Button>
                        {props.user.permissions.includes(`${props.announcement.divisionIdentifier}-announcement:create`) ?
                            <Button size={"medium"} variant="text" sx={{
                                display: {xs: "none", sm: "flex"},
                                float: "right"
                            }} component={RouterLink}
                                    to={`/announcement/edit/${props.announcement.id}`}>
                                Edit Article
                                <AppRegistrationIcon/>
                            </Button> : <></>}
                    </Box>

                    <Divider sx={{display: {xs: "block", sm: "none"}}}/>

                    <Box sx={{
                        p: 2,
                        display: {xs: "block", sm: "none"}
                    }}>
                        <Button size={"medium"} variant="text" sx={{width: "100%"}} component={RouterLink}
                                to={`/announcement/${props.announcement.id}`}>
                            Read More
                            <LoginIcon/>
                        </Button>
                        {props.user.permissions.includes(`${props.announcement.divisionIdentifier}-announcement:create`) ?
                            <Button size={"medium"} type={"success"} variant="text" sx={{width: "100%"}} component={RouterLink}
                                    to={`/announcement/edit/${props.announcement.id}`}>
                                Edit
                                <AppRegistrationIcon/>
                            </Button> : <></>}
                    </Box>
                </Stack>
            </Paper>
        </Grid>
    );
}