import {AccountResponse} from "../../data/AccountResponse";
import {UserResponse} from "../../data/UserResponse";
import React, {useState} from "react";
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {ObservationReportForm} from "./ObservationReportForm";
import {ObservationReportResponse} from "../../data/ObservationReportResponse";
import {formatDate, reduceLargeTextInput} from "../../utils";
import useUser from "../../hooks/useUser";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import {ObservationReportViewForm} from "./ObservationReportViewForm";
import {PoliceUser} from "../PoliceUser";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {ConfirmationDialog} from "../ConfirmationDialog";

interface EmployeeObservationReportsContentProps {
    self: AccountResponse
    user: UserResponse
    reports: ObservationReportResponse[] | null
    onReportSubmitted: () => void
}

export const EmployeeObservationReportsContent = (props: EmployeeObservationReportsContentProps) => {
    const [showDialog, setShowDialog] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [targetReportId, setTargetReportId] = useState(0)
    const user = useUser()

    let addButton = <></>
    if (user.hasPermissions("observationReport:create")) {
        addButton =
            <Button size="small" variant="contained" onClick={() => setShowDialog(true)}>Add Observation Report</Button>
    }
    let form = <></>
    if (showDialog) {
        form = <ObservationReportForm userId={props.user.communityId} open={showDialog}
                                      onClose={() => setShowDialog(false)} onSubmit={props.onReportSubmitted}/>
    }
    let managementColum = <></>
    if (user.hasPermissions("observationReport:view")) {
        managementColum = <TableCell>Actions</TableCell>
    }

    const deleteOR = (confirm: boolean) => {
        setOpenConfirm(false)
        if (confirm) {
            makeApiCall({
                url: `/api/observation-report/${targetReportId}`,
                method: "DELETE",
                onLoadedCallback: () => {
                    props.onReportSubmitted()
                },
                onError: () => {
                }
            })
        }
    }

    return <>
        {addButton}
        <TableContainer sx={{overflowX: "auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Patrol Date</TableCell>
                        <TableCell>What The Officer Did Well</TableCell>
                        <TableCell>What The Officer Should Improve</TableCell>
                        <TableCell>Supervisor</TableCell>
                        {managementColum}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.reports?.map((report) => <TableRow key={report.id.toString()}>
                        <TableCell>{formatDate(report.date)}</TableCell>
                        <TableCell><Typography
                            variant={"caption"}>{reduceLargeTextInput(report.positive)}</Typography></TableCell>
                        <TableCell><Typography
                            variant={"caption"}>{reduceLargeTextInput(report.improvement)}</Typography></TableCell>
                        <TableCell>
                            <PoliceUser user={{communityId: report.issuerCommunityId, nick: report.issuerNick}}/>
                        </TableCell>
                            <TableCell>
                                <Stack direction="row" spacing={1}>
                                    {user.hasPermissions("observationReport:view") ? <ObservationReportViewForm id={report.id}/> : <></>}
                                    {user.hasPermissions("observationReport:delete") ?
                                        <Tooltip title={"Delete OR"}>
                                        <DeleteForeverIcon color={"error"} onClick={() => {
                                            setOpenConfirm(true)
                                            setTargetReportId(report.id)
                                        }}/>
                                    </Tooltip> : <></>}
                                </Stack>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        <ConfirmationDialog title={"Delete Observation Report"} description={"This action cannot be undone"} open={openConfirm} onClose={deleteOR} />
        {form}
    </>
}

interface EmployeeObservationReportsProps {
    self: AccountResponse
    user: UserResponse
}

export const EmployeeObservationReports = (props: EmployeeObservationReportsProps) => {
    const observationReportURL = (props.self.communityId === props.user.communityId) ? "/api/observation-report/user/-" : `/api/observation-report/user/${props.user.communityId}`
    const loadObservationReports = useApiCall<ObservationReportResponse[]>({
        initialUrl: observationReportURL,
    })

    return (
        <LoadingContent
            isLoading={(loadObservationReports.isLoading || !loadObservationReports.data) && loadObservationReports.data != null}>
            <EmployeeObservationReportsContent self={props.self} user={props.user} reports={loadObservationReports.data}
                                               onReportSubmitted={loadObservationReports.refresh}/>
        </LoadingContent>
    )
}