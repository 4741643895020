import React from "react";
import {styled} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {Avatar} from "@mui/material";
import {useNavigate} from "react-router-dom";

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        zIndex: "auto",
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

interface ProfilePictureProps {
    avatarUrl: string
    color?: string
    dimensions?: number
    rounded?: boolean
}

interface PoliceAvatarProps extends ProfilePictureProps {
    online?: boolean
    userCommunityId?: string
}

function ProfilePicture(props: ProfilePictureProps) {
    return <Avatar
        alt={"A Officer's Avatar"}
        src={props.avatarUrl}
        sx={{
            width: props.dimensions,
            height: props.dimensions,
            border: "2px solid #" + props.color,
            borderRadius: (!props.rounded && props.rounded !== undefined) ? "0%" : "50%",
        }}
    />
}

export function PoliceAvatar(props: PoliceAvatarProps) {
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/user/${props.userCommunityId}`)}>
            {props.online && (
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    variant="dot"
                >
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ProfilePicture
                            avatarUrl={props.avatarUrl}
                            color={props.color}
                            dimensions={props.dimensions ?? 56}
                            rounded={props.rounded}
                        ></ProfilePicture>
                    </div>
                </StyledBadge>
            )}
            {!props.online && (
                <ProfilePicture
                    avatarUrl={props.avatarUrl}
                    color={props.color}
                    dimensions={props.dimensions ?? 56}
                    rounded={props.rounded}
                ></ProfilePicture>
            )}
        </div>
    );
}