import {useSearchParams} from "react-router-dom";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import React, {Fragment, useState} from "react";
import {Box, Divider, Pagination, Paper, Stack, Typography} from "@mui/material";
import {NotificationResponse} from "../data/NotificationResponse";
import {NotificationMenuEntry} from "../components/NotificationMenu";
import NotificationsIcon from "@mui/icons-material/Notifications";


interface NotificationPaginationResponse {
    notifications: NotificationResponse[],
    pages: number,
    totalNotifications: number
}

export const NotificationsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [changeApiCall, setChangeApiCall] = useState<ApiCallResponseData>()
    const loadNotifications = useApiCall<NotificationPaginationResponse>({
        url: `/api/notification?page=${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }


    const deleteNotification = (id: number) => {
        changeApiCall?.cancel()
        setChangeApiCall(makeApiCall({
            url: `/api/notification/${id}`,
            method: "DELETE",
            onLoadedCallback: () => {
                loadNotifications.refresh()
            },
            onError: () => {
            },
        }))
    }

    const markNotificationRead = (id: number, read: boolean) => {
        changeApiCall?.cancel()
        setChangeApiCall(makeApiCall({
            url: `/api/notification/${id}/read?read=${read}`,
            method: "PUT",
            onLoadedCallback: () => {
                loadNotifications.refresh()
            },
            onError: () => {
            },
        }))
    }

    const totalPages = Math.max(loadNotifications.data?.pages ?? 1, 1)

    return (
        <Paper variant={"outlined"} sx={{p: 2}}>
            <Stack spacing={1} direction="row" alignItems="center" sx={{mb: 2}}>
                <NotificationsIcon color="disabled"/>
                <Typography variant="h6"> Notifications </Typography>
            </Stack>
            <Stack spacing={0}>
                {loadNotifications.data && loadNotifications.data.notifications.map((notification) => {
                    return <Fragment key={notification.id}>
                        <NotificationMenuEntry notification={notification}
                                               markRead={markNotificationRead} delete={deleteNotification}/>
                        <Divider/>
                    </Fragment>
                })}
            </Stack>
            <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
                <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                            onChange={handleChange}/>
            </Box>
        </Paper>
    )
}