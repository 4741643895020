import useUser from "../hooks/useUser";
import {
    Alert,
    Checkbox,
    FormControlLabel,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {BlockUi} from "../components/BlockUi";
import {useNavigate} from "react-router-dom";
import {TextEditor} from "../components/editor/TextEditor";
import {Division} from "../data/Division";
import {AlertType} from "../data/AlertType";


interface CreateAnnouncementResponse {
    id: number
}

export const CreateAnnouncementPage = () => {
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [title, setTitle] = useState("")
    const [division, setDivision] = useState(0)
    const [isPrivate, setIsPrivate] = useState(false)
    const user = useUser()

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const allDivisionsData = useApiCall<Division[]>({
        url: "/api/division/list-divisions"
    })

    useUser({
        divisionPermission: "announcement:create"
    })

    const cancelOldApiCall = useCallback(() => {
        apiCall?.cancel()
    }, [apiCall])

    useEffect(() => {
        return cancelOldApiCall
    }, [cancelOldApiCall])

    const submitArticle = (html: string) => {
        setApiCall(makeApiCall({
            url: `/api/announcement/${
                allDivisionsData.data?.find((p) => {
                    return p.id === division
                })?.identifier ?? "unknown"
            }`,
            method: "POST",
            body: {
                title: title,
                divisionId: division,
                isPrivate: isPrivate,
                content: html
            },
            redirectOnUnauthorized: navigate,
            onLoadedCallback: (data: CreateAnnouncementResponse) => {
                const id = data.id
                navigate(`/announcement/${id}`)
            },
            onError: () => {
                setAlert("error", "An error occurred while creator announcement")
            }
        }))
    }

    return (
        <>
            <Typography variant={"h6"} gutterBottom>Create Announcement</Typography>
            <Paper variant={"outlined"} style={{padding: 20}}>
                <Input placeholder="Title" sx={{mb:2,mt:2}} fullWidth value={title} onChange={event => {
                    setTitle(event.target.value)
                }}/>
                <InputLabel id="division-select">Division</InputLabel>
                <Select sx={{minWidth: "200px", mb:2}} id="division-select" value={division} onChange={event => {
                    setDivision(event.target.value as number)
                }}>{
                    (allDivisionsData.data && allDivisionsData.data.filter(division => {
                        return user.hasPermissions(`${division.identifier}-announcement:create`)
                    }).map(division => {
                        return <MenuItem key={division.id} value={division.id}>{division.displayName}</MenuItem>
                    }))
                }
                </Select>
                {division !== 0 ?
                    <>
                        <BlockUi open={apiCall !== undefined}>
                            <TextEditor onSubmitPressed={submitArticle} submitButtonText="Post Announcement"/>
                        </BlockUi>
                        <Tooltip
                            title={"Can only be seen by members of the selected division"}>
                            <FormControlLabel label={"Private Announcement"} control={
                                <Checkbox onChange={(_, checked) => setIsPrivate(checked)} checked={isPrivate}/>
                            }/>
                        </Tooltip>
                    </> : <></>}
            </Paper>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </>
    )
}