import {Box, Divider, Paper, Typography} from "@mui/material";
import {RankChip} from "./RankChip";
import {formatTimestamp} from "../utils";
import {EditorContent} from "./editor/EditorContent";
import React from "react";
import {useNavigate} from "react-router-dom";

interface MessageBoxWithUserDetailsProps {
    authorCommunityId: string,
    authorNick: string,
    authorRpName: string,
    authorRankDisplayName?: string,
    authorRankColor?: string,
    date: string,
    content: string
}

export const MessageBoxWithUserDetails = (props: MessageBoxWithUserDetailsProps) => {
    const navigate = useNavigate()

    return <Paper variant={"outlined"} >
                <Box sx={{p:2}} display="flex" flexWrap="wrap" gap={1}>
                    <RankChip rank={props?.authorRankDisplayName ?? "Unranked"} color={props?.authorRankColor ?? "white"} onClick={() => {navigate(`/user/${props.authorCommunityId}/`)}}/>
                    <RankChip rank={props.authorNick} onClick={() => {navigate(`/user/${props.authorCommunityId}/`)}}/>
                    <RankChip rank={props.authorRpName} color="23C6C8" onClick={() => {navigate(`/user/${props.authorCommunityId}/`)}}/>
                    <Typography variant="subtitle1">
                        {formatTimestamp(props.date)}
                    </Typography>
                </Box>
        <Divider />
        <Box sx={{p:2}}>
            <EditorContent content={props.content}/>
        </Box>
    </Paper>
}