import React, {useState} from 'react';
import {Alert, Button, Input, Paper, Snackbar, Stack, Typography} from "@mui/material";
import useUser from "../hooks/useUser";
import {MuiFileInput} from "mui-file-input";

type alertType = "success" | "error" | "info"

export const CreateAwardPage = () => {
    useUser()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")
    const [name, setName] = useState("")
    const [file, setFile] = useState<File | null>(null)

    const setAlert = (type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }

    const createAward = (name: string, image: File) => {
        const urlParams = new URLSearchParams({name: name})
        const formData = new FormData()
        formData.append("image", image)
        const request = new XMLHttpRequest()
        request.onreadystatechange = (_) => {
            if (request.readyState === 4 && request.status === 200) {
                setAlert("success", `Created award ${name}!`)
                setFile(null)
                setName("")
            }
        }
        request.onerror = (_) => {
            setAlert("error", "Failed to create award")
        }
        request.open("POST", "/api/awards?" + urlParams.toString())
        request.send(formData)
    }

    return (
        <Paper style={{padding: 16, marginTop: 32}}>
            <Stack spacing={1}>
                <Typography variant="h4">Create Award</Typography>
                <Input placeholder="Name..."
                       onChange={(event) => setName(event.target.value)}/>
                <MuiFileInput inputProps={{accept: ".jpg, .png"}} value={file} onChange={setFile}/>
                <Button variant="contained" onClick={() => {
                    if (name.length > 0 && file) {
                        createAward(name, file)
                    }
                }}>
                    Create
                </Button>
                <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                    <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}}
                           variant="filled">
                        {alertText}
                    </Alert>
                </Snackbar>
            </Stack>
        </Paper>
    )
}
