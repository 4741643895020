import {MiniProfileContext} from "../hooks/useMiniProfile";
import {PropsWithChildren, useContext, useEffect, useRef} from "react";
import {Box} from "@mui/material";

interface MiniProfileTargetProps extends PropsWithChildren {
    communityId: string
}

export const MiniProfileTarget = ({communityId, children}: MiniProfileTargetProps) => {
    const {onElementHovered, onTargetUnmounted} = useContext(MiniProfileContext)
    const ref = useRef<HTMLElement>()

    const onHovered = (hovered: boolean) => {
        if (ref.current) {
            onElementHovered(ref.current, hovered, communityId)
        }
    }

    useEffect(() => {
        const currentRef = ref.current
        return () => {
            if (currentRef) {
                onTargetUnmounted(currentRef)
            }
        }
    }, [onTargetUnmounted]);

    return <Box
        ref={ref}
        onMouseEnter={() => onHovered(true)}
        onMouseLeave={() => onHovered(false)}
    >
        {children}
    </Box>
}