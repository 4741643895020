import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {
    Alert, Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import {LoadingContent} from "../components/LoadingContent";
import React, {useCallback, useState} from "react";
import {AwardResponse} from "../data/AwardResponse";
import {UserResponse} from "../data/UserResponse";
import {UserSelector} from "../components/UserSelector";
import {useNavigate} from "react-router-dom";

interface GrantAwardPageProps {
    awards: AwardResponse[]
}

type alertType = "success" | "error" | "info"

function GrantAwardPageContent(props: GrantAwardPageProps) {
    const [awardMessage, setAwardMessage] = useState("")
    const [awardUser, setAwardUser] = useState<UserResponse | null>(null)
    const [awardIdx, setAwardIdx] = useState(0)
    const award = props.awards.at(awardIdx)
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const cancelOldApiCall = useCallback(() => {
        apiCall?.cancel()
    }, [apiCall])

    const giveAward = (communityId: string, awardId: number, message: string) => {
        if (!(message.length > 0)) return
        cancelOldApiCall()
        setApiCall(makeApiCall({
            url: `/api/user/${communityId}/awards`,
            method: "POST",
            body: {awardId: awardId, message: message},
            redirectOnUnauthorized: navigate,
            onLoadedCallback: () => {
                setAlert("success", "Gave award")
            },
            onError: () => {
                setAlert("error", "An error giving an award")
            }
        }))
    }

    return <Box sx={{width:"100%"}}>
        <Typography variant={"h6"} gutterBottom>Give Award</Typography>
        <Paper variant={"outlined"} style={{
            width:"100%",
            padding: "16px"
        }}>
            <Stack spacing={1}>
                <UserSelector user={awardUser} onUserSelected={setAwardUser} extendedUserInformation={true}/>
                <FormControl>
                    <InputLabel id="award-select-label">Award</InputLabel>
                    <Select id="award-select" value={awardIdx} label="Award" onChange={(event) => {
                        setAwardIdx(event.target.value as number)
                    }}>
                        {props.awards.map((award, idx) =>
                            <MenuItem key={award.name} value={idx}>{award.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Input multiline rows={4} placeholder="Award reason..."
                       onChange={(event) => setAwardMessage(event.target.value)}/>
                <Button disabled={!(awardUser && award && awardMessage.length > 0)} variant="contained" onClick={() => {
                    if (awardUser && award) {
                        giveAward(awardUser.communityId, award.id, awardMessage)
                    }
                }}>
                    Give Award
                </Button>
                <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                    <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                        {alertText}
                    </Alert>
                </Snackbar>
            </Stack>
        </Paper>
    </Box>
}

export const GrantAwardPage = () => {
    const loadAwards = useApiCall<AwardResponse[]>({
        initialUrl: "/api/awards"
    })

    return (
        <>
            <LoadingContent isLoading={loadAwards.isLoading}>
                <Stack spacing={{xs: 2, sm: 4}} direction="row" useFlexGap flexWrap="wrap">
                    <GrantAwardPageContent awards={loadAwards.data!!}/>
                </Stack>
            </LoadingContent>
        </>
    )
}