import React, {Fragment, useEffect, useState} from "react";
import useApiCall from "../hooks/CancellableApiCall";
import {
    Avatar,
    Badge,
    Button,
    Divider,
    Grow,
    Link,
    Paper,
    Stack,
    Typography, useTheme
} from "@mui/material";
import {PopperArrow, StyledPopper} from "./StyledPopper";
import {Link as RouterLink} from "react-router-dom";
import {ConversationSummary} from "../data/ConversationResponse";
import {formatTimestamp} from "../utils";
import {LoadingContent} from "./LoadingContent";

interface InboxMenuProps {
    open: boolean
    onClose: () => void
    anchorEl: HTMLElement | null
}

export const InboxMenu = (props: InboxMenuProps) => {
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
    const [shouldLoadData, setShouldLoadData] = useState(false)
    const [wasOpen, setWasOpen] = useState(false)


    const dataApiCall = useApiCall<ConversationSummary[]>({
        url: "/api/conversation/recent",
        deferLoading: !shouldLoadData
    })
    const conversations = dataApiCall.data

    useEffect(() => {
        setWasOpen(props.open)
        if (!props.open || wasOpen === props.open) return
        setShouldLoadData(true)
        dataApiCall.refresh()
    }, [props.open, wasOpen, dataApiCall])

    return <StyledPopper sx={{zIndex: (theme) => theme.zIndex.appBar + 1}} open={props.open} anchorEl={props.anchorEl}
                         placement="bottom-end" transition
                         modifiers={[
                             {
                                 name: 'arrow',
                                 enabled: true,
                                 options: {
                                     element: arrowRef,
                                 },
                             },
                         ]}>
        {({TransitionProps}) => (
            <Grow {...TransitionProps} timeout={250}>
                <Paper variant={"outlined"} sx={{width: 300, overflowWrap: "break-word"}} elevation={8}>
                    <PopperArrow ref={setArrowRef}/>
                    <Stack spacing={0}>
                        <Typography variant="h6" sx={{padding: 1}}>Conversations</Typography>
                        <Divider/>
                        {(conversations && conversations.length > 0) ?
                            conversations?.map((conversation) => {
                                return (
                                    <Fragment key={conversation.id}>
                                        <ConversationMenuEntry conversation={conversation} onClose={props.onClose}/>
                                        <Divider/>
                                    </Fragment>
                                )
                            }) : (
                                <LoadingContent isLoading={dataApiCall.isLoading || !shouldLoadData}>
                                    <Typography sx={{padding: 1}} variant="body1"><i>You have no recent conversations</i></Typography>
                                    <Divider/>
                                </LoadingContent>
                            )
                        }
                        <Stack direction="row" sx={{padding: 1}}>
                            <Button color="info" variant="text" component={RouterLink} to="/inbox" onClick={() => {
                                props.onClose()
                            }}>Show all</Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Grow>
        )}
    </StyledPopper>
}

interface ConversationMenuEntryProps {
    conversation: ConversationSummary
    onClose: () => void
}

const ConversationMenuEntry = (props: ConversationMenuEntryProps) => {
    const date = props.conversation.lastMessageDate ?? props.conversation.createdAt
    const title = props.conversation.title
    const theme = useTheme()
    const [hovered, setHovered] = useState(false)

    const lastPoster = props.conversation.lastPoster ?? props.conversation.creator
    const avatarUrl = `/api/user/${lastPoster.communityId}/avatar`

    const hoveredColor = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100]
    const backgroundColor = hovered ? hoveredColor : undefined

    return (
            <Link variant="body1" component={RouterLink} to={`/inbox/${props.conversation.id}`} color="inherit"
                  onClick={() => props.onClose()} underline="none"
                  sx={{width: "100%", background: backgroundColor, padding: 1}}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
            >
                <Stack spacing={2} direction={"row"}>
                    <Badge color="error" variant="dot" invisible={props.conversation.read}
                           anchorOrigin={{vertical: "top", horizontal: "left"}}>
                        <Avatar src={avatarUrl}/>
                    </Badge>
                    <Stack spacing={0.5}>
                        <Typography sx={{textDecoration: hovered ? "underline" : "none"}}>{title}</Typography>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle2" color="inherit">{formatTimestamp(date)}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Link>
    )

}