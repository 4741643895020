import {Chip, ChipProps} from "@mui/material";
import {Link} from "react-router-dom";

interface RankChipProps {
    rank: string
    color?: string
    onClick?: () => void
    chipProps?: ChipProps,
    link?: string
}

export function RankChip(props: RankChipProps) {
    const chip = <Chip onClick={props.onClick} label={props.rank} size="small" color={"primary"}
                 style={props.color ? {backgroundColor: `#${props.color}`} : {}} {...props.chipProps}
    />

    if (props.link) {
        return <Link to={props.link}>{chip}</Link>
    } else {
        return chip
    }
}