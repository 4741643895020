import React, {PropsWithChildren} from "react";
import {Backdrop, CircularProgress} from "@mui/material";

interface BlockUiProps extends PropsWithChildren {
    open: boolean
}

export const BlockUi = ({open, children}: BlockUiProps) => {
    return (
        <div style={{"position": "relative"}}>
            <Backdrop
                sx={{zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute", width: "100%", height: "100%"}}
                open={open}
            >
                <CircularProgress style={{marginTop: "auto", marginBottom: "auto"}}/>
            </Backdrop>
            {children}
        </div>
    )
}