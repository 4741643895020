import React, {useEffect, useState} from 'react';
import PoliceEvent from '../components/recent-activity/PoliceEvent';
import {Timeline, timelineItemClasses} from '@mui/lab';
import {RecentActivityPageinationResponse} from '../data/RecentActivityPageinationResponse';
import {Box, Pagination, Paper, Stack} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Typography from '@mui/material/Typography';
import {useSearchParams} from "react-router-dom";
import useApiCall from "../hooks/CancellableApiCall";
import {RankResponse} from "../data/RankResponse";
import useUser from "../hooks/useUser";

export const PoliceTimeLinePage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const policeRanks = useApiCall<RankResponse[]>({
        url: `/api/rank/list/`,
    })
    const [recentActivity, setRecentActivity] = useState<RecentActivityPageinationResponse>({
        recentActivities: [],
        totalEntries: 1,
        totalPages: 1
    });
    const user = useUser()

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/recent-activity/?page=${page}`)
                const data = await response.json()
                setRecentActivity(data)
            } catch (error) {
                console.error('Error fetching recent activity:', error)
            }
        };
        fetchData()
    }, [page])

    const totalPages = recentActivity.totalPages

    return (
        <Paper variant={"outlined"} style={{padding: 16, marginTop: 16}}>
            <Stack spacing={1} direction="row" alignItems="center">
                <ReceiptLongIcon color="disabled"/>
                <Typography variant="h6"> Paralake Police Department Timeline </Typography>
            </Stack>
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
            >
                {recentActivity.recentActivities.map((event) => (
                    <PoliceEvent user={user} key={event.id} event={event} policeRanks={policeRanks?.data ?? undefined}/>
                ))}
            </Timeline>

            <Box sx={{justifyContent: "center", display: "flex", width: "100%", mt: 2}}>
                <Pagination color={'secondary'} count={totalPages} page={page} onChange={handleChange}/>
            </Box>
        </Paper>
    );
};
