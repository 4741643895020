import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Input} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import EditNoteIcon from "@mui/icons-material/EditNote";

interface EditFormProps {
    id: number,
    content: string,
    communityId: string
}

export default function EditActivityNoticeForm(props: EditFormProps) {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(props.content)
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <EditNoteIcon color={"info"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Reason</DialogTitle>
                <DialogContent>
                    <Input multiline rows={3} value={reason} size="small" sx={{width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogActions>
                    <Button color={"warning"} onClick={handleClose}>Cancel</Button>
                    <Button color={"info"} onClick={() =>
                        makeApiCall({
                            url: `/api/activity/${props.communityId}/${props.id}/edit`,
                            method: "PATCH",
                            body: {
                                newReason: reason,
                            },
                            onLoadedCallback: () => {
                                navigate(`/user/${props.communityId}`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Edit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}