import {Alert, Paper, Snackbar, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {BlockUi} from "../components/BlockUi";
import {useNavigate, useParams} from "react-router-dom";
import {TextEditor} from "../components/editor/TextEditor";
import {AlertType} from "../data/AlertType";
import {AnnouncementResponse} from "../data/AnnouncementResponse";

export const EditAnnouncementPage = () => {
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const params = useParams()

    const announcementData = useApiCall<AnnouncementResponse>({
        url: `/api/announcement/${params.id}`
    })

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const editArticle = (htmlBody: string) => {
        setApiCall(makeApiCall({
            url: `/api/announcement/${announcementData.data?.divisionIdentifier}`,
            method: "PATCH",
            body: {
                announcementId: params.id,
                newContent: htmlBody
            },
            redirectOnUnauthorized: navigate,
            onLoadedCallback: () => {
                navigate(`/announcement/${params.id}`)
            },
            onError: () => {
                setAlert("error", "An error occurred while editing the announcement")
            }
        }))
    }

    return (
        <>
            <Typography variant={"h6"} gutterBottom>Edit Announcement</Typography>
            <Paper variant={"outlined"} style={{padding: 20}}>
                <BlockUi open={apiCall !== undefined}>
                    <TextEditor initialContent={announcementData.data?.content} onSubmitPressed={editArticle} submitButtonText="Edit Announcement"/>
                </BlockUi>
            </Paper>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </>
    )
}