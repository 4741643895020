import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../../hooks/CancellableApiCall";
import {useNavigate} from "react-router-dom";
import {pluralize} from "../../utils";
import {ObservationReportDraftResponse} from "../../data/ObsercationReportDraftResponse";
import {useTimer} from "../../hooks/useTimer";

interface ObservationReportFormProps {
    userId: string
    open: boolean
    onClose: () => void
    onSubmit: () => void
}

const skillScores: string[] = [
    "Unsatisfactory",
    "Below Average",
    "Average",
    "Satisfactory",
    "Good",
    "Excellent"
]

const rankScores: string[] = [
    "Meets expectations of below rank",
    "Meets some expectations of current rank",
    "Meets expectations of current rank",
    "Meets some expectations of next rank",
    "Meets expectations of next rank",
]

const TEXT_MAX_LENGTH = 1500
const TEXT_TOO_LONG_ERROR = `Message longer than ${TEXT_MAX_LENGTH} characters`
const TEXT_EMPTY_ERROR = "Empty message"

export const ObservationReportForm = (props: ObservationReportFormProps) => {
    const navigate = useNavigate()
    const [reportId, setReportId] = useState(-1)
    const [positive, setPositive] = useState("")
    const [positiveError, setPositiveError] = useState("")
    const [improvement, setImprovement] = useState("")
    const [improvementError, setImprovementError] = useState("")
    const [duration, setDuration] = useState(0)
    const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()))
    const [summary, setSummary] = useState("")
    const [summaryError, setSummaryError] = useState("")
    const [leadership, setLeadership] = useState(0)
    const [communication, setCommunication] = useState(0)
    const [investigative, setInvestigative] = useState(0)
    const [knowledge, setKnowledge] = useState(0)
    const [rank, setRank] = useState(0)
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const loadDraft = useApiCall<ObservationReportDraftResponse>({
        method: "POST",
        initialUrl: "/api/observation-report/draft",
        initialBody: {
            communityId: props.userId,
            positive: positive,
            improvement: improvement,
            duration: duration,
            date: date!.toISOString(),
            summary: summary,
            leadershipScore: leadership,
            communicationScore: communication,
            investigativeScore: investigative,
            knowledgeScore: knowledge,
            rankScore: rank,
            isDraft: true
        },
    })

    const resetValues = () => {
        setReportId(-1)
        setPositive("")
        setImprovement("")
        setDuration(0)
        setDate(dayjs(new Date()))
        setSummary("")
        setLeadership(0)
        setCommunication(0)
        setInvestigative(0)
        setKnowledge(0)
        setRank(0)
    }

    const validate = useCallback((submit: boolean): boolean => {
        let valid = true
        const inputs = [
            {field: positive, setError: setPositiveError},
            {field: improvement, setError: setImprovementError},
            {field: summary, setError: setSummaryError},
        ]
        inputs.forEach(input => {
            if (!validateField(input.field, input.setError, submit)) {
                valid = false
            }
        })
        return valid
    }, [improvement, positive, summary])

    const validateField = (input: string, setError: (e: string) => void, submit: boolean): boolean => {
        let valid = true
        if (input.length > TEXT_MAX_LENGTH) {
            setError(TEXT_TOO_LONG_ERROR)
            valid = false
        } else if (input.length < 1) {
            if (submit) {
                setError(TEXT_EMPTY_ERROR)
                valid = false
            }
        } else {
            setError("")
        }
        return valid
    }

    const submit = useCallback((submit: boolean, shouldClose: boolean) => {
        const url = submit ? "/api/observation-report" : "/api/observation-report/draft/update"
        const body = {
            id: reportId,
            communityId: props.userId,
            positive: positive,
            improvement: improvement,
            duration: duration,
            date: date!.toISOString(),
            summary: summary,
            leadershipScore: leadership,
            communicationScore: communication,
            investigativeScore: investigative,
            knowledgeScore: knowledge,
            rankScore: rank,
            isDraft: !submit
        }
        if (reportId === -1) return
        if (!validate(submit)) return
        apiCall?.cancel()
        setApiCall(makeApiCall({
            url: url,
            method: "POST",
            body: body,
            onLoadedCallback: () => {
                if (shouldClose) {
                    props.onClose()
                    props.onSubmit()
                    navigate(`/user/${props.userId}/observation-reports`, {replace: true})
                    resetValues()
                }
            },
            onError: () => {
            }
        }))
    }, [apiCall, communication, date, duration, improvement, investigative, knowledge, leadership, navigate, positive, props, rank, reportId, summary, validate])

    useTimer({
        interval: 60 * 1000,
        callback: () => {
            submit(false, false)
        }
    })

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall])

    useEffect(() => {
        if (!loadDraft.data || loadDraft.isLoading) return
        setReportId(loadDraft.data.id)
        setPositive(loadDraft.data.positive)
        setImprovement(loadDraft.data.improvement)
        setDuration(loadDraft.data.duration)
        setDate(dayjs(loadDraft.data.date))
        setSummary(loadDraft.data.summary)
        setLeadership(loadDraft.data.leadershipScore)
        setCommunication(loadDraft.data.communicationScore)
        setInvestigative(loadDraft.data.investigativeScore)
        setKnowledge(loadDraft.data.knowledgeScore)
        setRank(loadDraft.data.rankScore)
    }, [loadDraft.data, loadDraft.isLoading])

    return <Dialog fullWidth={true} open={props.open} onClose={() => props.onClose()} scroll="paper">
        <DialogTitle>Create Observation Report</DialogTitle>
        <DialogContent dividers={true}>
            <Stack spacing={2} alignItems="stretch">
                <TextField error={positiveError.length > 0} label="*What The Officer Did Well" variant="standard"
                           value={positive} multiline rows={4} helperText={positiveError}
                           onChange={(event) => {
                               validateField(event.target.value, setPositiveError, false)
                               setPositive(event.target.value)
                           }}/>
                <TextField error={improvementError.length > 0} label="*What The Officer Should Improve"
                           variant="standard" value={improvement} multiline
                           rows={4} helperText={improvementError}
                           onChange={(event) => {
                               validateField(event.target.value, setImprovementError, false)
                               setImprovement(event.target.value)
                           }}/>
                <Stack direction="row" spacing={2}>
                    <Slider value={duration} onChange={(_, value) => setDuration(value as number)} min={0}
                            max={120} sx={{maxWidth: "75%"}}/>
                    <Typography>{duration} {pluralize("Minute", duration)}</Typography>
                </Stack>
                <DatePicker value={date} onChange={(value) => setDate(value)}/>
                <TextField error={summaryError.length > 0} label="Summary" variant="standard" value={summary} multiline
                           rows={4} helperText={summaryError}
                           onChange={(event) => {
                               validateField(event.target.value, setSummaryError, false)
                               setSummary(event.target.value)
                           }}/>
                <FormControl>
                    <InputLabel id="leadership-select-label">Leadership</InputLabel>
                    <Select id="leadership-select" value={leadership} variant="standard" label="Leadership"
                            onChange={(event) => {
                                setLeadership(event.target.value as number)
                            }}>
                        {skillScores.map((text, idx) =>
                            <MenuItem key={"leadership" + idx} value={idx}>{text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="communication-select-label">Communication</InputLabel>
                    <Select id="communication-select" value={communication} variant="standard" label="Communication"
                            onChange={(event) => {
                                setCommunication(event.target.value as number)
                            }}>
                        {skillScores.map((text, idx) =>
                            <MenuItem key={"communication" + idx} value={idx}>{text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="investigative-select-label">Investigative Skills</InputLabel>
                    <Select id="investigative-select" value={investigative} variant="standard"
                            label="Invenstigative Skills"
                            onChange={(event) => {
                                setInvestigative(event.target.value as number)
                            }}>
                        {skillScores.map((text, idx) =>
                            <MenuItem key={"investigative" + idx} value={idx}>{text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="knowledge-select-label">Law/Policy Knowledge</InputLabel>
                    <Select id="knowledge-select" value={knowledge} variant="standard" label="Law/Policy Knowledge"
                            onChange={(event) => {
                                setKnowledge(event.target.value as number)
                            }}>
                        {skillScores.map((text, idx) =>
                            <MenuItem key={"knowledge" + idx} value={idx}>{text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="rank-select-label">Rank Performance</InputLabel>
                    <Select id="rank-select" value={rank} variant="standard" label="Rank Performance"
                            onChange={(event) => {
                                setRank(event.target.value as number)
                            }}>
                        {rankScores.map((text, idx) =>
                            <MenuItem key={"rank" + idx} value={idx}>{text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Typography variant="body2">* Shown to the officer</Typography>
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button variant="outlined" onClick={() => props.onClose()}>Cancel</Button>
                    <Button variant="contained"
                            onClick={() => submit(true, true)}>Submit</Button>
                </Stack>
            </Stack>
        </DialogContent>
    </Dialog>
}