import React, {useEffect} from 'react';
import plpdLogo from '../assets/plpd.png';
import {Button, Container, Link, Paper} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import useUser from "../hooks/useUser";


export const LoginPage = () => {
    const navigate = useNavigate()
    const userData = useUser({requireUser: false})

    useEffect(() => {
        if (userData.user) {
            navigate("/", {replace: true})
        }
    }, [navigate, userData.user]);

    return (
        <Container maxWidth="sm">
            <Paper variant={"outlined"} style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
                padding: 32,
                marginTop: 100
            }}>
                <img src={plpdLogo} alt="plpd-logo"
                     style={{maxHeight: 200, marginBottom: 32}}></img>
                <Button variant="contained" style={{minWidth: "80%"}} href="/api/auth/steam" disabled={userData.isLoading}>Login</Button>

                <Link style={{marginTop: 16}} component={RouterLink} to="/terms">Terms of Service</Link>
            </Paper>
        </Container>
    )
}