import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React from "react";
import {UserContextProps} from "../../hooks/useUser";
import {RecordNoteResponse} from "../../data/RecordNoteResponse";
import {
    Box,
    Button, Checkbox,
    FormControlLabel,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {formatTimestamp} from "../../utils";
import {ProfileResponse} from "../../data/ProfileResponse";
import IssueInfractionForm from "./IssueInfractionForm";
import EditInfractionForm from "./EditRecordForm";
import DeleteNotePrompt from "./DeleteNotePrompt";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {RankResponse} from "../../data/RankResponse";
import {RecordTypes} from "../../data/internal-affairs/RecordTypes";

interface EmployeeInfractionsProps {
    user: UserContextProps
    employee: ProfileResponse
    searchRoot: string
}

interface EmployeeInfractionsContentProps extends EmployeeInfractionsProps {
    infractions: RecordNoteResponse[],
    ranks: RankResponse[]
}

export interface RecordNoteRowProps {
    note: RecordNoteResponse
    user?: UserContextProps
    searchRoot?: string
    showSupervisor: boolean
}

export const RecordNoteRow = (props: RecordNoteRowProps) => {
    const navigate = useNavigate()
    let managementColum = <></>
    let hiddenColum = <></>
    if (props?.user?.hasPermissions(["professionalStandards:modifyRecordNote"])) {
        managementColum = <TableCell>
            <EditInfractionForm searchRoot={props.searchRoot!!} infraction={props.note} />
            <DeleteNotePrompt searchRoot={props.searchRoot!!} infraction={props.note} />
        </TableCell>
    }
    if (props?.user?.hasPermissions(["professionalStandards:modifyRecordNote"])) {
        if (props.note.type === "note") {
            hiddenColum = <TableCell>
                <Tooltip
                    title={"Hides the note from the employee's view [Record notes should never hide a mistake from the employee, if the note is being used to track wrongdoing on behalf of the employee it should not be hidden]"}>
                    <FormControlLabel label={"Hidden"} control={
                        <Checkbox  onClick={() =>
                            makeApiCall({
                                url: `/api/records/hide/${props.note.id}`,
                                method: "PATCH",
                                body: {
                                    hidden: !props.note.hidden
                                },
                                onLoadedCallback: () => {
                                    navigate(`/records/${props.searchRoot}`)
                                },
                                onError: () => {}
                            })
                        } checked={props.note.hidden}/>
                    }/>
                </Tooltip>
            </TableCell>
        } else if (props.note.type === "activity_warning") {
            hiddenColum = <TableCell>
                <Tooltip
                    title={"You cannot hide Activity Warnings"}>
                    <FormControlLabel disabled={true} label={"Hidden"} control={
                        <Checkbox  onClick={() => {}} checked={false}/>
                    }/>
                </Tooltip>
            </TableCell>
        }
    }

    let supervisorRow = <></>
    if (props.showSupervisor) {
        supervisorRow = <TableCell>
            <Link
                underline="none"
                component={RouterLink}
                to={`/user/${props.note.supervisorCommunityId}`}>
            {props.note.supervisorNick}</Link></TableCell>
    }

    return <TableRow>
        <TableCell>{RecordTypes.get(props.note.type)?.displayName ?? "Unknown"}</TableCell>
        <TableCell>{formatTimestamp(props.note.date)}</TableCell>
        <TableCell>{props.note.reason}</TableCell>
        {supervisorRow}
        {managementColum}
        {hiddenColum}
    </TableRow>
}

function EmployeeInfractionsContent(props: EmployeeInfractionsContentProps) {
    const navigate = useNavigate()

    let managementRow = <></>
    if (props.user.hasPermissions(["professionalStandards:modifyRecordNote"])) {
        managementRow = <TableCell>Actions</TableCell>
    }

    let removeActivePunishmentsButton = <></>
    if (props.employee.suspended && props.user.hasPermissions(["professionalStandards:removeSuspension"])) {
        removeActivePunishmentsButton = (<Button variant={"outlined"} color="success" onClick={() =>
                    makeApiCall({
                        url: `/api/records/action/remove-suspension/${props.employee.communityId}`,
                        method: "PATCH",
                        onLoadedCallback: () => {
                            navigate(`/user/${props.searchRoot}`);
                        },
                        onError: () => {}
                    })
                }
            >Remove Suspension</Button>
        )
    } else if (props.employee.blacklisted && props.user.hasPermissions(["professionalStandards:removeBlacklist"])) {
        removeActivePunishmentsButton = (<Button variant={"outlined"} color="success" onClick={() =>
                makeApiCall({
                    url: `/api/records/action/remove-blacklist/${props.employee.communityId}`,
                    method: "PATCH",
                    onLoadedCallback: () => {
                        navigate(`/user/${props.searchRoot}`);
                    },
                    onError: () => {}
                })
            }
            >Remove Blacklist</Button>
        )
    }

    return <Stack spacing={4}>
        <Box sx={{overflowX: "auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type of Infraction</TableCell>
                        <TableCell>Date of Infraction</TableCell>
                        <TableCell>Reason for Infraction</TableCell>
                        <TableCell>Supervisor</TableCell>
                        {managementRow}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.infractions?.map((infraction) => {
                        return <RecordNoteRow showSupervisor={true} searchRoot={props.searchRoot} key={infraction.id}
                                              note={infraction} user={props.user}/>
                    })}
                </TableBody>
            </Table>
        </Box>
        <IssueInfractionForm ranks={props.ranks} searchRoot={props.searchRoot} employee={props.employee}/>
        {removeActivePunishmentsButton}
    </Stack>;
}

export const EmployeeInfractions = (props: EmployeeInfractionsProps) => {
    const loadInfractions = useApiCall<RecordNoteResponse[]>({
        initialUrl: `/api/records/infractions/${props.employee.communityId}`
    })

    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: `/api/rank/list`
    })

    return (
        <LoadingContent isLoading={loadRanks.isLoading || loadInfractions.isLoading || loadInfractions.data === null || !loadInfractions.data}>
            <EmployeeInfractionsContent ranks={loadRanks.data!!} searchRoot={props.searchRoot} user={props.user} employee={props.employee}
                                        infractions={loadInfractions.data!!}/>
        </LoadingContent>
    )
}