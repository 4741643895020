import React, {useCallback, useState} from "react"
import {
    Alert,
    Button,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {RankResponse} from "../../data/RankResponse";
import {LoadingContent} from "../../components/LoadingContent";
import {useNavigate, useParams} from "react-router-dom";
import {ApplicationRequirements} from "./ApplicationRequierments";
import {allRequirements} from "./RankRequirements";
import {CertificationResponse} from "../../data/CertificationResponse";
import {divisionIdentifierUrlPrettifier} from "../../utils";
import {DivisionRoles} from "../../data/DivisionRoles";

type alertType = "success" | "error" | "info"

interface NewCertificationApplicationPanelProps {
    ranks: RankResponse[]
    certifications: CertificationResponse[]
    divisionRoles: DivisionRoles[]
}

interface applicationRequirement {
    type: string,
    param: string | number
}

function NewCertificationApplicationPanelContent(props: NewCertificationApplicationPanelProps) {
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")
    const [certification, setCertification] = useState(-1)
    const [description, setDescription] = useState("")
    const [markerRole, setMarkerRole] = useState(-1)
    const [newRoleApplicationRankRequirements, setNewRoleApplicationRankRequirements] = useState<applicationRequirement[]>([])
    const navigate = useNavigate()

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const createNewApplication = (description: string, requirements: applicationRequirement[] | null, markerRole: number, certification: number) => {
        makeApiCall({
            url: `/api/careers/new`,
            method: "POST",
            body: {
                certificationId: certification,
                markerRoleId: markerRole,
                description: description,
                requirements: requirements,
                type: "certification",
            },
            onLoadedCallback: () => {
                setAlert("success", "Application created")
                navigate("/careers")
            },
            onError: () => {
                setAlert("error", "Application failed to create due to a issue")
                setNewRoleApplicationRankRequirements([])
            }
        })
    }

    return <>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Typography variant="h5">New Certification</Typography>
            <Tooltip title="The Certification being applied for" arrow>
                <InputLabel id="new-cert-application-select-label">Certification</InputLabel>
            </Tooltip>
            <Select sx={{minWidth: "100%"}} id="new-cert-application-select" value={certification} label="Role" onChange={(event) => {
                setCertification(event.target.value as number)
            }}>
                {props.certifications.map((cert) =>
                    <MenuItem key={cert.id} value={cert.id}>{cert.displayName}</MenuItem>
                )}
            </Select>
            <Tooltip title="The role that marks the application, everything below is optional" arrow>
                <InputLabel id="new-cert-mark-select-label">Marker Role</InputLabel>
            </Tooltip>
            <Select sx={{minWidth: "100%"}} id="new-cert-mark-select" value={markerRole} label="Marker Role" onChange={(event) => {
                setMarkerRole(event.target.value as number)
            }}>
                {props.divisionRoles.map((role) =>
                    <MenuItem key={role.id} value={role.roleId}>{role.displayName}</MenuItem>
                )}
            </Select>
            <Stack spacing={1}>
                <Input multiline rows={4} placeholder="Application Description..."
                       onChange={(event) => setDescription(event.target.value)}/>
                <Button variant="contained" onClick={() => {
                    createNewApplication(description, newRoleApplicationRankRequirements, markerRole, certification)
                }}>
                    Create new application
                </Button>
            </Stack>
        </Paper>
        <ApplicationRequirements
            requirementTable={newRoleApplicationRankRequirements}
            ranks={props.ranks}
            certifications={props.certifications}
            divisionRoles={props.divisionRoles}
            setAlert={setAlert}
            requirementsToInclude = {allRequirements.filter(requirement =>
                !requirement.shouldUseRanks && !requirement.shouldUseDivisions
            )}
        />
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </>
}

export const NewCertificationApplicationPanel = () => {
    const params = useParams()

    const loadCertifications = useApiCall<CertificationResponse[]>({
        url: `/api/certifications/division/${divisionIdentifierUrlPrettifier(params.divisionIdentifier ?? "", false)}`
    })

    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: "/api/rank/subordinates"
    })

    const loadDivisionRoles = useApiCall<DivisionRoles[]>({
        initialUrl: `/api/division/roles/${divisionIdentifierUrlPrettifier(params.divisionIdentifier ?? "", false)}`
    })


    return <LoadingContent isLoading={
        loadRanks.isLoading || !loadRanks.data ||
        loadDivisionRoles.isLoading || !loadDivisionRoles.data ||
        loadCertifications.isLoading || !loadCertifications.data
    }>
        <NewCertificationApplicationPanelContent
            ranks={loadRanks.data!!}
            divisionRoles={loadDivisionRoles.data!!}
            certifications={loadCertifications.data!!}
        />
    </LoadingContent>
}