import {Box, CircularProgress, Container, ContainerProps, Toolbar} from "@mui/material";
import useUser from "../hooks/useUser";
import React, {useState} from "react";
import Sidebar from "../components/Sidebar";
import {NavBar} from "../components/NavBar";
import {MiniProfilePopper} from "../components/profile/MiniProfile";

interface PageTemplateProps {
    children?: React.ReactNode
    containerProps?: ContainerProps
}

const drawerWidth = 240;

// Template for a page using the sidebar
export const PageTemplate: React.FC<PageTemplateProps> = (props: PageTemplateProps) => {
    const userData = useUser()
    const [sidebarOpen, setSidebarOpen] = useState(false)

    let content;
    if (userData.isLoading || !userData.user) {
        content = <CircularProgress/>
    } else {
        content = <Box sx={{display: 'flex'}}>
            <NavBar drawerWidth={drawerWidth} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>

            <Box
                component="nav"
                sx={{width: {md: drawerWidth}, flexShrink: {md: 0}}}
            >
                <Sidebar user={userData.user} sidebarWidth={drawerWidth} sidebarOpen={sidebarOpen}
                         setSidebarOpen={setSidebarOpen}/>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, mt: 2, p: 1, width: {md: `calc(100% - ${drawerWidth}px)`, maxWidth: "100%"}}}
            >
                <Toolbar/>
                <Container id="mainContainer" maxWidth={false} sx={{overflow: "auto", p: 1}} {...props.containerProps}>
                    {props.children}
                </Container>
            </Box>
        </Box>
    }

    return <>
        <MiniProfilePopper/>
        {content}
        </>
}