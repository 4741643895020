interface InternalAffairsComplaintClassificationInter {
    key: string
    displayName: string
}

export const internalAffairsComplaintClassification: InternalAffairsComplaintClassificationInter[] = [
    { key: "discourtesy", displayName: "Discourtesy" },
    { key: "traffic", displayName: "Traffic Offense" },
    { key: "policy", displayName: "Breach of Policy" },
    { key: "unlawful_charge", displayName: "Unlawful Charge" },
    { key: "unlawful_entry", displayName: "Unlawful Entry" },
    { key: "unlawful_search", displayName: "Unlawful Search" },
    { key: "insubordination", displayName: "Insubordination" },
    { key: "failure_to_report", displayName: "Failure to Report Misconduct" },
    { key: "dishonesty", displayName: "Dishonesty" },
    { key: "use_of_force", displayName: "Use of Force Violation" },
    { key: "shooting_violation", displayName: "Shooting Violation" },
    { key: "criminal_activity", displayName: "Criminal Activity" },
    { key: "duty_of_care", displayName: "Duty of Care" },
    { key: "pending", displayName: "Pending" },
]

export const InternalAffairsComplaintClassification: Map<string, InternalAffairsComplaintClassificationInter> = new Map(internalAffairsComplaintClassification.map(i => [i.key, i]))