import {
    Alert,
    Button,
    FormControl,
    Input,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import useUser from "../hooks/useUser";
import {AccountResponse} from "../data/AccountResponse";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {useNavigate} from "react-router-dom";
import {LoadingContent} from "../components/LoadingContent";
import {AboutMeResponse} from "../data/AboutMeResponse";
import {AlertType} from "../data/AlertType";

export const EditProfilePage = () => {
    const userData = useUser()
    const user = userData.user
    const aboutMe = useApiCall<AboutMeResponse>({
        initialUrl: "/api/user/-/about-me"
    })
    return <LoadingContent isLoading={userData.isLoading || !user || aboutMe.isLoading}>
                <EditProfilePageContent user={user!} aboutMe={aboutMe.data} refresh={userData.refresh}/>
        </LoadingContent>
}

interface EditProfilePageProps {
    user: AccountResponse
    aboutMe: AboutMeResponse | null
    refresh: () => void
}

const supportedPronouns = ["None", "He/Him", "She/Her", "They/Them"]

const EditProfilePageContent = (props: EditProfilePageProps) => {
    const navigate = useNavigate()
    const [aboutMe, setAboutMe] = useState(props.aboutMe?.text ?? "")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [pronouns, setPronouns] = useState(props.user.pronouns ?? "None")

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const cancelOldApiCall = useCallback(() => {
        apiCall?.cancel()
    }, [apiCall])

    useEffect(() => {
        return cancelOldApiCall
    }, [cancelOldApiCall])

    const updateProfile = (pronouns: string, aboutMe: string) => {
        cancelOldApiCall()
        setApiCall(makeApiCall({
            url: `/api/user/-/profile`,
            method: "PUT",
            body: {pronouns: pronouns, aboutMe: aboutMe},
            redirectOnUnauthorized: navigate,
            onLoadedCallback: () => {
                setAlert("success", "Updated profile")
                props.refresh()
                navigate(`/user/${props.user.communityId}`)
            },
            onError: () => {
                setAlert("error", "An error occurred updating profile")
            }
        }))
    }

    return (
    <Stack spacing={1}>
        <Typography variant="h6">Edit Profile</Typography>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Typography variant="subtitle1">Pronouns</Typography>

            <FormControl fullWidth>
                <Select defaultValue={pronouns} id="pronouns-select" value={pronouns} onChange={(event) => {
                    setPronouns(event.target.value)
                }}>
                    {supportedPronouns.map((p) =>
                        <MenuItem key={p} value={p}>{p}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Paper>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Typography variant="subtitle1">About Me</Typography>
            <Input fullWidth multiline rows={4} placeholder="Tell us about yourself..." value={aboutMe}
                   onChange={(event) => setAboutMe(event.target.value)}/>
            <Button sx={{"margin-top": "15px", "width": "100%"}} color={"success"} variant="outlined" onClick={() => updateProfile(pronouns, aboutMe)}>
                Update Profile
            </Button>
        </Paper>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Stack>
    )
}