import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React from "react";
import {RecordNoteResponse} from "../../data/RecordNoteResponse";
import {Box, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {RecordNoteRow} from "../record/EmployeeInfractions";

interface EmployeeRecordsContentProps {
    records: RecordNoteResponse[]
}

function EmployeeRecordsContent(props: EmployeeRecordsContentProps) {
    return <Stack spacing={4}>
        <Box sx={{overflowX: "auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type of Note</TableCell>
                        <TableCell>Date of Note</TableCell>
                        <TableCell>Reason for Note</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.records?.map((record) => {
                        return <RecordNoteRow showSupervisor={false} key={record.id} note={record}/>
                    })}
                </TableBody>
            </Table>
        </Box>
    </Stack>


}

export const EmployeeRecords = () => {
    const loadRecords = useApiCall<RecordNoteResponse[]>({
        initialUrl: `/api/records/me`
    })

    return (
        <LoadingContent isLoading={loadRecords.isLoading}>
            <EmployeeRecordsContent records={loadRecords.data!!}/>
        </LoadingContent>
    )
}