import {AssessmentInfoResponse} from "../../data/assessment/AssessmentInfoResponse";
import React, {useRef, useState} from "react";
import {LexicalEditor} from "lexical";
import {clamp} from "../../utils";
import {NUM_QUESTIONS_MAX, NUM_QUESTIONS_MIN, PASSING_MAX, PASSING_MIN} from "../../data/assessment/AssessmentConstants";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Input,
    Slider, Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {TextEditor} from "../editor/TextEditor";
import {$generateHtmlFromNodes} from '@lexical/html';
import {EditorContent} from "../editor/EditorContent";

interface AssessmentEditorProps {
    editing?: boolean
    assessment?: AssessmentInfoResponse
    handleSubmit: (assessment: AssessmentInfoResponse) => void
}

const sliderMarks = [
    {
        value: 30,
        label: "30%"
    },
    {
        value: 50,
        label: "50%"
    },
    {
        value: 70,
        label: "70%"
    },
    {
        value: 100,
        label: "100%"
    }
]

export const AssessmentEditor = ({editing, assessment, handleSubmit}: AssessmentEditorProps) => {
    const [title, setTitle] = useState(assessment?.title ?? "")
    const [passingScore, _setPassingScore] = useState(assessment?.passingScore ?? 70)
    const [numQuestions, _setNumQuestions] = useState(assessment?.numQuestions ?? 0)
    const [randomizeQuestions, _setRandomizeQuestions] = useState(assessment?.randomizeQuestions ?? false)
    const [isPractical, _setIsPractical] = useState(assessment?.isPractical ?? false)
    const editorRef = useRef<LexicalEditor>(null)
    const [titleError, setTitleError] = useState("")

    const validateTitle = (title: string) => {
        if (title.length > 200) {
            setTitleError("Title too long")
            return false
        } else if (title.trim().length < 1) {
            setTitleError("Title too short")
            return false
        }
        setTitleError("")
        return true
    }

    const submit = (title: string, description: string, passingScore: number, numQuestions: number, randomizeQuestions: boolean, isPractical: boolean) => {
        if (!validateTitle(title)) {
            return
        }
        const questions = numQuestions < 1 ? null : numQuestions
        handleSubmit({
            id: assessment?.id ?? -1,
            title: title,
            description: description,
            passingScore: passingScore,
            numQuestions: !randomizeQuestions || isPractical ? null : questions,
            randomizeQuestions: isPractical ? false : randomizeQuestions,
            isPractical: isPractical,
            isPublished: false,
            questionPoolSize: -1,
            openUserAssessmentsCount: 0
        })
    }

    const setPassingScore = (score: number) => _setPassingScore(clamp(score, PASSING_MIN, PASSING_MAX))

    const setRandomizeQuestions = (randomize: boolean) => {
        setNumQuestions(0)
        _setRandomizeQuestions(randomize)
    }

    const setNumQuestions = (value: number) => {
        _setNumQuestions(clamp(value, NUM_QUESTIONS_MIN, NUM_QUESTIONS_MAX))
    }

    const setIsPractical = (value: boolean) => {
        if (editing) {
            return
        }
        if (value) {
            setRandomizeQuestions(false)
        } else {
            setRandomizeQuestions(true)
        }
        _setIsPractical(value)
    }

    return <Stack spacing={2} alignItems="start">
        <Typography variant="h6">{editing ? "Edit" : "Create New"} Assessment</Typography>
        <Typography variant="h5" gutterBottom>Title</Typography>
        <TextField variant="standard" error={titleError.length > 0} helperText={titleError} value={title}
                   disabled={assessment?.isPublished}
                   onChange={(event) => {
                       setTitle(event.target.value)
                       validateTitle(event.target.value)
                   }}/>
        <Tooltip title={"The description is displayed to the user before they begin the assessment"}>
            <Typography variant="h5" gutterBottom>Description</Typography>
        </Tooltip>
        {assessment?.isPublished ?
            <EditorContent content={assessment.description ?? ""}/> :
            <TextEditor ref={editorRef} initialContent={assessment?.description}/>
        }
        <Tooltip title={"Minimum grade require to pass the assessment"}>
            <Typography variant="h5" gutterBottom>Passing Grade</Typography>
        </Tooltip>
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start" width="100%"
              maxWidth="500px">
            <Grid item xs sx={{marginRight: "16px"}}>
                <Slider min={PASSING_MIN} max={PASSING_MAX} defaultValue={70} valueLabelDisplay="auto"
                        disabled={assessment?.isPublished}
                        value={passingScore}
                        marks={sliderMarks}
                        valueLabelFormat={(value) => `${value}%`}
                        onChange={(_, value) => setPassingScore(value as number)}/>
            </Grid>
            <Grid item>
                <Input value={passingScore} size="small" sx={{width: "8ch"}} disabled={assessment?.isPublished}
                       onChange={(event) => {
                           setPassingScore(event.target.value === "" ? 0 : Number(event.target.value))
                       }}
                       onBlur={() => setPassingScore(passingScore)}
                       inputProps={{
                           step: 1,
                           min: PASSING_MIN,
                           max: PASSING_MAX,
                           type: "number"
                       }}/>
            </Grid>
        </Grid>
        {isPractical ? <></> :
            <Tooltip title={"Randomly order the questions of the assessment"}>
                <FormControlLabel label={"Randomize Questions"} control={
                    <Checkbox onChange={(_, checked) => setRandomizeQuestions(checked)}
                              checked={randomizeQuestions} disabled={assessment?.isPublished}/>
                }/>
            </Tooltip>
        }
        {!randomizeQuestions || isPractical ? <></> :
            <Stack direction="row" spacing={2}>
                <Tooltip title={"Maximum number of questions to be picked from the question pool"}>
                    <Typography># of Questions</Typography>
                </Tooltip>
                <Input value={numQuestions} size="small"
                       disabled={assessment?.isPublished}
                       onChange={(event) => {
                           setNumQuestions(event.target.value === "" ? 0 : Number(event.target.value))
                       }}
                       onBlur={() => setNumQuestions(numQuestions)}
                       inputProps={{
                           step: 1,
                           min: 0,
                           max: NUM_QUESTIONS_MAX,
                           type: "number"
                       }}/>
            </Stack>
        }
        {!editing || assessment?.isPractical ?
            <Tooltip
                title={"Practical assessments are only graded, the user does not fill out the assessment (e.g. practical assessments conducted in-game)"}>
                <FormControlLabel label={"Practical Assessment"} control={
                    <Checkbox disabled={editing || assessment?.isPublished} onChange={(_, checked) => setIsPractical(checked)}
                              checked={isPractical}/>
                }/>
            </Tooltip> :
            <></>
        }

        <Button variant="contained" disabled={assessment?.isPublished} onClick={() => {
            if (editorRef.current) {
                editorRef.current.getEditorState().read(() => {
                    let description = $generateHtmlFromNodes(editorRef.current!)
                    submit(title.trim(), description, passingScore, numQuestions, randomizeQuestions, isPractical)
                })
            }
        }}>
            {editing ? "Update" : "Create"} Assessment
        </Button>
    </Stack>
}