import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DialogActions, DialogContent} from "@mui/material";

interface DeleteResourcePromptProps {
    id: number,
    divisionIdentifier: string,
}

export default function DeleteResourcePrompt(props: DeleteResourcePromptProps) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <DeleteForeverIcon color={"error"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>Delete Resource (This CANNOT be undone)</DialogContent>
                <DialogActions>
                    <Button color={"success"} onClick={handleClose}>Cancel</Button>
                    <Button color={"error"} onClick={() =>
                        makeApiCall({
                            url: `/api/resource/${props.divisionIdentifier}/${props.id}`,
                            method: "DELETE",
                            onLoadedCallback: () => {
                                navigate(`/resources`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Delete Resource</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}