interface InternalAffairsComplaintOutcomeInter {
    key: string
    displayName: string
}

export const internalAffairsComplaintOutcome: InternalAffairsComplaintOutcomeInter[] = [
    { key: "exonerated", displayName: "Exonerated" },
    { key: "unfounded", displayName: "Unfounded" },
    { key: "not_sustained", displayName: "Not Sustained" },
    { key: "sustained", displayName: "Sustained" },
    { key: "withdrawn", displayName: "Withdrawn" },
    { key: "pending", displayName: "Pending" },
]

export const InternalAffairsComplaintOutcome: Map<string, InternalAffairsComplaintOutcomeInter> = new Map(internalAffairsComplaintOutcome.map(i => [i.key, i]))