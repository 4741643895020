import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React from "react";
import {ProfileResponse} from "../../data/ProfileResponse";
import {InternalAffairsLimitedComplaintResponse} from "../../data/internal-affairs/InternalAffairsLimitedComplaintResponse";
import {ComplaintTable} from "../../pages/internal-affairs/InternalAffairs";

interface EmployeeComplaintsProps {
    employee: ProfileResponse
}

interface EmployeeComplaintsContentProps {
    complaints: InternalAffairsLimitedComplaintResponse[] | null
}

function EmployeeComplaintsContent(props: EmployeeComplaintsContentProps) {
    return <ComplaintTable header={""} complaints={props.complaints}/>
}

export const EmployeeComplaints = (props: EmployeeComplaintsProps) => {
    const loadComplaints = useApiCall<InternalAffairsLimitedComplaintResponse[]>({
        initialUrl: `/api/records/complaints/${props.employee.communityId}`
    })

    return (
        <LoadingContent isLoading={loadComplaints.isLoading}>
            <EmployeeComplaintsContent complaints={loadComplaints.data}/>
        </LoadingContent>
    )
}