import React, {useState} from "react";
import {Box, Container, Paper, Tab, Tabs} from "@mui/material";

interface TosTabProps {
    index: number;
    value: number;
    src: string
}

const ToSTab = (props: TosTabProps) => {
    const { value, index, src } = props;
    return (
        <div role="tabpanel" hidden={value !== index} style={{width: "100%", height: "76vh"}}>
            <iframe title="ToS" src={src} style={{width: "100%", height: "100%"}}></iframe>
        </div>
    );
}

export const ToSPage = () => {
    let [value, setValue] = useState(0)

    return (
        <Container maxWidth="lg">
            <Paper style={{padding: 32, marginBottom: "auto", marginTop: 10, height: "100%", minHeight: "80vh"}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 2, height: "100%"}}>
                    <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
                        <Tab label="Terms of Service"/>
                        <Tab label="Privacy Policy"/>
                        <Tab label="Cookies Policy"/>
                    </Tabs>
                </Box>
                <ToSTab index={0} value={value} src={"https://docs.google.com/document/d/e/2PACX-1vQAULemgTkvf9xcFzInswG_7lUGze6CitOr1WuaBKt36agzZL0W-z0xD97bSKdKMwgaz_k82FjlX8ig/pub?embedded=true"}/>
                <ToSTab index={1} value={value} src={"https://docs.google.com/document/d/1TQbP2JxpJpQRPlESlZsDaqt6Yav0WwyPXfOsOD0NqWw/pub?embedded=true"}/>
                <ToSTab index={2} value={value} src={"https://docs.google.com/document/d/e/2PACX-1vRSULdWxrY94wNvlj24LVBHGkq9s95odwaTWhUYfmlr6_YNwfYdcel_NthorQyEn51p5nrfFMyXjZAw/pub?embedded=true"}/>
            </Paper>
        </Container>
    )
}