import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {UserAssessmentResponse} from "../data/UserAssessmentResponse";
import {LoadingContent} from "../components/LoadingContent";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle,
    Grid, Link,
    Paper,
    Snackbar,
    Stack,
} from "@mui/material";
import {EditorContent} from "../components/editor/EditorContent";
import {clamp, parseIntOrNull} from "../utils";
import {UserAssessmentQuestionResponse} from "../data/UserAssessmentQuestionResponse";
import {UserAssessmentQuestion} from "../components/assessment/UserAssessmentQuestion";
import {QuestionList} from "../components/assessment/QuestionList";
import {UserAssessmentUserAnswerResponse} from "../data/UserAssessmentAnswerRespoonse";
import {AlertType} from "../data/AlertType";
import {UserAssessmentQuestionInfoResponse} from "../data/UserAssessmentQuestionInfoResponse";

export const UserAssessmentPage = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [questionNumber, _setQuestionNumber] = useState(parseIntOrNull(searchParams.get("question") ?? "") ?? undefined)
    const userAssessmentData = useApiCall<UserAssessmentResponse>({
        url: `/api/careers/${params.accesstype}/${params.id}/${params.type}`
    })

    const setQuestionNumber = (questionNumber?: number) => {
        if (questionNumber === undefined) {
            searchParams.delete("question")
        } else {
            searchParams.set("question", questionNumber.toString())
        }
        _setQuestionNumber(questionNumber)
        setSearchParams(searchParams)
    }

    return <LoadingContent isLoading={userAssessmentData.isLoading || !userAssessmentData.data}>
        <UserAssessmentPageContent assessment={userAssessmentData.data!} questionNumber={questionNumber}
                                   setQuestionNumber={setQuestionNumber}/>
    </LoadingContent>
}

interface UserAssessmentPageProps {
    assessment: UserAssessmentResponse
    questionNumber?: number
    setQuestionNumber: (questionNumber?: number) => void
}

const UserAssessmentPageContent = (props: UserAssessmentPageProps) => {
    const [questionInfo, setQuestionInfo] = useState(props.assessment.questions)
    const [question, setQuestion] = useState<UserAssessmentQuestionResponse>()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [questionApiCall, setQuestionApiCall] = useState<ApiCallResponseData>()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [showSubmitDialog, setShowSubmitDialog] = useState(false)
    const navigate = useNavigate()
    const allAnswered = questionInfo.every(q => q.isAnswered)

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    useEffect(() => {
        return () => questionApiCall?.cancel()
    }, [questionApiCall]);

    useEffect(() => {
        if (props.questionNumber !== undefined) {
            const questionId = props.assessment.questions[(props.questionNumber ?? 1) - 1]?.id
            if (questionId !== undefined) {
                setQuestionApiCall(makeApiCall({
                    url: `/api/careers/user/${props.assessment.id}/question/${questionId}`,
                    onLoadedCallback: (data: UserAssessmentQuestionResponse) => setQuestion(data),
                    onError: () => {
                    }
                }))
            }
        } else {
            setQuestion(undefined)
        }

    }, [props.assessment, props.questionNumber, setApiCall, setQuestion]);

    const doSaveAnswers = useCallback((question: UserAssessmentQuestionResponse,
                                       answers: UserAssessmentUserAnswerResponse[],
                                       alert?: boolean) => {
        setApiCall(makeApiCall({
            url: `/api/careers/user/${props.assessment.id}/question/${question.id}`,
            method: "POST",
            body: {
                answers: answers
            },
            onLoadedCallback: (data: UserAssessmentQuestionInfoResponse) => {
                if (alert) {
                    setAlert("success", `Saved answer for Question ${question.order}`)
                }
                const newQuestionInfo = questionInfo.map(_ => _)
                newQuestionInfo[data.order - 1] = data
                setQuestionInfo(newQuestionInfo)
            },
            onError: () => {
                setAlert("error", `Failed to save answer for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, questionInfo, setAlert, setApiCall])

    const incrementQuestionNumber = (x: number) => {
        props.setQuestionNumber(clamp((question?.order ?? 0) + x, 1, props.assessment.questions.length))
    }

    const trySubmit = () => {
        if (allAnswered) {
            doSubmit()
        } else {
            setShowSubmitDialog(true)
        }
    }

    const doSubmit = () => {
        setShowSubmitDialog(false)
        if (props.assessment.submitted) {
            return
        }
        setApiCall(makeApiCall({
            url: `/api/careers/user/${props.assessment.id}/submit`,
            method: "POST",
            onLoadedCallback: () => navigate("/careers"),
            onError: () => setAlert("error", "Failed to submit assessment"),
        }))
    }

    return <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
                <Stack spacing={2}>
                    <Link component="button" variant="h4" underline="hover" color="inherit"
                          sx={{textAlign: "inherit"}} onClick={() => {
                        props.setQuestionNumber(undefined)
                    }}>{props.assessment.title}</Link>
                    {question !== undefined ? <>
                            <UserAssessmentQuestion key={question.order} assessment={props.assessment} question={question}
                                                    doSaveAnswers={doSaveAnswers}/>
                            <Stack direction="row" spacing={2}>
                                <Button disabled={question.order <= 1} variant="contained"
                                        onClick={() => incrementQuestionNumber(-1)}>Previous
                                    Question</Button>
                                <Button disabled={question.order >= questionInfo.length} variant="contained"
                                        onClick={() => incrementQuestionNumber(1)}>Next
                                    Question</Button>
                            </Stack>
                        </> :
                        <>
                            <EditorContent content={props.assessment.description}/>
                            <Button variant="contained"
                                    onClick={() => props.setQuestionNumber(questionInfo[0].order)}>Continue</Button>
                        </>
                    }

                </Stack>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Paper variant="outlined" sx={{padding: "16px"}}>
                    <Stack spacing={2}>
                        <QuestionList questions={questionInfo} selectedQuestion={(question?.id)}
                                      submitted={props.assessment.submitted}
                                      onClick={question => props.setQuestionNumber(question.order)}/>
                        {props.assessment.submitted ? <></> :
                            <Button variant="contained" color={allAnswered ? "success" : "info"} onClick={trySubmit}>
                                Submit
                            </Button>
                        }
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
        <Dialog open={showSubmitDialog}>
            <DialogTitle>Submit Assessment</DialogTitle>
            <DialogContent>
                You have not answered all of the questions. Are you sure you want to submit this assessment?
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowSubmitDialog(false)}>Cancel</Button>
                <Button onClick={doSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}