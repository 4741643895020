import {Link, useNavigate, useParams} from "react-router-dom";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {AssessmentInfoResponse} from "../data/assessment/AssessmentInfoResponse";
import {AssessmentQuestionInfoResponse} from "../data/assessment/AssessmentQuestionInfoResponse";
import {AssessmentQuestionResponse} from "../data/assessment/AssessmentQuestionResponse";
import {LoadingContent} from "../components/LoadingContent";
import {Alert, Button, Grid, Paper, Snackbar, Stack,} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {AlertType} from "../data/AlertType";
import {QuestionList} from "../components/assessment/QuestionList";
import {QuestionEditor} from "../components/assessment/QuestionEditor";
import {AssessmentQuestionRequest} from "../data/assessment/AssessmentQuestionRequest";

export const EditAssessmentQuestionPage = () => {
    const params = useParams()
    const assessmentData = useApiCall<AssessmentInfoResponse>({
        url: `/api/careers/assessments/${params.id}`
    })
    const questionsData = useApiCall<AssessmentQuestionInfoResponse[]>({
        url: `/api/careers/assessments/${params.id}/questions`
    })
    const questionData = useApiCall<AssessmentQuestionResponse>({
        url: `/api/careers/assessments/${params.id}/question/${params.questionId}`
    })

    return <LoadingContent
        isLoading={assessmentData.isLoading || !assessmentData.data ||
            questionsData.isLoading || !questionsData.data ||
            questionData.isLoading || !questionData.data}>
        <EditAssessmentQuestionPageContent assessment={assessmentData.data!} questions={questionsData.data!}
                                           question={questionData.data!}/>
    </LoadingContent>
}

interface EditAssessmentQuestionPageProps {
    assessment: AssessmentInfoResponse
    questions: AssessmentQuestionInfoResponse[]
    question: AssessmentQuestionResponse
}

const EditAssessmentQuestionPageContent = (props: EditAssessmentQuestionPageProps) => {
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    const editQuestion = (question: AssessmentQuestionRequest) => {
        setApiCall(makeApiCall({
            url: `/api/careers/${props.assessment.id}/question/${props.question.id}`,
            method: "PATCH",
            body: question,
            onLoadedCallback: _ => {
                navigate(`/assessments/${props.assessment.id}/edit`)
            },
            onError: () => {
                setAlert("error", "Failed to update question")
            }
        }))
    }

    const deleteQuestion = () => {
        setApiCall(makeApiCall({
            url: `/api/assessments/${props.assessment.id}/question/${props.question.id}`,
            method: "DELETE",
            onLoadedCallback: _ => {
                navigate(`/assessments/${props.assessment.id}/edit`)
            },
            onError: () => {
                setAlert("error", "Failed to delete question")
            }
        }))
    }

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    return <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={9}>
                <QuestionEditor assessment={props.assessment} handleSubmit={editQuestion} setAlert={setAlert}
                                question={props.question} handleDeleteQuestion={deleteQuestion} editing/>
            </Grid>
            <Grid item lg={3} xs={12}>
                <Paper variant="outlined" sx={{padding: "16px"}}>
                    <Stack spacing={2}>
                        <Button disabled={props.assessment.isPublished} variant="contained" component={Link}
                                to={`/assessments/${props.assessment.id}/edit/question/new`}>Add Question</Button>
                        <QuestionList questions={props.questions} selectedQuestion={props.question.id}
                                      linkUrlBuilder={question =>
                                          `/assessments/${props.assessment.id}/edit/question/${question.id}`}/>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}}
                   variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}