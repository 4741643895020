import useApiCall, {makeApiCall, UseApiCallResponseData} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React, {useState} from "react";
import {Box, IconButton, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import GavelIcon from '@mui/icons-material/Gavel';
import {DivisionBan} from "../../data/DivisionBan";
import {formatDate} from "../../utils";
import IssueBanForm from "./IssueBanForm";
import {ConfirmationDialog} from "../ConfirmationDialog";

interface BanListProps {
    divisionIdentifier: string
}

export interface BanListRowProps extends BanListProps {
    ban: DivisionBan
    apiCall: UseApiCallResponseData<DivisionBan[]>
}

const BanListRow = (props: BanListRowProps) => {
    const [openConfirm, setOpenConfirm] = useState(false)

    const unban = (confirm: boolean) => {
        setOpenConfirm(false)
        if (confirm) {
            makeApiCall({
                url: `/api/division/${props.divisionIdentifier}/ban`,
                method: "PATCH",
                body: {
                    banId: props.ban.id,
                    banned: true
                },
                onLoadedCallback: () => {
                    props.apiCall.refresh()
                },
                onError: () => {}
            })
        }
    }

    let banTime
    if (props.ban.isPermanent && !props.ban.unbanned) {
        banTime = <TableCell sx={{backgroundColor: "darkred", color: "white"}}>Permanent</TableCell>
    } else if (!props.ban.expired && !props.ban.unbanned) {
        banTime = <TableCell sx={{backgroundColor: "darkgoldenrod", color: "white"}}>{formatDate(props.ban.endDate)}</TableCell>
    } else if (props.ban.unbanned) {
        banTime = <TableCell sx={{backgroundColor: "darkgreen", color: "white"}}>{formatDate(props.ban.endDate)} (Unbanned)</TableCell>
    } else {
        banTime = <TableCell sx={{backgroundColor: "darkgreen", color: "white"}}>{formatDate(props.ban.endDate)} (Expired)</TableCell>
    }

    return <TableRow>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.ban.supervisorCommunityId}`}>
                {props.ban.supervisorNick}
            </Link>
        </TableCell>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.ban.employeeCommunityId}`}>
                {props.ban.employeeNick}
            </Link>
        </TableCell>
        <TableCell>{props.ban.reason}</TableCell>
        <TableCell>
            {!props.ban.unbanned && !props.ban.expired ? <Tooltip title={"Unban"}>
                <IconButton size="small" color="success" onClick={() =>
                    setOpenConfirm(true)
                }>
                    <GavelIcon color={"success"} fontSize="small" />
                </IconButton>
            </Tooltip> : <></> }
        </TableCell>
        {banTime}
        <ConfirmationDialog title={"Unban User"} description={"This will unban the user, this is not undoable"} open={openConfirm} onClose={unban} />
    </TableRow>
}

export const BanList = (props: BanListProps) => {
    const loadBans = useApiCall<DivisionBan[]>({
        initialUrl: `/api/division/${props.divisionIdentifier}/ban`
    })

    return <Stack spacing={4}>
            <IssueBanForm apiCall={loadBans} divisionIdentifier={props.divisionIdentifier} />
            <Box sx={{overflowX: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Issuer</TableCell>
                            <TableCell>Officer</TableCell>
                            <TableCell>Reason</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>End Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingContent showLoadingSkeleton={true} isLoading={loadBans.isLoading || !loadBans.data}>
                            {loadBans.data && loadBans.data.map((ban) => {
                                return <BanListRow key={ban.id} apiCall={loadBans} ban={ban} divisionIdentifier={props.divisionIdentifier}/>
                            })}
                        </LoadingContent>
                    </TableBody>
                </Table>
            </Box>
        </Stack>
}