import useApiCall, {makeApiCall, UseApiCallResponseData} from "../hooks/CancellableApiCall";
import {HelpdeskTicketResponse} from "../data/helpdesk/HelpdeskTicketResponse";
import useUser, {UserContextProps} from "../hooks/useUser";
import {LoadingContent} from "../components/LoadingContent";
import {
    Alert,
    Avatar,
    Button,
    Divider,
    Grid,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {formatTimestamp} from "../utils";
import {MessageBoxWithUserDetails} from "../components/MessageBoxWithUserDetails";
import {TextEditor} from "../components/editor/TextEditor";
import {$getRoot, LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import {Division} from "../data/Division";
import {HelpdeskAssigneeResponse} from "../data/helpdesk/HelpdeskAssigneeResponse";
import {DivisionRoles} from "../data/DivisionRoles";
import {helpdeskTicketStatus} from "../data/helpdesk/HelpdeskTicketStatus";
import {AlertType} from "../data/AlertType";
import {HelpdeskActivityResponse} from "../data/helpdesk/HelpdeskActivityResponse";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    timelineItemClasses,
    TimelineOppositeContent,
    TimelineSeparator
} from '@mui/lab';

interface HelpdeskTicketPageContentProps {
    ticket: HelpdeskTicketResponse
    user: UserContextProps
    divisions: Division[]
    userRoleInDivision: String
    ticketApiCall: UseApiCallResponseData<HelpdeskTicketResponse>
}

function HelpdeskTicketEvent(props: {data: HelpdeskActivityResponse}) {
    return <TimelineItem>
            <TimelineSeparator>
                <Avatar
                    src={props.data.avatar}
                />
                <TimelineConnector
                    sx={{
                        backgroundColor: "rgba(182,182,192,0.45)"
                    }}
                />
            </TimelineSeparator>
            <TimelineContent sx={{px: 2, mt: "-7px", mb: "40px"}}>
                <Stack direction="column">
                    <Typography variant="body1" component="span">
                        {props.data.nick}
                    </Typography>
                </Stack>
                <Stack direction="column">
                    <Typography variant="body1" component="span">
                        {props.data.message}
                    </Typography>
                </Stack>
            </TimelineContent>
            <TimelineOppositeContent>
                <Stack sx={{pt: 1}} direction="row">
                    <Typography color={"secondary"} variant="caption">
                        {formatTimestamp(props.data.date)}
                    </Typography>
                </Stack>
            </TimelineOppositeContent>
        </TimelineItem>
}

function HelpdeskTicketPageContent(props: HelpdeskTicketPageContentProps) {
    const editorRef = useRef<LexicalEditor>(null)
    const [ticketMessages, setTicketMessages] = useState(props.ticket.messages)
    const [ticketMessageCount, setTicketMessageCount] = useState(props.ticket.messages.length)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [status, setStatus] = useState(props.ticket.status)
    const [assignee, setAssignee] = useState(props.ticket.assigneeCommunityId ?? "none")

    const possibleAssignees = useApiCall<HelpdeskAssigneeResponse[]>({
        url: `/api/helpdesk/${props.ticket.divisionIdentifier}/possible-assignees`
    })

    let userRoleInDivision = ''
    if (props.userRoleInDivision !== "") {
        userRoleInDivision = `<p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">${props.userRoleInDivision}</span></p>`
    }

    const initialContent = `<p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">Hello,</span></p><p class="editor-paragraph" style="text-align: start;"><br></p><p class="editor-paragraph"><br></p><p class="editor-paragraph"><br></p><p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">Kind Regards,</span></p><p class="editor-paragraph" dir="ltr" style="text-align: start;"><span style="white-space: pre-wrap;">${props.user.user?.rank?.displayName} ${props.user.user?.rpName}</span></p>${userRoleInDivision}`

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const updateStatus = (id: number, divisionIdentifier: string, status: string) => {
        makeApiCall({
            url: `/api/helpdesk/ticket/${id}/status`,
            method: "PATCH",
            body: {
                status: status
            },
            onLoadedCallback: () => {
                props.ticketApiCall.refresh()
            },
            onError: () => {
                setAlert("error", "Status Failed to Update")
            }
        })
    }

    const updateAssignee = (id: number, assigneeCommunityId: string) => {
        makeApiCall({
            url: `/api/helpdesk/ticket/${id}/assign`,
            method: "PATCH",
            body: {
                param: assigneeCommunityId
            },
            onLoadedCallback: () => {
                props.ticketApiCall.refresh()
            },
            onError: () => {
                setAlert("error", "Assignee Failed to Update")
            }
        })
    }

    const updateDivision = (id: number, divisionIdentifier: string, newDivisionIdentifier: string) => {
        makeApiCall({
            url: `/api/helpdesk/ticket/${id}/division`,
            method: "PATCH",
            body: {
                param: newDivisionIdentifier
            },
            onLoadedCallback: () => {
                props.ticketApiCall.refresh()
            },
            onError: () => {
                setAlert("error", "Division Failed to Update")
            }
        })
    }

    return <Stack spacing={1}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={8}>
                <Stack spacing={1}>
                    {status === "closed" ?
                        <Alert severity="info">This ticket has been closed, and can no longer be replied to</Alert> : <></>}
                    {status !== "closed" && assignee === props.user.user?.communityId ?
                        <Alert severity="warning">Please ensure that you close the ticket once the matter has been resolved</Alert> : <></>}
                    {ticketMessages?.map(message => {
                        return <MessageBoxWithUserDetails
                            key={message.id}
                            authorCommunityId={message.creatorCommunityId}
                            authorNick={message.creatorNick}
                            authorRpName={message.creatorRpName}
                            authorRankDisplayName={message.creatorRankDisplayName}
                            authorRankColor={message.creatorRankColor}
                            date={message.date}
                            content={message.content}
                        />
                    })}
                </Stack>
                {status !== "closed" ? <>
                    <Paper variant={"outlined"} style={{
                        marginTop: "16px",
                        padding: "16px"
                    }}>
                        <TextEditor ref={editorRef} initialContent={initialContent}/>
                        <Button color="info" onClick={() => {
                            if (editorRef.current) {
                                editorRef.current.getEditorState().read(() => {
                                    let content = $generateHtmlFromNodes(editorRef.current!)

                                    editorRef.current?.update(() => {
                                        $getRoot().clear();
                                    })

                                    makeApiCall({
                                        url: `/api/helpdesk/message`,
                                        method: "POST",
                                        body: {
                                            ticketId: props.ticket.id,
                                            content: content
                                        },
                                        onLoadedCallback: () => {
                                            setAlert("success", "Message Sent")
                                            setTicketMessageCount(ticketMessageCount + 1)
                                            setTicketMessages([...ticketMessages, {
                                                id: ticketMessages.length,
                                                content: content,
                                                date: new Date().toString(),
                                                creatorCommunityId: props.user.user?.communityId ?? "Unknown",
                                                creatorNick: props.user.user?.nick ?? "Unknown",
                                                creatorRpName: props.user.user?.rpName ?? "Unknown",
                                                creatorRankDisplayName: props.user.user?.rank?.displayName,
                                                creatorRankColor: props.user.user?.rank?.color
                                            }]);
                                            if (props.user.user?.communityId !== props.ticket.creatorCommunityId) {
                                                setAssignee(props.user.user!!.communityId)
                                                setStatus("awaiting_creator_response")
                                            }
                                        },
                                        onError: () => {
                                            setAlert("error", "Message failed to send")
                                        }
                                    })
                                })
                            }
                        }}>Send Message</Button>
                    </Paper>
                </> : <></>}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <Stack spacing={2}>
                    <Paper variant={"outlined"} style={{
                        padding: "16px"
                    }}>
                        <Stack spacing={1}>
                            <Typography variant="h6">{props.ticket.title}</Typography>
                            <Divider/>
                            <Typography variant="subtitle1">Division: {props.ticket.divisionDisplayName}</Typography>
                            <Divider/>
                            <Typography variant="subtitle1">Date: {formatTimestamp(props.ticket.date)}</Typography>
                            <Divider/>
                            <Typography variant="subtitle1">Messages: {ticketMessageCount}</Typography>
                        </Stack>
                    </Paper>
                    {props.user.hasPermissions(`${props.ticket.divisionIdentifier}-helpdesk:manage`) ? <Paper variant={"outlined"} style={{
                        marginTop: "16px",
                        padding: "16px"
                    }}>
                        <Stack spacing={1}>
                            <Typography variant="h6">Ticket Management</Typography>
                            <Divider/>
                            <Typography variant="subtitle1">Assigned Division</Typography>
                            <Select
                                id="select-division"
                                value={props.ticket.divisionIdentifier}
                                onChange={(event) => {
                                    updateDivision(props.ticket.id, props.ticket.divisionIdentifier, event.target.value as string)
                                }}
                            >
                                {
                                    props.divisions?.map(division => {
                                        return <MenuItem key={division.id}
                                                         value={division.identifier}>{division.displayName}</MenuItem>
                                    })
                                }
                            </Select>
                            <Divider/>
                            <Typography variant="subtitle1">Ticket Status</Typography>
                            <Select
                                id="select-status"
                                value={status}
                                onChange={(event) => {
                                    updateStatus(props.ticket.id, props.ticket.divisionIdentifier, event.target.value as string)
                                }}
                            >
                                {
                                    helpdeskTicketStatus.map((status, idx) => {
                                        return <MenuItem key={idx} value={status.key}>{status.displayName}</MenuItem>
                                    })
                                }
                            </Select>
                            <Divider/>
                            <Typography variant="subtitle1">Assignee</Typography>
                            <Select
                                id="select-assignee"
                                value={assignee}
                                disabled={status === "closed"}
                                onChange={(event) => {
                                    updateAssignee(props.ticket.id, event.target.value as string)
                                }}
                            >
                                {
                                    possibleAssignees.data?.map(assignee => {
                                        return <MenuItem key={assignee.communityId}
                                                         value={assignee.communityId}>{assignee.rankDisplayName} {assignee.nick}</MenuItem>
                                    })
                                }
                                <MenuItem key={0} value={undefined}>None</MenuItem>
                            </Select>
                        </Stack>
                    </Paper> : <></>}
                    <Paper variant={"outlined"} style={{
                        padding: "16px"
                    }}>
                        <Stack spacing={1}>
                            <Typography variant="subtitle1">Ticket Events</Typography>
                            <Timeline sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}>
                                {props.ticket.activities.map((activity) => (
                                    <HelpdeskTicketEvent data={activity}/>
                                ))}
                            </Timeline>
                        </Stack>
                    </Paper>
                </Stack>
            </Grid>
        </Grid>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Stack>
}

export const HelpdeskTicketPage = () => {
    const params = useParams()
    const userData = useUser()

    const ticketData = useApiCall<HelpdeskTicketResponse>({
        url: `/api/helpdesk/ticket/${params.id}`
    })

    const allDivisionsData = useApiCall<Division[]>({
        url: "/api/division/list-divisions"
    })

    const userDivisionRole = useApiCall<DivisionRoles>({
        url: `/api/user/${userData.user?.communityId}/division-role/${ticketData.data?.divisionIdentifier}`
    })

    return <LoadingContent
        isLoading={userData.isLoading || !userData || ticketData.isLoading || allDivisionsData.isLoading || userDivisionRole.isLoading}>
        <HelpdeskTicketPageContent user={userData!!} ticket={ticketData.data!!}
                                   divisions={allDivisionsData.data!!}
                                   userRoleInDivision={userDivisionRole.data?.displayName ?? ""}
                                   ticketApiCall={ticketData}
        />
    </LoadingContent>
}