import {AssessmentAnswer} from "../../data/assessment/AssessmentAnswer";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack
} from "@mui/material";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import React, {useEffect, useRef, useState} from "react";
import {TextEditor} from "../editor/TextEditor";

interface EditAnswerDialogProps {
    answer?: AssessmentAnswer
    open: boolean
    onClose: () => void
    onSubmit: (answer: AssessmentAnswer) => void
}

export const EditAnswerDialog = (props: EditAnswerDialogProps) => {
    const editorRef = useRef<LexicalEditor>(null)
    const [correct, setCorrect] = useState(props.answer?.correct ?? false)

    useEffect(() => {
        setCorrect(props.answer?.correct ?? false)
    }, [props.answer, props.open]);

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Edit Answer</DialogTitle>
        <DialogContent>
            <Stack spacing={2}>
                <FormControlLabel
                    control={<Checkbox checked={correct} onChange={(_, checked) => setCorrect(checked)}/>}
                    label={"Correct Answer"}/>
                <TextEditor ref={editorRef} initialContent={props.answer?.text}/>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={() => {
                if (editorRef.current) {
                    editorRef.current.getEditorState().read(() => {
                        let text = $generateHtmlFromNodes(editorRef.current!)
                        props.onSubmit({id: props.answer?.id ?? -1, text: text, correct: correct})
                    })
                }
            }}>Submit</Button>
        </DialogActions>
    </Dialog>
}