import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {RecordNoteResponse} from "../../data/RecordNoteResponse";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DialogActions, DialogContent} from "@mui/material";

interface DeleteNotePromptProps {
    searchRoot: string,
    infraction: RecordNoteResponse,
}

export default function DeleteNotePrompt(props: DeleteNotePromptProps) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <DeleteForeverIcon color={"error"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>Remove Record Entry</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant={"contained"} color={"success"} onClick={() =>
                        makeApiCall({
                            url: `/api/records/remove/${props.infraction.id}`,
                            method: "DELETE",
                            onLoadedCallback: () => {
                                navigate(`/records/${props.searchRoot}`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Remove Record Entry</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}