import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {MenuItem, Select} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import {Division} from "../../data/Division";
import useUser from "../../hooks/useUser";

interface NewApplicationDivisionSelectionFormProps {
    divisions: Division[],
}

export const NewApplicationDivisionSelectionForm = (props: NewApplicationDivisionSelectionFormProps) => {
    const [open, setOpen] = useState(false);
    const [applicationType, setApplicationType] = useState("")
    const [divisionIdentifier, setDivisionIdentifier] = useState("")
    const navigate = useNavigate()
    const user = useUser()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let content = <></>

    if (applicationType === "division" || applicationType === "certification") {
        content = <>
            <DialogTitle>Select Division</DialogTitle>
            <DialogContent>
                <Select sx={{width: "xl", minWidth: "150px"}}  id="new-role-application-select-division" value={divisionIdentifier}
                        onChange={(event) => {
                            setDivisionIdentifier(event.target.value as string)
                        }}>
                    {props.divisions.map((division) =>
                        <MenuItem key={division.id}
                                  value={division.identifier.replaceAll("_", "-")}>{division.displayName}</MenuItem>
                    )}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button color={"warning"} onClick={handleClose}>Cancel</Button>
                <Button color={"info"} disabled={divisionIdentifier === ""} onClick={() => {
                    if (applicationType === "division") {
                        navigate(`/new-application/${divisionIdentifier}`)
                    } else {
                        navigate(`/new-certification/${divisionIdentifier}`)
                    }
                }}>Select</Button>
            </DialogActions>
        </>
    } else if (applicationType === "rank") {
        content = <>
            <DialogActions>
                <Button color={"warning"} onClick={handleClose}>Cancel</Button>
                <Button color={"info"} onClick={() =>
                    navigate(`/new-rank-application`)
                }>Continue</Button>
            </DialogActions>
        </>
    }

    let rankOption = <></>
    if (user.hasPermissions(`office_of_operations_services-careers:create`)) {
        rankOption = <MenuItem key={"rank"} value={"rank"}>Department Rank</MenuItem>
    }

    return (
        <React.Fragment>
            <Button sx={{width: 200}} color="success" variant="contained" onClick={handleClickOpen}>New
                Application</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Application Type</DialogTitle>
                <DialogContent>
                    <Select sx={{width: "xl", minWidth: "150px"}}  id="new-role-application-select-type" value={applicationType}
                            onChange={(event) => {
                                setApplicationType(event.target.value as string)
                            }}>
                        {rankOption}
                        <MenuItem key={"division"} value={"division"}>Division Role</MenuItem>
                        <MenuItem key={"certification"} value={"certification"}>Certification</MenuItem>
                        )
                    </Select>
                </DialogContent>
                {content}
            </Dialog>
        </React.Fragment>
    );
}