import {
    Alert,
    Paper,
    Snackbar,
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AlertType} from "../data/AlertType";
import {AssessmentEditor} from "../components/assessment/AssessmentEditor";
import {AssessmentInfoResponse} from "../data/assessment/AssessmentInfoResponse";

export const NewAssessmentPage = () => {
    const navigate = useNavigate()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const createAssessment = (assessment: AssessmentInfoResponse) => {
        setApiCall(makeApiCall({
            url: `/api/careers/edit/${searchParams.get("careerId")}/new-stage`,
            method: "POST",
            body: assessment,
            onLoadedCallback: _ => {
                navigate(`/edit-application/${searchParams.get("careerId")}`)
            },
            onError: () => {
                setAlert("error", "Failed to create assessment")
            }
        }))
    }

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);


    return <Paper variant={"outlined"} style={{marginTop: "16px", padding: "16px"}}>
        <AssessmentEditor handleSubmit={createAssessment}/>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Paper>
}