import {makeApiCall} from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React, {useCallback, useEffect, useState} from "react";
import {
    Alert,
    Box,
    IconButton,
    Link,
    Pagination, Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {PaginatedUserSummaryResponseWithActivity, UserSummaryResponseWithActivity} from "../../data/UserSummary";
import {pluralize} from "../../utils";
import WarningIcon from '@mui/icons-material/Warning';
import {AlertType} from "../../data/AlertType";
import {ConfirmationDialog} from "../ConfirmationDialog";

interface ActivityProps {
    divisionIdentifier: string
}

export interface ActivityRowProps {
    member: UserSummaryResponseWithActivity
    divisionIdentifier: string
}

const ActivityRow = (props: ActivityRowProps) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])
    const [openConfirm, setOpenConfirm] = useState(false)

    const issueActivityWarning = (confirm: Boolean) => {
        setOpenConfirm(false)
        if (confirm) {
            makeApiCall({
                url: `/api/division/${props.divisionIdentifier}/activity`,
                method: "POST",
                body: {
                    employeeCommunityId: props.member.communityId
                },
                onLoadedCallback: () => {
                    setAlert("success", "Created inactivity warning")
                },
                onError: () => {
                    setAlert("error", "Failed to create inactivity warning")
                }
            })
        }
    }

    return <TableRow>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.member.communityId}`}>
            {props.member.nick}
            </Link>
        </TableCell>
        <TableCell>
            <Link underline="none" component={RouterLink} to={`/user/${props.member.communityId}`}>
                {props.member.rpName}
            </Link>
        </TableCell>
        <TableCell>{props.member.badgeNumber}</TableCell>
        <TableCell>{props.member.divisionRoleDisplayName}</TableCell>
        <TableCell>{Math.floor(props.member.thisMonthsActivity / 60 / 60)} {pluralize("Hour", props.member.thisMonthsActivity / 60 / 60)}</TableCell>
        {props.member.lastMonthsActivity >= 7200 ? <>
                <TableCell>{props.member.lastMonthsActivity ? <>
                    {Math.floor(props.member.lastMonthsActivity / 60 / 60)} {pluralize("Hour", props.member.lastMonthsActivity / 60 / 60)}
                </> : "Inactive"}</TableCell>
                <TableCell>
                    <IconButton disabled={true} size="small" color="error">
                        <WarningIcon color={"disabled"} fontSize="small" />
                    </IconButton>
                </TableCell>
            </>
            :
            <>
                <TableCell sx={{backgroundColor: "darkred", color: "white"}}>{props.member.lastMonthsActivity ? <>
                    {Math.floor(props.member.lastMonthsActivity / 60 / 60)} {pluralize("Hour", props.member.lastMonthsActivity / 60 / 60)} Fails to meet minimum hours
                </> : "Inactive"}</TableCell>
                <TableCell sx={{backgroundColor: "darkred"}}>
                    <Tooltip title={"Issue Activity Warning"}>
                        <IconButton size="small" color="error" onClick={() =>
                            setOpenConfirm(true)
                        }>
                            <WarningIcon color={"warning"} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </>
        }
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
        <ConfirmationDialog title={"Issue Activity Warning"} description={"This will make a inbox and issue a record note"} open={openConfirm} onClose={issueActivityWarning} />
    </TableRow>
}

export const Activity = (props: ActivityProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const [activity, setActivity] = useState<PaginatedUserSummaryResponseWithActivity>({
        members: [],
        count: 0,
        pages: 1
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/division/${props.divisionIdentifier}/activity/${page}`)
                const data = await response.json()
                setActivity(data)
            } catch (error) {
                console.error('Error fetching Rows:', error)
            }
        };
        fetchData()
    }, [page, props.divisionIdentifier])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    return <Stack spacing={4}>
            <Box sx={{overflowX: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Steam Name</TableCell>
                            <TableCell>In-Game Name</TableCell>
                            <TableCell>Badge Number</TableCell>
                            <TableCell>Division Rank</TableCell>
                            <TableCell>Activity</TableCell>
                            <TableCell>Activity Last Month</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingContent showLoadingSkeleton={true} isLoading={activity.count === 0}>
                            {activity.members && activity.members.map((member) => {
                                return <ActivityRow key={member.communityId} divisionIdentifier={props.divisionIdentifier} member={member}/>
                            })}
                        </LoadingContent>
                    </TableBody>
                </Table>
                <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
                    <Pagination color={'secondary'} count={activity.pages} page={page} style={{padding: 8}}
                                onChange={handleChange}/>
                </Box>
            </Box>
        </Stack>
}