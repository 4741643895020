import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {Alert, Button, Paper, Snackbar, Stack} from "@mui/material";
import {LoadingContent} from "../../components/LoadingContent";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {TextEditor} from "../../components/editor/TextEditor";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import useUser, {UserContextProps} from "../../hooks/useUser";
import {InternalAffairsFinalReportResponse} from "../../data/internal-affairs/InternalAffairsFinalReportResponse";
import {AlertType} from "../../data/AlertType";
import {formatTimestamp} from "../../utils";

interface finalReportProps {
    finalReport: InternalAffairsFinalReportResponse,
    user: UserContextProps,
    displayId: string
}

function FinalReport(props: finalReportProps) {
    const editorRef = useRef<LexicalEditor>(null)
    const [lastSyncedContent, setLastSyncedContent] = useState(props.finalReport.content)
    const [lastEditTime, setLastEditTime] = useState(props.finalReport.lastEditDate)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const navigate = useNavigate()

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    // eslint-disable-next-line
    const updateFinalReport = () => {
        if (editorRef.current) {
            editorRef.current.getEditorState().read(() => {
                let editorContent = $generateHtmlFromNodes(editorRef.current!)
                if (editorContent !== lastSyncedContent) {
                    makeApiCall({
                        url: `/api/internal-affairs/final-report/update/${props.finalReport.complaintId}`,
                        method: "POST",
                        body: {
                            content: editorContent,
                        },
                        onLoadedCallback: (data: InternalAffairsFinalReportResponse) => {
                            setLastSyncedContent(editorContent)
                            setLastEditTime(data.lastEditDate)
                            setAlert("success", "Final Report Saved")
                        },
                        onError: () => {
                            setAlert("error", "Final Report failed to save")
                        }
                    })
                } else {
                    setAlert("info", "You had no new information to save, a update was not performed")
                }
            })
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateFinalReport()
        }, 150000);

        return () => clearInterval(interval);
    }, [updateFinalReport])

    return <Stack spacing={1}>
        {props.finalReport.content !== lastSyncedContent ? <Alert variant={"outlined"} severity="success">Final Report last saved {formatTimestamp(lastEditTime)}</Alert> : <></>}
        <Paper variant={"outlined"} style={{
            padding: "16px"
        }}>
            <TextEditor ref={editorRef} initialContent={props.finalReport.content}/>
            <Button color="success" onClick={() => {
                if (editorRef.current) {
                    editorRef.current.getEditorState().read(() => {
                        makeApiCall({
                            url: `/api/internal-affairs/final-report/submit/${props.finalReport.complaintId}`,
                            method: "POST",
                            body: {
                                body: $generateHtmlFromNodes(editorRef.current!)
                            },
                            onLoadedCallback: () => {
                                navigate(`/complaint/${props.displayId}`)
                            },
                            onError: () => {}
                        })
                    })
                }
            }}>Submit Final Report</Button>
            <Button color="warning" component={RouterLink} to={`/complaint/${props.displayId}`}>Return to complaint</Button>
            <Button color="info" onClick={updateFinalReport}>Save Final Report</Button>
        </Paper>
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </Stack>
}

export const InternalAffairsFinalReport = () => {
    const params = useParams()
    const finalReportData = useApiCall<InternalAffairsFinalReportResponse>({
        url: `/api/internal-affairs/final-report/load/${params.id}`
    })

    const user = useUser()

    return (
        <LoadingContent isLoading={
            finalReportData.isLoading || !finalReportData.data
        }>
            <FinalReport finalReport={finalReportData.data!!} user={user} displayId={params.id!!}/>
        </LoadingContent>
    )
}