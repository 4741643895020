import {useTheme} from "@mui/material";
import './editor-content.css';
import {CSSProperties} from "react";
import {sanitizeHtml} from "../../utils";


interface EditorContentProps {
    content: string,
    style?: CSSProperties
}

export const EditorContent = (props: EditorContentProps) => {
    const theme = useTheme()
    let contentClassName = "editor-content"
    if (theme.palette.mode === "dark") {
        contentClassName = "editor-content dark-editor-content"
    }
    return <div style={{
        overflow: "auto",
        ...props.style
    }} className={contentClassName}
                dangerouslySetInnerHTML={{__html: sanitizeHtml(props.content)}}>
    </div>
}