import {NotificationResponse} from "../data/NotificationResponse";
import {
    Button,
    Divider,
    Grow,
    IconButton,
    Link,
    Paper,
    Stack,
    Tooltip,
    Typography, useTheme
} from "@mui/material";
import {formatTimestamp} from "../utils";
import React, {Fragment, useEffect, useState} from "react";
import {Delete, MarkAsUnread, MarkAsUnreadOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import Badge from "@mui/material/Badge";
import {PopperArrow, StyledPopper} from "./StyledPopper";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {RecentNotificationResponse} from "../data/RecentNotificationResponse";
import {LoadingContent} from "./LoadingContent";

interface NotificationMenuProps {
    open: boolean
    onClose: () => void
    anchorEl: HTMLElement | null
}

export const NotificationMenu = (props: NotificationMenuProps) => {
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
    const [changeApiCall, setChangeApiCall] = useState<ApiCallResponseData>()
    const [shouldLoadData, setShouldLoadData] = useState(false)
    const [wasOpen, setWasOpen] = useState(false)

    const dataApiCall = useApiCall<RecentNotificationResponse>({
        url: "/api/notification/recent",
        deferLoading: !shouldLoadData
    })
    const notifications = dataApiCall.data?.notifications

    const deleteNotification = (id: number) => {
        changeApiCall?.cancel()
        setChangeApiCall(makeApiCall({
            url: `/api/notification/${id}`,
            method: "DELETE",
            onLoadedCallback: () => {
                dataApiCall.refresh()
            },
            onError: () => {
            },
        }))
    }

    const markNotificationRead = (id: number, read: boolean) => {
        changeApiCall?.cancel()
        setChangeApiCall(makeApiCall({
            url: `/api/notification/${id}/read?read=${read}`,
            method: "PUT",
            onLoadedCallback: () => {
                dataApiCall.refresh()
            },
            onError: () => {
            },
        }))
    }

    const readAllNotifications = () => {
        changeApiCall?.cancel()
        setChangeApiCall(makeApiCall({
            url: "/api/notification/read-all",
            method: "POST",
            onLoadedCallback: () => {
                dataApiCall.refresh()
            },
            onError: () => {
            },
        }))
    }

    useEffect(() => {
        return () => {
            changeApiCall?.cancel()
        }
    }, [changeApiCall])

    useEffect(() => {
        setWasOpen(props.open)
        if (!props.open || wasOpen === props.open) return
        setShouldLoadData(true)
        dataApiCall.refresh()
    }, [props.open, wasOpen, dataApiCall])


    return <StyledPopper sx={{zIndex: (theme) => theme.zIndex.appBar + 1}} open={props.open} anchorEl={props.anchorEl}
                         placement="bottom-end" transition
                         modifiers={[
                             {
                                 name: 'arrow',
                                 enabled: true,
                                 options: {
                                     element: arrowRef,
                                 },
                             },
                         ]}>
        {({TransitionProps}) => (
            <Grow {...TransitionProps} timeout={250}>
                <Paper variant={"outlined"} sx={{width: 300, overflowWrap: "break-word"}}>
                    <PopperArrow ref={setArrowRef}/>
                    <Stack spacing={0}>
                        <Typography variant="h6" sx={{padding: 1}}>Notifications</Typography>
                        <Divider/>

                        {(notifications && notifications.length > 0) ?
                            notifications?.map(notification =>
                                <Fragment key={notification.id}>
                                    <NotificationMenuEntry notification={notification}
                                                           delete={deleteNotification}
                                                           markRead={markNotificationRead}
                                                           onClick={props.onClose}
                                    />
                                    <Divider/>
                                </Fragment>
                            ) : <LoadingContent isLoading={dataApiCall.isLoading || !shouldLoadData}>
                                <Typography sx={{padding: 1}} variant="body1"><i>You have no notifications</i></Typography>
                                <Divider/>
                            </LoadingContent>
                        }
                        <Stack direction="row" sx={{padding: 1}}>
                            <Button color="info" variant="text" component={RouterLink} to="/notifications"
                                    onClick={() => {
                                        props.onClose()
                                    }}>
                                Show all
                            </Button>
                            <Button variant="text" color="info" size="small" onClick={() => readAllNotifications()}>Mark
                                read</Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Grow>
        )}
    </StyledPopper>
}


interface NotificationMenuEntryProps {
    notification: NotificationResponse
    markRead: (id: number, read: boolean) => void
    delete: (id: number) => void
    onClick?: () => void
}

export const NotificationMenuEntry = (props: NotificationMenuEntryProps) => {
    const [hovered, setHovered] = useState(false)
    const theme = useTheme()

    const hoveredColor = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100]
    const backgroundColor = hovered ? hoveredColor : undefined

    const readIcon = props.notification.read ? <MarkAsUnread fontSize="inherit"/> :
        <MarkAsUnreadOutlined fontSize="inherit"/>
    let readButton = <></>
    let deleteButton = <></>
    if (hovered) {
        readButton = <Tooltip arrow title={`Mark ${props.notification.read ? "Unread" : "Read"}`}>
            <IconButton size="small" sx={{paddingY: 0}}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            props.markRead(props.notification.id, !props.notification.read)
                        }}>{readIcon}</IconButton>
        </Tooltip>
        deleteButton = <Tooltip arrow title="Delete">
            <IconButton size="small" sx={{paddingY: 0}} onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                props.delete(props.notification.id)}}>
                <Delete fontSize="inherit"/>
            </IconButton>
        </Tooltip>
    }
    return <Link variant="body1" component={RouterLink} to={props.notification.link} color="inherit"
                 underline="none"
                 sx={{width: "100%", background: backgroundColor, p: 1, pl: 2, pr: 2}}
                 onMouseEnter={() => setHovered(true)}
                 onMouseLeave={() => setHovered(false)}
                 onClick={() => {
                     if (!props.notification.read) {
                         props.markRead(props.notification.id, true)
                     }
                     if (props.onClick) {
                         props.onClick()
                     }
                 }}>
        <Badge color="error" variant="dot" invisible={props.notification.read}
               anchorOrigin={{vertical: "top", horizontal: "left"}} sx={{width: "100%"}}>
            <Stack spacing={0.5} sx={{width: "100%"}}>
                <Typography
                    sx={{textDecoration: hovered ? "underline" : "none"}}>{props.notification.message}</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2"
                                color="inherit">{formatTimestamp(props.notification.date)}</Typography>
                    <Stack direction="row" spacing={1}>
                        {readButton}
                        {deleteButton}
                    </Stack>
                </Stack>
            </Stack>
        </Badge>
    </Link>
}