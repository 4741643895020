import React from "react";
import {Button, Paper} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useParams} from "react-router-dom";
import {DetailedResourceResponse} from "../../data/DetailedResourceResponse";
import {EditorContent} from "../../components/editor/EditorContent";

interface ViewResourcePageContentProps {
    resource: DetailedResourceResponse
}

function ViewResourcePageContent(props: ViewResourcePageContentProps) {
    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <EditorContent content={props.resource.content} />
        <Button component={RouterLink}
                to={`/resources/${props.resource.divisionIdentifier?.replaceAll("_", "-")}`}
                color="info">Return</Button>
    </Paper>
}

export const ViewResourcePage = () => {
    const params = useParams()

    const resource = useApiCall<DetailedResourceResponse>({
        url: `/api/resource/entry/${params.divisionIdentifier?.replaceAll("-", "_")}/${params.resourceIdentifier}`
    })

    return <LoadingContent
        isLoading={(resource.isLoading || !resource)}>
        <ViewResourcePageContent resource={resource.data!!}/>
    </LoadingContent>
}