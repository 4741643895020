import {DivisionRoles} from "../../data/DivisionRoles";
import {RankResponse} from "../../data/RankResponse";
import React, {useState} from "react";
import {Alert, Button, Input, InputLabel, MenuItem, Paper, Select, Stack} from "@mui/material";
import {reduceTimeToSeconds} from "../../utils";
import {applicationRequirement} from "./NewApplicationPage";
import {Division} from "../../data/Division";
import {CertificationResponse} from "../../data/CertificationResponse";

type alertType = "success" | "error" | "info"

interface ApplicationRequirementTemplateProps {
    requirementTable: applicationRequirement[]
    requirementIdentifier: string
    shouldUseTimeUnits?: boolean
    isToggle?: boolean
    divisionRankData?: DivisionRoles[]
    rankData?: RankResponse[]
    divisionData?: Division[]
    certificationData?: CertificationResponse[]
    startValue: number
    inputPlaceholder: string
    setAlert: (type: alertType, text: string) => void
    alertText?: string
    defaultBasicSelectorData?: number
}

interface ApplicationRequirementSelectorTableValues {
    key: number
    value: number
    displayContent: string
}

export const ApplicationRequirementTemplate = (props: ApplicationRequirementTemplateProps) => {
    const defaultData = props.defaultBasicSelectorData ?? props.startValue
    const [basicSelection, setBasicSelection] = useState(defaultData)
    const [secondaryTimeSelector, setSecondaryTimeSelector] = useState("seconds")
    const [disabled, setDisabled] = useState(false)

    const selectorTableValues: ApplicationRequirementSelectorTableValues[][] = []

    selectorTableValues.push(
        props.rankData?.map(data => { return {
            key: data.rankId,
            value: data.rankId,
            displayContent: data.displayName
        }}) ?? []
    )

    selectorTableValues.push(
        props.divisionData?.map(data => { return {
            key: data.id,
            value: data.id,
            displayContent: data.displayName
        }}) ?? []
    )

    selectorTableValues.push(
        props.divisionRankData?.map(data => { return {
            key: data.id,
            value: data.roleId,
            displayContent: data.displayName
        }}) ?? []
    )

    selectorTableValues.push(
        props.certificationData?.map(data => { return {
            key: data.id,
            value: data.id,
            displayContent: data.displayName
        }}) ?? []
    )

    return <Paper style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Stack spacing={2}>
            {props.alertText ? <Alert severity="info">{props.alertText}</Alert> : <></>}
            {props.shouldUseTimeUnits ? <>
                <InputLabel>{props.inputPlaceholder}</InputLabel>
                <Input disabled={disabled} type={"number"} value={basicSelection}onChange={
                    (event) => setBasicSelection(Number(event.target.value))
                }/>
                <Select disabled={disabled} id="cooldown-unit" value={secondaryTimeSelector} onChange={(event) => {
                setSecondaryTimeSelector(event.target.value as string)
            }}>
                <MenuItem key={0} value={"seconds"}>Seconds</MenuItem>
                <MenuItem key={1} value={"minutes"}>Minutes</MenuItem>
                <MenuItem key={2} value={"hours"}>Hours</MenuItem>
                <MenuItem key={3} value={"days"}>Days</MenuItem>
                <MenuItem key={4} value={"weeks"}>Weeks</MenuItem>
                <MenuItem key={5} value={"months"}>Months</MenuItem>
            </Select></> : <></>}
            {selectorTableValues.filter(i => i.length > 0).map(selectorData => {
                return <>
                    <InputLabel>{props.inputPlaceholder}</InputLabel>
                    <Select disabled={disabled} value={basicSelection} onChange={(event) => {
                        setBasicSelection(event.target.value as number)
                    }}>
                        {selectorData.map((i) =>
                            <MenuItem key={i.key} value={i.value}>{i.displayContent}</MenuItem>
                        )}
                    </Select>
                </>
            })}
            {props.isToggle ? <InputLabel>{props.inputPlaceholder}</InputLabel> : <></>}
            <Button disabled={(basicSelection === defaultData && !props.isToggle) || disabled} variant="contained" onClick={() => {
                if (props.shouldUseTimeUnits) {
                    props.requirementTable.push(
                        {
                            type: props.requirementIdentifier,
                            param: reduceTimeToSeconds(basicSelection, secondaryTimeSelector)
                        }
                    )
                } else {
                    props.requirementTable.push(
                        {
                            type: props.requirementIdentifier,
                            param: basicSelection
                        }
                    )
                }
                setDisabled(true)
                props.setAlert("success", "Requirement Saved")
            }}>
                Save requirement
            </Button>
        </Stack>
    </Paper>
}