import React, {useState} from "react";
import useApiCall from "../hooks/CancellableApiCall";
import {PayeeResponse} from "../data/PayeeResponse";
import {
    Box,
    Button,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    InputLabel,
    FormControl,
    ListSubheader
} from "@mui/material";
import {PoliceUser} from "../components/PoliceUser";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export const PaycheckPage = () => {
    const [blacklistedIds, setBlacklistIds] = useState<number[]>([])
    const [paymentScript, setPaymentScript] = useState<string | null>(null)
    const [extraPay, setExtraPay] = useState<{ [communityId: string]: number }>({})
    const loadPayees = useApiCall<PayeeResponse[]>({
        url: `/api/paycheck/`
    })

    let pageContent
    if (!paymentScript) {
        pageContent = <>
            <Box sx={{overflowX:"auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nick</TableCell>
                        <TableCell>Bank ID</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Payment Amount</TableCell>
                        <TableCell>Additional Bonus</TableCell>
                        <TableCell>Give Bonus</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loadPayees.data && loadPayees.data.map((payee) => {
                        let payCheckColor
                        if (extraPay[payee.payeeCommunityId] > 0) {
                            payCheckColor = "success.main"
                        } else if (extraPay[payee.payeeCommunityId] < 0) {
                            payCheckColor = "error.main"
                        } else {
                            payCheckColor = "info.main"
                        }
                        return <TableRow key={payee.payeeCommunityId}>
                            <TableCell>
                                <PoliceUser
                                    user={{nick: payee.payeeNick, communityId: payee.payeeCommunityId.toString()}}/>
                            </TableCell>
                            <TableCell> {payee.bankAccount.split(".")[0]} </TableCell>
                            <TableCell> {payee.paycheckType.substring(9).replace("-", " ").replace(/(^\w|\s\w)/g, m => m.toUpperCase())} </TableCell>
                            <TableCell> ${payee.paycheckAmount} </TableCell>
                            <TableCell>
                            <FormControl sx={{ m: 1, width: 300 }} variant="standard">
                            <InputLabel id="bonus-amount">Bonus Amount</InputLabel>
                            <Select sx={{color: payCheckColor}} id={`${payee.payeeCommunityId}-extra`} defaultValue={0} label="Bonus Amount" onChange={(event) =>
                                setExtraPay(prevState => ({
                                    ...prevState,
                                    [payee.payeeCommunityId]: event.target.value
                                }))
                            }>
                            <ListSubheader sx={{color: 'info.main'}}>Base</ListSubheader>
                            <MenuItem key={0} value={"0"} sx={{color: 'info.main'}}>$0</MenuItem>
                            <ListSubheader sx={{color: 'error.main'}}>Subtraction</ListSubheader>
                            <MenuItem key={15000} value={"-15000"} sx={{color: 'error.main'}}>$15,000</MenuItem>
                            <MenuItem key={30000} value={"-30000"} sx={{color: 'error.main'}}>$30,000</MenuItem>
                            <MenuItem key={50000} value={"-50000"} sx={{color: 'error.main'}}>$50,000</MenuItem>
                            <ListSubheader sx={{color: 'success.main'}}>Addition</ListSubheader>
                            <MenuItem key={15000} value={"15000"} sx={{color: 'success.main'}}>$15,000</MenuItem>
                            <MenuItem key={30000} value={"30000"} sx={{color: 'success.main'}}>$30,000</MenuItem>
                            <MenuItem key={50000} value={"50000"} sx={{color: 'success.main'}}>$50,000</MenuItem>
                            </Select>
                            </FormControl>
                            </TableCell>
                            <TableCell>
                            <FormControl sx={{ m: 1, width: 150 }} variant="standard">
                                <Select
                                    id='select-allowed'
                                    value={Number(blacklistedIds.includes(payee.payeeCommunityId))}
                                    onChange={(event) => {
                                        if (Number(event.target.value) === 1) {
                                            setBlacklistIds([...blacklistedIds, payee.payeeCommunityId])
                                        } else {
                                            setBlacklistIds(blacklistedIds.filter(blacklistedId => {
                                                return payee.payeeCommunityId !== blacklistedId
                                            }))
                                        }
                                    }}
                                >
                                    <MenuItem key={0} value={0}>Yes</MenuItem>
                                    <MenuItem key={1} value={1}>No</MenuItem>
                                </Select>
                            </FormControl>
                            </TableCell>
                        </TableRow>
                    })
                    }
                </TableBody>
            </Table>
            </Box>
            <Button color="success" onClick={() => {
                let paymentScript = `echo "Starting payroll script"\n`
                loadPayees.data?.forEach(payee => {
                    let bonus
                    if (!isNaN(Number(extraPay[payee.payeeCommunityId]))) {
                        bonus = Number(extraPay[payee.payeeCommunityId])
                    } else {
                        bonus = 0
                    }
                    if (!blacklistedIds.includes(payee.payeeCommunityId)) {
                        let paycheck = Number(payee.paycheckAmount) + bonus
                        let bankaccount = payee.bankAccount.split(".")[0]
                        paymentScript += `echo "Sending $${paycheck} to ${payee.payeeNick}"\nscam givemoneybank ${bankaccount} ${paycheck} "PLPD Paycheck"\n`
                    }
                })
                setPaymentScript(paymentScript)
            }}>Generate Script</Button>
        </>
    } else {
        pageContent = <>
            <Typography variant="h6">Payment Script</Typography>
            <Typography variant="subtitle1" sx={{whiteSpace: "pre-wrap", mb: 1}}>{paymentScript}</Typography>
            <Button color="info" variant="text" onClick={() => setPaymentScript(null)}>
                Return
            </Button>
            <Button color="success" variant="text" startIcon={<ContentPasteIcon />} onClick={() => {navigator.clipboard.writeText(paymentScript)}}>
                Copy to Clipboard
            </Button>
        </>
    }

    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        {pageContent}
    </Paper>
}