import {Link, useNavigate, useParams} from "react-router-dom";
import {
    Alert,
    Button,
    Grid,
    Paper,
    Snackbar,
    Stack,
} from "@mui/material";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {AssessmentInfoResponse} from "../data/assessment/AssessmentInfoResponse";
import {LoadingContent} from "../components/LoadingContent";
import React, {useCallback, useEffect, useState} from "react";
import {AssessmentQuestionInfoResponse} from "../data/assessment/AssessmentQuestionInfoResponse";
import {QuestionList} from "../components/assessment/QuestionList";
import {AlertType} from "../data/AlertType";
import {QuestionEditor} from "../components/assessment/QuestionEditor";
import {AssessmentQuestionRequest} from "../data/assessment/AssessmentQuestionRequest";

export const NewAssessmentQuestionPage = () => {
    const params = useParams()
    const navigate = useNavigate()
    const assessmentData = useApiCall<AssessmentInfoResponse>({
        url: `/api/careers/assessments/${params.id}`
    })
    const questionsData = useApiCall<AssessmentQuestionInfoResponse[]>({
        url: `/api/careers/assessments/${params.id}/questions`
    })

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    const createQuestion = (question: AssessmentQuestionRequest) => {
        setApiCall(makeApiCall({
            url: `/api/careers/edit/${params.id}/new-question`,
            method: "POST",
            body: question,
            onLoadedCallback: _ => {
                navigate(`/assessments/${params.id}/edit`)
            },
            onError: () => {
                setAlert("error", "Failed to create question")
            }
        }))
    }

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    return <LoadingContent
        isLoading={assessmentData.isLoading || !assessmentData.data || questionsData.isLoading || !questionsData.data}>
        <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                    <QuestionEditor assessment={assessmentData.data!!} handleSubmit={createQuestion} setAlert={setAlert}/>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Paper variant="outlined" sx={{padding: "16px"}}>
                        <Stack spacing={2}>
                            <Button variant="contained" component={Link} disabled={assessmentData.data?.isPublished}
                                    to={`/assessments/${params.id}/edit/question/new`}>Add Question</Button>
                            <QuestionList questions={questionsData.data ?? []}
                                          linkUrlBuilder={question =>
                                              `/assessments/${params.id}/edit/question/${question.id}`}/>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}}
                       variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </Paper>
    </LoadingContent>
}