import {Link as RouterLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {clamp, parseIntOrNull} from "../utils";
import useApiCall, {ApiCallResponseData, makeApiCall} from "../hooks/CancellableApiCall";
import {UserAssessmentResponse} from "../data/UserAssessmentResponse";
import {Alert, Button, Grid, Link, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {UserAssessmentQuestion} from "../components/assessment/UserAssessmentQuestion";
import {EditorContent} from "../components/editor/EditorContent";
import {QuestionList} from "../components/assessment/QuestionList";
import {UserAssessmentQuestionResponse} from "../data/UserAssessmentQuestionResponse";
import {AlertType} from "../data/AlertType";
import {LoadingContent} from "../components/LoadingContent";
import {UserAssessmentQuestionInfoResponse} from "../data/UserAssessmentQuestionInfoResponse";
import {UserAssessmentFeedbackResponse} from "../data/UserAssessmentFeedbackResponse";
import useUser from "../hooks/useUser";
import {AvatarBlock, TextBracket} from "../components/profile/ProfileInfo";
import {PoliceAvatar} from "../components/PoliceAvatar";
import {ConfirmationDialog} from "../components/ConfirmationDialog";

export const GradeAssessmentPage = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [questionNumber, _setQuestionNumber] = useState(parseIntOrNull(searchParams.get("question") ?? "") ?? undefined)
    const userAssessmentData = useApiCall<UserAssessmentResponse>({
        url: `/api/careers/grade/${params.id}`
    })

    const setQuestionNumber = (questionNumber?: number) => {
        if (questionNumber === undefined) {
            searchParams.delete("question")
        } else {
            searchParams.set("question", questionNumber.toString())
        }
        _setQuestionNumber(questionNumber)
        setSearchParams(searchParams)
    }

    return <LoadingContent isLoading={userAssessmentData.isLoading || !userAssessmentData.data}>
        <GradeAssessmentPageContent assessment={userAssessmentData.data!} questionNumber={questionNumber}
                                    setQuestionNumber={setQuestionNumber}/>
    </LoadingContent>
}

interface GradeAssessmentPageProps {
    assessment: UserAssessmentResponse
    questionNumber?: number
    setQuestionNumber: (questionNumber?: number) => void
}

const GradeAssessmentPageContent = (props: GradeAssessmentPageProps) => {
    const user = useUser()
    const [questionInfo, setQuestionInfo] = useState(props.assessment.questions)
    const [failConfirm, setFailConfirm] = useState(false)
    const [gradeConfirm, setGradeConfirm] = useState(false)
    const [question, setQuestion] = useState<UserAssessmentQuestionResponse>()
    const [apiCall, setApiCall] = useState<ApiCallResponseData>()
    const [currentScore, setCurrentScore] = useState(props.assessment.currentScore)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const allGraded = questionInfo.every(q => q.awardedPoints !== null)
    const navigate = useNavigate()

    const fail = (confirm: boolean) => {
        if (confirm) {
            makeApiCall({
                url: `/api/careers/administrative-fail/${props.assessment.id}`,
                method: "PATCH",
                body: {},
                onLoadedCallback: () => {
                    navigate(`/user/${props.assessment.user.communityId}`)
                },
                onError: () => {}
            })
        }
        setFailConfirm(false)
    }

    const grade = (confirm: boolean) => {
        if (confirm) {
            setApiCall(makeApiCall({
                url: `/api/careers/marking/${props.assessment.id}/submit`,
                method: "POST",
                body: {},
                onLoadedCallback: () => {
                    navigate("/careers")
                    setAlert("success", `Submitted to user`)
                },
                onError: () => {
                    setAlert("error", `Action Failed`)
                }
            }))
        }
        setGradeConfirm(false)
    }

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    useEffect(() => {
        return () => {
            apiCall?.cancel()
        }
    }, [apiCall]);

    useEffect(() => {
        if (props.questionNumber !== undefined) {
            const questionId = props.assessment.questions[(props.questionNumber ?? 1) - 1]?.id
            if (questionId !== undefined) {
                setApiCall(makeApiCall({
                    url: `/api/careers/grade/${props.assessment.id}/question/${questionId}`,
                    onLoadedCallback: (data: UserAssessmentQuestionResponse) => setQuestion(data),
                    onError: () => {
                    }
                }))
            }
        } else {
            setQuestion(undefined)
        }

    }, [props.assessment, props.questionNumber, setApiCall, setQuestion]);

    const incrementQuestionNumber = (x: number) => {
        props.setQuestionNumber(clamp((question?.order ?? 0) + x, 1, props.assessment.questions.length))
    }

    const doUpdateGrade = useCallback((question: UserAssessmentQuestionResponse, points: number, onSuccess: () => void) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}`,
            method: "PATCH",
            body: {
                points: points
            },
            onLoadedCallback: (data: UserAssessmentQuestionInfoResponse) => {
                onSuccess()
                setAlert("success", `Updated grade for Question ${question.order}`)
                const newQuestionInfo = questionInfo.map(_ => _)
                newQuestionInfo[data.order - 1] = data
                setQuestionInfo(newQuestionInfo)
            },
            onError: () => {
                setAlert("error", `Failed to update grade for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, questionInfo, setAlert])

    const doSubmitFeedback = useCallback((
        question: UserAssessmentQuestionResponse,
        feedback: string,
        isPrivate: Boolean,
        onSuccess: (response: UserAssessmentFeedbackResponse) => void
    ) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}/feedback`,
            method: "POST",
            body: {
                isPrivate: isPrivate,
                feedback: feedback,
            },
            onLoadedCallback: (data: UserAssessmentFeedbackResponse) => {
                setAlert("success", `Submitted feedback for Question ${question.order}`)
                onSuccess(data)
            },
            onError: () => {
                setAlert("error", `Failed to submit feedback for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, setAlert])

    const doDeleteFeedback = useCallback((question: UserAssessmentQuestionResponse, id: number, onSuccess: () => void) => {
        setApiCall(makeApiCall({
            url: `/api/careers/grade/${props.assessment.id}/question/${question.id}/feedback`,
            method: "DELETE",
            body: {
                id: id,
            },
            onLoadedCallback: () => {
                setAlert("success", `Deleted feedback for Question ${question.order}`)
                onSuccess()
            },
            onError: () => {
                setAlert("error", `Failed to delete feedback for Question ${question.order}`)
            }
        }))
    }, [props.assessment.id, setAlert])

    return <Stack spacing={2}>
        <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} xl={4}>
                    <Paper variant={"outlined"} sx={{padding: '15px'}}>
                        <AvatarBlock>
                            <PoliceAvatar
                                online={false}
                                avatarUrl={props.assessment.user.avatarUrl}
                                color={props.assessment.user.rank?.color ?? "white"}
                                userCommunityId={props.assessment.user.communityId}
                                dimensions={150}
                            ></PoliceAvatar>
                            <TextBracket>
                                <Typography variant="h5" color="text.primary">{props.assessment.user.nick}</Typography>
                                <span><i className={`rank timeline-rank ${props.assessment.user.rank?.iconName}`}/> {props.assessment.user.rank?.displayName} {props.assessment.user.rpName} ({props.assessment.user.badgeNumber})</span>
                            </TextBracket>
                        </AvatarBlock>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={8}>
                    <Paper variant={"outlined"} sx={{padding: "15px", height: "181px", overflow: "auto", wordWrap: "break-word"}}>
                        {props.assessment.passed === null ?
                            <Alert severity={currentScore >= props.assessment.passingScore ? "success" : "error"}>Current Score {currentScore}/{props.assessment.totalPoints} a score of {props.assessment.passingPercentage}% is needed to pass</Alert>
                        : <Alert severity={currentScore >= props.assessment.passingScore ? "success" : "error"}>Final Score {currentScore}/{props.assessment.totalPoints} - Score Submitted by
                                {props.assessment.markingUser !== null ? <Link underline={"none"} component={RouterLink} to={`/user/${props.assessment.markingUser?.communityId}`}>
                                    {` ${props.assessment.markingUser?.nick}`}
                                </Link> : " an Automated System"}
                        </Alert>}
                        {props.assessment.passed !== null ? <>
                            <Button disabled={true} color="error">Fail Application</Button>
                        </> :  <>
                            <Button onClick={() => {
                                setFailConfirm(true)
                            }} color="error">Fail Application</Button>
                        </>}
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
        <Paper variant={"outlined"} sx={{padding: "16px", marginTop: "16px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                    <Stack spacing={2}>
                        <Link component="button" variant="h4" underline="hover" color="inherit"
                              sx={{textAlign: "inherit"}} onClick={() => {
                            props.setQuestionNumber(undefined)
                        }}>{props.assessment.title}</Link>
                        {question !== undefined ? <>
                                <UserAssessmentQuestion key={question.order} assessment={props.assessment} question={question}
                                                        doUpdateGrade={doUpdateGrade} doSubmitFeedback={doSubmitFeedback}
                                                        doDeleteFeedback={doDeleteFeedback} variant="grader"
                                                        canDeleteFeedback={user.hasPermissions("assessment:deleteFeedback")}
                                                        updateCurrentScoreFunction={setCurrentScore} currentScore={currentScore}/>
                                <Stack direction="row" spacing={2}>
                                    <Button disabled={question.order <= 1} variant="contained"
                                            onClick={() => incrementQuestionNumber(-1)}>Previous
                                        Question</Button>
                                    <Button disabled={question.order >= questionInfo.length} variant="contained"
                                            onClick={() => incrementQuestionNumber(1)}>Next
                                        Question</Button>
                                </Stack>
                            </> :
                            <>
                                <EditorContent content={props.assessment.description}/>
                                <Button variant="contained"
                                        onClick={() => props.setQuestionNumber(questionInfo[0].order)}>Continue</Button>
                            </>
                        }

                    </Stack>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Paper variant="outlined" sx={{padding: "16px"}}>
                        <Stack spacing={2}>
                            <QuestionList questions={questionInfo} selectedQuestion={(question?.id)}
                                          submitted={props.assessment.submitted}
                                          onClick={question => props.setQuestionNumber(question.order)}/>
                            <Button variant="contained" disabled={props.assessment.passed !== null} color={allGraded ? "success" : "info"}
                                    onClick={() => {
                                        setGradeConfirm(true)
                                    }}>
                                Finish Grading
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </Paper>
        <ConfirmationDialog title={`Submit Grading`} description={"Ensure your marks are correct before submitting, you can view if the applicant will pass or fail in the main view, when submitted this cannot be reverted"} open={gradeConfirm} onClose={grade} />
        <ConfirmationDialog title={`Fail Application`} description={"This will fail the applicant, this cannot be undone"} open={failConfirm} onClose={fail} />
    </Stack>
}