import {LoadingContent} from "../components/LoadingContent";
import useApiCall from "../hooks/CancellableApiCall";
import useUser from "../hooks/useUser";
import {Box, Button, Pagination, Paper} from "@mui/material";
import React from "react";
import {HelpdeskPageContent} from "./HelpdeskPage";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {HelpdeskPageninatedResponse} from "../data/helpdesk/HelpdeskPageninatedResponse";

export const ArchivedHelpdeskTicketsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") ?? "1")
    const ticketData = useApiCall<HelpdeskPageninatedResponse>({
        url: `/api/helpdesk/archived?page=${page}`
    })

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString())
        setSearchParams(searchParams)
    }

    const totalPages = ticketData.data?.pages ?? 1

    const userData = useUser()

    return <LoadingContent isLoading={userData.isLoading || ticketData.isLoading}>
        <Paper variant={"outlined"} style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <HelpdeskPageContent user={userData.user!!} ticketData={ticketData.data!!}/>
            <Button component={RouterLink} to={`/helpdesk`} color="info">View Open Tickets</Button>
        </Paper>
        <Box sx={{justifyContent: "center", display: "flex", mt: 2, width: "100%"}}>
            <Pagination color={'secondary'} count={totalPages} page={page} style={{padding: 8}}
                        onChange={handleChange}/>
        </Box>
    </LoadingContent>
}