import {RankResponse} from "./RankResponse";

export interface AccountResponse {
    communityId: string
    nick: string
    rpName: string
    pronouns?: string
    badgeNumber?: number
    avatarLink: string
    mediumAvatarLink: string
    fullAvatarLink: string
    enabled: boolean
    discharged: boolean
    rank?: RankResponse
    permissions: string[]
    blacklisted: boolean
    suspended: boolean
    taser: boolean
    reservedSlot: boolean
}

export const userHasPermissions = (user: AccountResponse, permissions: string | string[]): boolean => {
    if (Array.isArray(permissions)) {
        return permissions.every((permission) => user.permissions.includes(permission))
    } else {
        return user.permissions.includes(permissions)
    }
}