import {Paper, Stack, Typography} from "@mui/material";
import {ApplicationRequirementTemplate} from "./ApplicationRequiermentTemplate";
import React from "react";
import {applicationRequirement} from "./NewApplicationPage";
import {DivisionRoles} from "../../data/DivisionRoles";
import {RankResponse} from "../../data/RankResponse";
import {Division} from "../../data/Division";
import {RankRequirement} from "./RankRequirements";
import {BasicCareerRequirement} from "../../data/BasicCareerRequirement";
import {CertificationResponse} from "../../data/CertificationResponse";

type alertType = "success" | "error" | "info"

interface ApplicationRequirementsProps {
    requirementTable: applicationRequirement[]
    divisionRoles?: DivisionRoles[]
    ranks?: RankResponse[]
    divisions?: Division[]
    certifications?: CertificationResponse[]
    setAlert: (type: alertType, text: string) => void
    requirementsToInclude: RankRequirement[]
    existingRequirements?: BasicCareerRequirement[]
}

export const ApplicationRequirements = (props: ApplicationRequirementsProps) => {
    return <Stack spacing={1}>
        <Paper style={{
            marginTop: "16px",
            padding: "16px"
        }}>
            <Typography variant="body1">The below requirements are all optional</Typography>
        </Paper>
        {props.requirementsToInclude && props.requirementsToInclude.map(requirement => {
            return <ApplicationRequirementTemplate
                requirementTable={props.requirementTable}
                requirementIdentifier={requirement.requirementIdentifier}
                shouldUseTimeUnits={requirement.shouldUseTimeUnits}
                rankData={requirement.shouldUseRanks ? props.ranks : undefined}
                divisionRankData={requirement.shouldUseDivisionRoles ? props.divisionRoles : undefined}
                divisionData={requirement.shouldUseDivisions ? props.divisions : undefined}
                certificationData={requirement.shouldUseCertifications ? props.certifications : undefined}
                defaultBasicSelectorData={props.existingRequirements?.find(t => t.type === requirement.requirementIdentifier)?.param}
                startValue={requirement.startValue}
                isToggle={requirement.isToggle}
                inputPlaceholder={requirement.inputPlaceholder}
                alertText={requirement.alertText}
                setAlert={props.setAlert}
            />
        })}
    </Stack>
}