interface DivisionHierarchyInter {
    key: string
    level: number
}

export const divisionHierarchy: DivisionHierarchyInter[] = [
    { key: "non_member", level: -1 },
    { key: "junior_member", level: 0 },
    { key: "member", level: 1 },
    { key: "assistant", level: 2 },
    { key: "junior_command", level: 3 },
    { key: "command", level: 4 },
    { key: "head", level: 5 },
    { key: "manager", level: 6 },
]

export const DivisionHierarchy: Map<string, DivisionHierarchyInter> = new Map(divisionHierarchy.map(i => [i.key, i]))