import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Checkbox, FormControlLabel, Input, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {makeApiCall, UseApiCallResponseData} from "../../hooks/CancellableApiCall";
import {UserResponse} from "../../data/UserResponse";
import {UserSelector} from "../UserSelector";
import {reduceTimeToSeconds} from "../../utils";
import {DivisionBan} from "../../data/DivisionBan";

interface IssueBanFormProps {
    divisionIdentifier: string,
    apiCall: UseApiCallResponseData<DivisionBan[]>
}

export default function IssueBanForm(props: IssueBanFormProps) {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("")
    const [isPermanent, setIsPermanent] = useState(false)
    const [user, setUser] = useState<UserResponse | null>(null)
    const [banLengthTimeType, setBanLengthTimeType] = useState("seconds")
    const [banLength, setBanLength] = useState(0)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant={"outlined"} color="info" onClick={handleClickOpen}>Issue Ban</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Issue Ban</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">User</Typography>
                    <UserSelector onUserSelected={setUser} user={user} extendedUserInformation={true}/>
                </DialogContent>
                <DialogContent>
                    <Stack spacing={2}>
                        <InputLabel>Ban Length</InputLabel>
                        <Input type={"number"} value={banLength} multiline rows={1} size="small" sx={{width: 500}}
                               onChange={(event) => setBanLength(Number(event.target.value))}
                        ></Input>
                        <Select sx={{width: 500}} value={banLengthTimeType} onChange={(event) => {
                            setBanLengthTimeType(event.target.value as string)
                        }}>
                            <MenuItem key={0} value={"seconds"}>Seconds</MenuItem>
                            <MenuItem key={1} value={"minutes"}>Minutes</MenuItem>
                            <MenuItem key={2} value={"hours"}>Hours</MenuItem>
                            <MenuItem key={3} value={"days"}>Days</MenuItem>
                            <MenuItem key={4} value={"weeks"}>Weeks</MenuItem>
                            <MenuItem key={5} value={"months"}>Months</MenuItem>
                        </Select>
                        <FormControlLabel label={"Permanent"} control={
                            <Checkbox onChange={(_checked) => setIsPermanent(!isPermanent)}
                                      checked={isPermanent}/>
                        }/>
                    </Stack>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>
                        Ban Reason
                    </DialogContentText>
                    <Input multiline rows={3} placeholder={"Enter reason..."} size="small" sx={{width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color={"error"} variant={"contained"} onClick={() => {
                        let finalBanLength = banLength
                        let finalBanTime = banLengthTimeType
                        if (isPermanent) {
                            finalBanLength = 60
                            finalBanTime = "months"
                        }
                        makeApiCall({
                            url: `/api/division/${props.divisionIdentifier}/ban`,
                            method: "POST",
                            body: {
                                communityId: user?.communityId,
                                reason: reason,
                                length: reduceTimeToSeconds(finalBanLength, finalBanTime)
                            },
                            onLoadedCallback: () => {
                                props.apiCall.refresh()
                                setOpen(false)
                            },
                            onError: () => {}
                        })
                    }}>Issue</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}