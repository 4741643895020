import * as React from 'react';
import {useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, Input, Snackbar} from "@mui/material";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {padDateElement} from "../../utils";

interface EditUserFormProps {
    communityId: string
}

type alertType = "success" | "error" | "info"

export default function CreateInactivityNoticeForm(props: EditUserFormProps) {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState("")
    const [startDate, setStateDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<alertType>("success")
    const [alertText, setAlertText] = useState("")
    const navigate = useNavigate()

    const setAlert = useCallback((type: alertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    const handleClose = () => {
        setOpen(false);
    };

    return <React.Fragment>
            <Button size="small" color={"success"} onClick={() => setOpen(true)}>Create New Notice</Button>
            <Dialog open={open} onClose={handleClose}>
                <Alert severity="info">You cannot edit the date once created, contact HR for lengths of more then two weeks</Alert>
                <DialogTitle>New Inactivity Notice</DialogTitle>
                <DialogContent>
                    <DialogContentText>Reason</DialogContentText>
                    <Input id={"reason"} value={reason} size="small" sx={{width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogContent>
                    <DialogContentText>Start Date</DialogContentText>
                    <DatePicker
                        defaultValue={startDate}
                        disablePast
                        views={['year', 'month', 'day']}
                        onChange={(event) => {setStateDate(event!!)}}
                    />
                </DialogContent>
                <DialogContent>
                    <DialogContentText>End Date</DialogContentText>
                    <DatePicker
                        defaultValue={endDate}
                        disablePast
                        views={['year', 'month', 'day']}
                        onChange={(event) => {setEndDate(event!!)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={handleClose}>Cancel</Button>
                    <Button variant={"contained"} onClick={() =>
                        makeApiCall({
                            url: `/api/activity/${props.communityId}/new`,
                            method: "POST",
                            body: {
                                startDate: startDate.year().toString() + "-" + padDateElement(startDate.month() + 1) + "-" + padDateElement(startDate.date()) + "T00:00",
                                endDate: endDate.year().toString() + "-" + padDateElement(endDate.month() + 1) + "-" + padDateElement(endDate.date()) + "T00:00",
                                note: reason
                            },
                            onLoadedCallback: () => {
                                setAlert("success", "Created")
                                handleClose()
                                navigate(`/user/${props.communityId}`)
                            },
                            onError: () => {
                                setAlert("error", "Failed to create")
                            }
                        })
                    }>Submit</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                    {alertText}
                </Alert>
            </Snackbar>
        </React.Fragment>
}