import {Box, Popper, styled} from "@mui/material";
import {forwardRef} from "react";

export const PopperArrow = forwardRef((props, ref) =>
    <Box component="span" className="arrow" ref={ref} sx={{
        position: 'absolute',
        fontSize: 7,
        '&::before': {
            position: 'absolute',
            content: '""',
            width: "10px",
            height: "10px",
            backgroundColor: "inherit",
            backgroundImage: "inherit",
            transform: "rotate(45deg)",
            transformOrigin: "center",
        },
        backgroundColor: "inherit",
        backgroundImage: "inherit",
    }}/>)

export const StyledPopper = styled(Popper)(() => ({
    '&[data-popper-placement*="bottom"] .arrow': {
        top: 0,
        left: 0,
        marginTop: '-5px',
        width: "10px",
        height: 0,
    },
    '&[data-popper-placement*="top"] .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '5px',
        width: "10px",
        height: 0,
    },
    '&[data-popper-placement*="right"] .arrow': {
        left: 0,
        marginLeft: '-5px',
        width: 0,
        height: "10px",
    },
    '&[data-popper-placement*="left"] .arrow': {
        right: 0,
        marginRight: '5px',
        width: 0,
        height: "10px",
    },
}))