import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Input} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {makeApiCall} from "../../hooks/CancellableApiCall";
import {RecordNoteResponse} from "../../data/RecordNoteResponse";
import EditNoteIcon from "@mui/icons-material/EditNote";

interface EditInfractionFormProps {
    searchRoot: string,
    infraction: RecordNoteResponse,
}

export default function EditInfractionForm(props: EditInfractionFormProps) {
    const [open, setOpen] = useState(false);
    const [reason, setReason] = useState(props.infraction.reason)
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <EditNoteIcon color={"info"} onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Infraction</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Reason for Infraction
                    </DialogContentText>
                    <Input multiline rows={3} value={reason} size="small" sx={{width: 500}}
                           onChange={(event) => setReason(event.target.value)}
                    ></Input>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color={"info"} variant={"contained"} onClick={() =>
                        makeApiCall({
                            url: `/api/records/edit/${props.infraction.id}`,
                            method: "POST",
                            body: {
                                newReason: reason,
                            },
                            onLoadedCallback: () => {
                                navigate(`/records/${props.searchRoot}`)
                            },
                            onError: () => {
                                handleClose()
                            }
                        })
                    }>Edit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}