import React, {ReactElement, ReactNode} from "react";
import {Alert, Box, Button, Paper, Stack, Tab} from "@mui/material";
import {Link as RouterLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import useUser, {UserContextProps} from "../hooks/useUser";
import useApiCall from "../hooks/CancellableApiCall";
import {LoadingContent} from "../components/LoadingContent";
import {ProfileInfo} from "../components/profile/ProfileInfo";
import {ProfileResponse} from "../data/ProfileResponse";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Error} from "@mui/icons-material";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import {EmployeeInfractions} from "../components/record/EmployeeInfractions";
import {EmployeeNotes} from "../components/record/EmployeeNotes";
import {EmployeeComplaints} from "../components/record/EmployeeComplaints";

interface EmployeeRecordProps {
    employeeInfo: ProfileResponse
    initialSearchParam: string
    user: UserContextProps
}

interface TabContent {
    title: string
    path?: RecordPageTab
    icon: ReactElement
    content: ReactNode
}

type RecordPageTab = undefined | "infractions" | "notes" | "complaints"

function EmployeeRecord(props: EmployeeRecordProps) {
    const tabs: TabContent[] = []
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const params = useParams()

    tabs.push({
        title: "Record Notes",
        path: "notes",
        icon: <FindInPageIcon/>,
        content: <EmployeeNotes searchRoot={props.initialSearchParam} user={props.user} employee={props.employeeInfo}/>
    })

    if (props.user.hasPermissions(["records:viewInfractions"])) {
        tabs.push({
            title: "Infractions",
            path: "infractions",
            icon: <Error/>,
            content: <EmployeeInfractions searchRoot={props.initialSearchParam} user={props.user}
                                          employee={props.employeeInfo}/>
        })
    }

    if (props.user.hasPermissions(["records:viewComplaints"])) {
        tabs.push({
            title: "Complaints",
            path: "complaints",
            icon: <GavelOutlinedIcon/>,
            content: <EmployeeComplaints employee={props.employeeInfo}/>
        })
    }

    const tabIndex = tabs.findIndex((t) => t.path === params.tab) ?? 0

    function changeTab(index: number) {
        const tabValue = tabs[index].path
        const newUrl = `/records/${props.initialSearchParam}/${tabValue}`
        navigate(newUrl)
    }

    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px",
    }}>
        <ProfileInfo data={props.employeeInfo} inactivityNotice={false}/>
        <TabContext value={tabIndex.toString()}>
            <Box sx={{borderBottom: 1, borderColor: "divider", mt:3}}>
                <TabList onChange={(_, newValue) => {
                    setSearchParams(searchParams)
                    changeTab(parseInt(newValue))
                }} variant="scrollable"
                         scrollButtons="auto">
                    {tabs.map((data, idx) =>
                        <Tab icon={data.icon} label={data.title} value={idx.toString()} key={data.title}/>
                    )}
                </TabList>
            </Box>
            {tabs.map((data, idx) =>
                <TabPanel value={idx.toString()} key={data.title}>
                    {tabIndex === idx ? data.content : <></>}
                </TabPanel>
            )}
        </TabContext>
    </Paper>
}

export const RecordsPage = () => {
    const params = useParams()
    const searchData = useApiCall<ProfileResponse>({
        url: `/api/user/${params.search}/profile`
    })
    const userData = useUser()

    let pageContent
    if (searchData.isError) {
        pageContent = <Stack spacing={2}>
            <Alert severity="warning">No employees matching the search were found</Alert>
            <Button component={RouterLink} to={`/record-search`} color="info">Return</Button>
        </Stack>
    } else if (!searchData.data) {
        pageContent = <Stack spacing={2}>
            <Alert severity="error">A error during querying has occurred</Alert>
            <Button component={RouterLink} to={`/record-search`} color="info">Return</Button>
        </Stack>
    } else if (searchData.data.communityId === userData.user?.communityId) {
        pageContent = <Stack spacing={2}>
            <Alert severity="error">You cannot query your own record</Alert>
            <Button component={RouterLink} to={`/record-search`} color="info">Return</Button>
        </Stack>
    } else if (!userData.hasPermissions("records:viewRecords")) {
        pageContent = <Stack spacing={2}>
            <Alert severity="error">You cannot query records</Alert>
            <Button component={RouterLink} to={`/record-search`} color="info">Return</Button>
        </Stack>
    } else {
        pageContent =
            <EmployeeRecord user={userData} initialSearchParam={params.search ?? ""} employeeInfo={searchData.data}/>
    }

    return <LoadingContent
        isLoading={(searchData.isLoading || userData.isLoading || !userData.user)}>
        {pageContent}
    </LoadingContent>
}