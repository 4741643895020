import {LoadingContent} from "../LoadingContent";
import React, {useCallback, useState} from "react";
import useApiCall, {makeApiCall} from "../../hooks/CancellableApiCall";
import {DivisionRoles} from "../../data/DivisionRoles";
import {ProfileResponse} from "../../data/ProfileResponse";
import {divisionIdentifierToDisplayName} from "../../utils";
import {Alert, Divider, Grid, InputLabel, MenuItem, Select, Snackbar, Stack} from "@mui/material";
import useUser, {UserContextProps} from "../../hooks/useUser";
import {AlertType} from "../../data/AlertType";
import {RankResponse} from "../../data/RankResponse";
import {DivisionHierarchy} from "../../data/DivisionHierarchy";
import {CertificationResponse} from "../../data/CertificationResponse";

interface EmployeeUserRanksProps {
    employee: ProfileResponse
}

interface EmployeeUserRanksContentProps extends EmployeeUserRanksProps {
    divisionRoles: { [division: string]: DivisionRoles[] },
    user: UserContextProps,
    employee: ProfileResponse,
    ranks: RankResponse[],
    userDivisionRoles: { [divisionIdentifier: string]: DivisionRoles[] },
    certifications: CertificationResponse[]
}

interface division {
    identifier: string,
    displayName: string,
    defaultRole: string,
    roles: DivisionRoles[]
}

interface DivisionRoleSelectorProps {
    division: division,
    user: UserContextProps,
    employee: ProfileResponse
    userDivisionRoles: { [divisionIdentifier: string]: DivisionRoles[] }
}

interface CertificationSelectorProps {
    certification: CertificationResponse
    communityId: string
    currentState: boolean
    alertFunc: (type: AlertType, text: string) => void
}

const DivisionRoleSelector = (props: DivisionRoleSelectorProps) => {
    const [rankValue, setRankValue] = useState(props.division.defaultRole)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    let access = false

    let divisionSelectorContent = <></>
    if (props.user.hasPermissions([`${props.division.identifier}-members:manage`])) {
        let divisionSelectionOptions = props.division.roles.filter(role => {
            if (props.user.hasPermissions(`${props.division.identifier}-pseudo:head`)) {
                return true
            } else if (props.user.hasPermissions(`${props.division.identifier}-pseudo:command`)) {
                return (DivisionHierarchy.get(role.immunity)?.level ?? 0) < (DivisionHierarchy.get("command")?.level ?? 1)
            } else if (props.user.hasPermissions(`${props.division.identifier}-pseudo:junior_command`)) {
                return (DivisionHierarchy.get(role.immunity)?.level ?? 0) < (DivisionHierarchy.get("assistant")?.level ?? 1)
            } else if (props.userDivisionRoles[props.division.identifier]) {
                return (DivisionHierarchy.get(role.immunity ?? "non_member")?.level ?? 0) < (DivisionHierarchy.get(props.userDivisionRoles[props.division.identifier][0]?.immunity ?? "non_member")?.level ?? 1)
            } else {
                return false
            }
        }).map((role, id) => {
            if (role.identifier === props.division.defaultRole) { access = true }
            return <MenuItem key={id} value={role.identifier}>{role.displayName}</MenuItem>
        })

        if (access) {
            divisionSelectorContent = <Stack sx={{padding: 1}}>
                <InputLabel id="division-select-label">{props.division.displayName}</InputLabel>
                <Select size="small" sx={{width: 500}} id="division-select" value={rankValue} variant="standard"
                        onChange={(event) =>
                            makeApiCall({
                                url: `/api/division/${props.division.identifier}/members`,
                                method: "POST",
                                body: {
                                    employeeCommunityId: props.employee.communityId,
                                    newRoleIdentifier: event.target.value
                                },
                                onLoadedCallback: () => {
                                    setRankValue(event.target.value)
                                    setAlert("success", "Rank set")
                                },
                                onError: () => {
                                    setAlert("error", "You are unable to do this action")
                                }
                            })
                        }>
                    {divisionSelectionOptions}
                </Select>
                <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
                    <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                        {alertText}
                    </Alert>
                </Snackbar>
            </Stack>
        }
    }

    return divisionSelectorContent
}

const CertificationSelector = (props: CertificationSelectorProps) => {
    const [currentState, setCurrentState] = useState(props.currentState)

    return <Stack sx={{padding: 1}}>
            <InputLabel id="division-select-label">{props.certification.displayName}</InputLabel>
            <Select size="small" sx={{width: 500}} id="division-select" value={currentState} variant="standard"
                    onChange={(event) =>
                        makeApiCall({
                            url: `/api/certifications/update`,
                            method: "PATCH",
                            body: {
                                communityId: props.communityId,
                                identifier: props.certification.identifier,
                                newStatus: event.target.value
                            },
                            onLoadedCallback: () => {
                                setCurrentState(event.target.value as boolean)
                                props.alertFunc("success", "Changed cert status")
                            },
                            onError: () => {
                                props.alertFunc("error", "Unable to change cert status")
                            }
                        })
                    }>
                <MenuItem key={1} value={"true"}>Yes</MenuItem>
                <MenuItem key={0} value={"false"}>No</MenuItem>
            </Select>
        </Stack>
}

const EmployeeUserRanksContent = (props: EmployeeUserRanksContentProps) => {
    const [rankId, setRankId] = useState(props.employee.rank?.rankId ?? 0)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const [enabledStatus, setEnabledStatus] = useState(props.employee.enabled)

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    let policeRankSelector = <></>
    if ((props.ranks.find(r => {return r.identifier === props.employee.rank?.identifier}) !== undefined) && props.user.hasPermissions("user:rank")) {
        policeRankSelector = <>
            <Divider>General Ranks</Divider>
            <InputLabel id="rank-select-label">Rank</InputLabel>
            <Select size="small" sx={{width: 500}} id="rank-select" value={rankId} variant="standard"
                    onChange={(event) =>
                        makeApiCall({
                            url: `/api/user/${props.employee.communityId}/rank`,
                            method: "PUT",
                            body: {rankId: event.target.value as number},
                            onLoadedCallback: () => {
                                setRankId(event.target.value as number)
                                setAlert("success", "Rank set")
                            },
                            onError: () => {
                                setAlert("error", "You are unable to do this action")
                            }
                        })
                    }>
                {props.ranks.map((rank, id) => {
                    return <MenuItem key={id} value={rank.rankId}>{rank.displayName}</MenuItem>
                })}
            </Select>
        </>
    }

    let accountManagement = <></>
    if (props.user.hasPermissions("admin:disable")) {
        accountManagement = <>
            <Divider>Account</Divider>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               <Stack sx={{padding: 1}}>
                   <InputLabel id="enabled-select-label">Status</InputLabel>
                   <Select size="small" sx={{width: 500}} id="enabled-select" value={enabledStatus} variant="standard" onChange={(event) =>
                       makeApiCall({
                           url: `/api/user/${props.employee.communityId}/enabled`,
                           method: "PATCH",
                           body: {state: event.target.value as boolean},
                           onLoadedCallback: () => {
                               setEnabledStatus(event.target.value as boolean)
                               setAlert("success", "Enabled Status Edited")
                           },
                           onError: () => {
                               setAlert("error", "You are unable to do this action")
                           }
                       })
                   }>
                       <MenuItem key={1} value={"true"}>Enabled</MenuItem>
                       <MenuItem key={0} value={"false"}>Disabled</MenuItem>
                   </Select>
               </Stack>
            </Grid>
        </>
    }

    let divisions: division[] = []
    Object.entries(props.divisionRoles).forEach(([division, divisionRoles]) => {
        if (!divisions.find(existingDivision => existingDivision.identifier === division)) {
            divisions.push({
                identifier: division,
                displayName: divisionIdentifierToDisplayName(division),
                defaultRole: "none",
                roles: divisionRoles
            })
            if (!divisionRoles.find(existingRoles => existingRoles.identifier === "none")) {
                divisionRoles.push({
                    id: -1,
                    identifier: "none",
                    displayName: "None",
                    roleId: -1 ,
                    divisionIdentifier: division,
                    immunity: "non_member"
                })
            }
            divisionRoles.forEach(role => {
                if (props.employee.roles.find(employeeRoles => employeeRoles.identifier === role.identifier)) {
                    divisions[divisions.length - 1].defaultRole = role.identifier
                }
            })
        }
    });

    return <Stack sx={{overflowX:"auto"}} spacing={1}>
        {policeRankSelector}
        {props.user.user?.permissions.find(perm => {return perm.includes("members:manage")}) !== undefined ?
            <>
                <Divider>Division Ranks</Divider>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {divisions.map(division => {
                        return <DivisionRoleSelector key={division.identifier} employee={props.employee} user={props.user} division={division} userDivisionRoles={props.userDivisionRoles}/>
                    })}
                </Grid>
            </> : <></>
        }
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
        {props.user.user?.permissions.find(perm => {return perm.includes("certification:manage")}) !== undefined ?
            <>
                <Divider>Certifications</Divider>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {
                        props.certifications.map((cert, idx) => {
                            if (props.user.hasPermissions(`${cert.divisionIdentifier}-certification:manage`)) {
                                return <CertificationSelector key={idx} certification={cert} communityId={props.employee.communityId}
                                          currentState={props.employee.certifications.find(ucert => {
                                              return ucert.identifier === cert.identifier}) !== undefined}
                                                              alertFunc={setAlert}
                                />
                            } else {
                                return <></>
                            }
                        })
                    }
                </Grid>
            </> : <></>
        }
        {accountManagement}
    </Stack>
}

export const EmployeeUserRanks = (props: EmployeeUserRanksProps) => {
    const user = useUser()
    const loadedDivisionRoles  = useApiCall<{ [division: string]: DivisionRoles[] }>({
        initialUrl: `/api/division/list-roles`
    })

    const loadRanks = useApiCall<RankResponse[]>({
        initialUrl: "/api/rank/subordinates"
    })

    const loadUserDivisionRoles = useApiCall<{ [divisionIdentifier: string]: DivisionRoles[] }>({
        initialUrl: `/api/user/${user.user?.communityId}/roles`
    })

    const loadCertifications = useApiCall<CertificationResponse[]>({
        initialUrl: "/api/certifications/"
    })

    return (
        <LoadingContent isLoading={loadedDivisionRoles.isLoading || !loadedDivisionRoles.data ||
            user.isLoading || loadRanks.isLoading || !loadRanks.data || loadUserDivisionRoles.isLoading || loadCertifications.isLoading ||
            !loadCertifications.data}>
            <EmployeeUserRanksContent
                user={user}
                employee={props.employee}
                divisionRoles={loadedDivisionRoles.data!!}
                ranks={loadRanks.data!!}
                userDivisionRoles={loadUserDivisionRoles.data!!}
                certifications={loadCertifications.data!!}/>
        </LoadingContent>
    )
}