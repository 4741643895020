import React from "react";
import {Box, Button, Chip, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../../components/LoadingContent";
import {Link as RouterLink, useParams} from "react-router-dom";
import {DetailedUserAssessmentInfoResponse} from "../../data/applications/DetailedUserAssessmentInfoResponse";

interface ApplicationMarkingPageContentProps {
    assessments: DetailedUserAssessmentInfoResponse[]
    careerId: string
}

function ApplicationMarkingPageContent(props: ApplicationMarkingPageContentProps) {
    return <Paper variant={"outlined"} style={{
        marginTop: "16px",
        padding: "16px"
    }}>
        <Box sx={{overflowX: "auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Stage</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.assessments?.map(assessment => {
                            let statusColor
                            let statusMsg
                            if (!assessment.submitted) {
                                statusColor = "#9333a4"
                                statusMsg = "Pending"
                                return <TableRow>
                                    <TableCell> {assessment.stageName} </TableCell>
                                    <TableCell> <Chip color={"primary"} style={{backgroundColor: statusColor, height: "20px"}}
                                                      label={statusMsg}/> </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            } else {
                                if (assessment.passed === null) {
                                    statusColor = "#f8ac59"
                                    statusMsg = "Pending Manual Marking"
                                } else {
                                    if (!assessment.passed) {
                                        statusColor = "red"
                                        statusMsg = "Failed"
                                    } else {
                                        statusColor = "green"
                                        statusMsg = "Passed"
                                    }
                                }

                                return <TableRow>
                                    <TableCell> {assessment.stageName} </TableCell>
                                    <TableCell> <Chip color={"primary"} style={{backgroundColor: statusColor, height: "20px"}}
                                                      label={statusMsg}/> </TableCell>
                                    <TableCell><Button component={RouterLink}
                                                       to={`/assessments/grade/${assessment.id}`}
                                                       color="info">View Assessment</Button></TableCell>
                                </TableRow>
                            }
                        }
                    )
                }
            </TableBody>
        </Table>
        </Box>
        <Button sx={{mt:1}} component={RouterLink}
                to={`/careers/overview/${props.careerId}/passed`}
                color="info">Return</Button>
    </Paper>
}

export const ApplicationMarkingPage = () => {
    const params = useParams()
    const assessments = useApiCall<DetailedUserAssessmentInfoResponse[]>({
        url: `/api/careers/${params.careerId}/${params.communityId}/mark`
    })

    return <LoadingContent
        isLoading={(assessments.isLoading || !assessments.data)}>
        <ApplicationMarkingPageContent
            assessments={assessments.data!!}
            careerId = {params.careerId ?? ""}
        />
    </LoadingContent>
}