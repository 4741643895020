interface HelpdeskTicketStatusInter {
    key: string
    displayName: string
}

export const helpdeskTicketStatus: HelpdeskTicketStatusInter[] = [
    { key: "unclaimed", displayName: "Unclaimed" },
    { key: "claimed", displayName: "Claimed" },
    { key: "awaiting_assignee_response", displayName: "Awaiting Assignee Response" },
    { key: "awaiting_creator_response", displayName: "Awaiting Creator Response" },
    { key: "reassigned", displayName: "Reassigned" },
    { key: "closed", displayName: "Closed" },
]

export const HelpdeskTicketStatus: Map<string, HelpdeskTicketStatusInter> = new Map(helpdeskTicketStatus.map(i => [i.key, i]))