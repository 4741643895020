import {Link as RouterLink} from "react-router-dom";
import {Link, Stack, Typography} from "@mui/material";
import {Check, Close, Star} from "@mui/icons-material";

interface Question {
    id: number
    order?: number
    required?: boolean
    isAnswered?: boolean
    awardedPoints?: number | null
    points?: number
}

interface QuestionListProps {
    questions: Question[]
    linkUrlBuilder?: (question: Question) => string
    onClick?: (question: Question) => void
    selectedQuestion?: number
    submitted?: boolean
}

export const QuestionList = (props: QuestionListProps) => {
    return <Stack spacing={1} alignItems="flex-start">
        {props.questions.length > 0 ?
            props.questions.map((question, idx) => {
                    let icon = <></>
                    if (question.required) {
                        icon = <Star fontSize="inherit"/>
                    } else if (question.isAnswered && !props.submitted) {
                        icon = <Check fontSize="inherit" color="success"/>
                    } else if (question.awardedPoints !== null && question.awardedPoints !== undefined && question.points) {
                        if (question.awardedPoints === 0) {
                            icon = <Close fontSize="inherit" color="error"/>
                        } else if (question.awardedPoints < question.points) {
                            icon = <Check fontSize="inherit" color="warning"/>
                        } else {
                            icon = <Check fontSize="inherit" color="success"/>
                        }
                    }
                    let content = <></>
                    if (props.onClick) {
                        content = <Link key={question.id} component="button" color="inherit"
                                        onClick={() => props.onClick!(question)}
                                        sx={{fontWeight: question.id === props.selectedQuestion ? "bold" : "normal"}}
                                        underline="none">
                            Question {question.order ?? (idx + 1)} {icon}
                        </Link>
                    } else if (props.linkUrlBuilder) {
                        content = <Link key={question.id} component={RouterLink} to={props.linkUrlBuilder(question)}
                                        color="inherit"
                                        sx={{fontWeight: question.id === props.selectedQuestion ? "bold" : "normal"}}
                                        underline="none">
                            Question {question.order ?? (idx + 1)} {icon}
                        </Link>
                    }
                    return content
                }
            ) :
            <Typography><em>No Questions</em></Typography>
        }
    </Stack>
}