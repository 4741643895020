import {
    Button,
    Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {QuestionType, QuestionTypeStrings} from "../../data/QuestionType";
import {NUM_QUESTIONS_MAX, NUM_QUESTIONS_MIN, POINTS_MAX, POINTS_MIN} from "../../data/assessment/AssessmentConstants";
import {TextEditor} from "../editor/TextEditor";
import {EditorContent} from "../editor/EditorContent";
import React, {useRef, useState} from "react";
import {LexicalEditor} from "lexical";
import {$generateHtmlFromNodes} from '@lexical/html';
import {AlertType} from "../../data/AlertType";
import {AssessmentAnswer} from "../../data/assessment/AssessmentAnswer";
import {AssessmentQuestionResponse} from "../../data/assessment/AssessmentQuestionResponse";
import {AssessmentInfoResponse} from "../../data/assessment/AssessmentInfoResponse";
import {clamp} from "../../utils";
import {AssessmentQuestionRequest} from "../../data/assessment/AssessmentQuestionRequest";
import {EditAnswerDialog} from "./EditAnswerDialog";


interface QuestionEditorProps {
    assessment: AssessmentInfoResponse
    question?: AssessmentQuestionResponse
    editing?: boolean
    handleSubmit: (question: AssessmentQuestionRequest) => void
    handleDeleteQuestion?: () => void
    setAlert: (type: AlertType, text: string) => void
}

interface EditableAssessmentAnswer extends AssessmentAnswer {
    edited: Boolean
}

const toEditableAnswer = (answer: AssessmentAnswer, edited?: boolean): EditableAssessmentAnswer => {
    return {
        ...answer,
        edited: edited === true
    }
}

export const QuestionEditor = ({
                                   assessment,
                                   question,
                                   editing,
                                   handleSubmit,
                                   setAlert,
                                   handleDeleteQuestion
                               }: QuestionEditorProps) => {
    const questionTypes = assessment.isPractical ? [QuestionType.PRACTICAL] : [QuestionType.MULTIPLE_CHOICE, QuestionType.SELECT_MULTIPLE, QuestionType.SHORT_RESPONSE]
    const editorRef = useRef<LexicalEditor>(null)
    const markingGuidelinesEditorRef = useRef<LexicalEditor>(null)
    const [questionType, _setQuestionType] = useState(question?.questionType ?? questionTypes[0])
    const [points, _setPoints] = useState(question?.points ?? 10)
    const [order, _setOrder] = useState<number>(question?.order ?? 0)
    const [required, setRequired] = useState(question?.required ?? false)
    const [answers, setAnswers] = useState<EditableAssessmentAnswer[]>(question?.answers.map(a => toEditableAnswer(a)) ?? [])
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showDeleteAnswerDialog, setShowDeleteAnswerDialog] = useState(false)
    const [showDeleteQuestionDialog, setShowDeleteQuestionDialog] = useState(false)
    const [dialogAnswer, setDialogAnswer] = useState<AssessmentAnswer>()
    const [editAnswerIdx, setEditAnswerIdx] = useState<number>()
    const [deletedAnswers, setDeletedAnswers] = useState<number[]>([])

    const submit = (text: string, markingGuidelines: string, questionType: QuestionType, points: number, answers: EditableAssessmentAnswer[], deletedAnswers: number[], required: boolean, order: number) => {
        const correctAnswers = answers.filter(a => a.correct).length
        if (answers.length < 2 && (questionType === QuestionType.MULTIPLE_CHOICE || questionType === QuestionType.SELECT_MULTIPLE)) {
            setAlert("error", "Too few answers")
            return
        }
        if ((correctAnswers > 1 && questionType === QuestionType.MULTIPLE_CHOICE) || (correctAnswers >= answers.length && questionType === QuestionType.SELECT_MULTIPLE)) {
            setAlert("error", "Too many correct answers")
            return
        }
        if (correctAnswers < 1 && (questionType === QuestionType.MULTIPLE_CHOICE || questionType === QuestionType.SELECT_MULTIPLE)) {
            setAlert("error", "Too few correct answers")
            return
        }
        handleSubmit({
            questionType: questionType,
            text: text,
            markingGuidelines: markingGuidelines,
            points: points,
            answers: answers.filter(a => a.id === -1),
            editedAnswers: answers.filter(a => a.edited),
            deletedAnswers: deletedAnswers,
            order: order > 0 ? order : null,
            required: required,
        })
    }

    const setPoints = (points: number) => {
        _setPoints(clamp(points, POINTS_MIN, POINTS_MAX))
    }

    const setOrder = (order: number) => {
        _setOrder(clamp(order, NUM_QUESTIONS_MIN, NUM_QUESTIONS_MAX))
    }

    const setQuestionType = (questionType: QuestionType) => {
        if (editing) {
            return
        }
        setAnswers([])
        _setQuestionType(questionType)
    }

    return <>
        <Stack spacing={2} sx={{marginRight: "16px"}}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} lg="auto">
                    <Typography variant="h4">{assessment.title}: Edit Question</Typography>
                </Grid>
                {editing ?
                    <Grid item xs={12} lg="auto">
                        <Button disabled={assessment.isPublished} variant="text" color="error"
                                onClick={() => setShowDeleteQuestionDialog(true)}>
                            Delete
                        </Button>
                    </Grid> :
                    <></>
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={3} xs={12}>
                    <FormControl>
                        <InputLabel id="question-type-select-label">Question Type</InputLabel>
                        <Select id="quetion-type-select" value={questionType}
                                onChange={(event) => setQuestionType(event.target.value as QuestionType)}
                                label="Question Type"
                                disabled={editing || assessment.isPublished}>
                            {questionTypes.map(qt => <MenuItem key={QuestionType[qt]}
                                                               value={qt}>{QuestionTypeStrings.get(qt)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <FormControl>
                        <Tooltip title={"How many points the question is worth"}>
                            <InputLabel id="question-points-input-label">Points</InputLabel>
                        </Tooltip>
                        <Input id="question-points-input" value={points} size="small"
                               disabled={assessment.isPublished}
                               sx={{width: "8ch"}}
                               onChange={(event) => {
                                   setPoints(event.target.value === "" ? 10 : Number(event.target.value))
                               }}
                               onBlur={() => setPoints(points)}
                               inputProps={{
                                   step: 1,
                                   min: POINTS_MIN,
                                   max: POINTS_MAX,
                                   type: "number"
                               }}/>
                    </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <FormControl>
                        <Tooltip title={"Used to order questions in a non-randomized assessment"}>
                            <InputLabel id="question-number-input-label">Number</InputLabel>
                        </Tooltip>
                        <Input id="question-number-input" value={order} size="small" sx={{width: "8ch"}}
                               onChange={(event) => {
                                   setOrder(event.target.value === "" ? 0 : Number(event.target.value))
                               }}
                               onBlur={() => setOrder(order)}
                               disabled={assessment.isPublished}
                               inputProps={{
                                   step: 1,
                                   min: NUM_QUESTIONS_MIN,
                                   max: NUM_QUESTIONS_MAX,
                                   type: "number"
                               }}/>
                    </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Tooltip title={"Requires the question to be included in the assessment"}>
                        <FormControlLabel
                            control={<Checkbox checked={required} disabled={assessment.isPublished}
                                               onChange={(_, checked) => setRequired(checked)}/>}
                            label={"Required"}/>
                    </Tooltip>
                </Grid>
            </Grid>
            <Typography variant="h5">Question</Typography>
            {assessment.isPublished ?
                <EditorContent content={question?.text ?? ""}/> :
                <TextEditor ref={editorRef} initialContent={question?.text}/>
            }
            {questionType === QuestionType.SHORT_RESPONSE || questionType === QuestionType.PRACTICAL ?
                <>
                    <Typography variant="h5">Marking Guidelines</Typography>
                    <TextEditor ref={markingGuidelinesEditorRef} initialContent={question?.markingGuidelines}/>
                </> :
                <></>
            }
            {questionType === QuestionType.MULTIPLE_CHOICE || questionType === QuestionType.SELECT_MULTIPLE ?
                <Button variant="contained" disabled={assessment.isPublished} onClick={() => {
                    setDialogAnswer(undefined)
                    setShowEditDialog(true)
                }}>Add Answer</Button> :
                <></>
            }
            <ol>
                {answers.map((answer, idx) =>
                    <li key={idx}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Button disabled={assessment.isPublished} variant="text" onClick={() => {
                                setDialogAnswer(answer)
                                setEditAnswerIdx(idx)
                                setShowEditDialog(true)
                            }}>Edit</Button>
                            <Button disabled={assessment.isPublished} variant="text" color="error" onClick={() => {
                                setDialogAnswer(answer)
                                setShowDeleteAnswerDialog(true)
                            }}>Delete</Button>
                            {answer.correct ? <Typography>Correct Answer</Typography> : <></>}
                        </Stack>
                        <EditorContent content={answer.text}/>
                    </li>)}
            </ol>
            <Stack direction="row" spacing={2}>
                <Button disabled={assessment.isPublished} variant="contained" onClick={() => {
                    let questionText = ""
                    let markingGuidelines = ""
                    if (editorRef.current) {
                        editorRef.current.getEditorState().read(() => {
                            questionText = $generateHtmlFromNodes(editorRef.current!)
                        })
                    }
                    if (markingGuidelinesEditorRef.current) {
                        markingGuidelinesEditorRef.current.getEditorState().read(() => {
                            markingGuidelines = $generateHtmlFromNodes(markingGuidelinesEditorRef.current!)
                        })
                    }
                    submit(questionText, markingGuidelines, questionType, points, answers, deletedAnswers, required, order)
                }}>{editing ? "Update" : "Create"} Question</Button>
            </Stack>
        </Stack>
        <EditAnswerDialog answer={dialogAnswer} open={showEditDialog} onClose={() => {
            setShowEditDialog(false)
            setDialogAnswer(undefined)
            setEditAnswerIdx(undefined)
        }} onSubmit={editedAnswer => {
            const answer = toEditableAnswer(editedAnswer, editedAnswer.id > 0)
            const newAnswers = answers.map(_ => _)
            if (editAnswerIdx !== undefined) {
                newAnswers[editAnswerIdx] = answer
            } else {
                newAnswers.push(answer)
            }
            setAnswers(newAnswers)
            setShowEditDialog(false)
            setDialogAnswer(undefined)
            setEditAnswerIdx(undefined)
        }}/>
        <Dialog open={showDeleteAnswerDialog} onClose={() => setShowDeleteAnswerDialog(false)}>
            <DialogTitle>Delete Answer</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this answer?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setDialogAnswer(undefined)
                    setShowDeleteAnswerDialog(false)
                }}>Cancel</Button>
                <Button color="error" onClick={() => {
                    if (dialogAnswer) {
                        if (dialogAnswer.id > 0) {
                            const newDeletedAnswers = deletedAnswers.map(_ => _)
                            newDeletedAnswers.push(dialogAnswer.id)
                            setDeletedAnswers(newDeletedAnswers)
                        }
                        setAnswers(answers.filter(a => a !== dialogAnswer))
                    }
                    setDialogAnswer(undefined)
                    setShowDeleteAnswerDialog(false)
                }}>Delete</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={showDeleteQuestionDialog} onClose={() => setShowDeleteQuestionDialog(false)}>
            <DialogTitle>Delete Question</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this question?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setShowDeleteQuestionDialog(false)
                }}>Cancel</Button>
                <Button color="error" onClick={() => {
                    if (handleDeleteQuestion) {
                        handleDeleteQuestion()
                    }
                    setShowDeleteQuestionDialog(false)
                }}>Delete</Button>
            </DialogActions>
        </Dialog>
    </>
}