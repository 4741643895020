import {Box, Grid, Paper, Tab} from "@mui/material";
import {ProfileInfo} from "../components/profile/ProfileInfo";
import {Assignment, Info, MilitaryTech} from "@mui/icons-material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {EmployeeDetails} from "../components/profile/EmployeeDetails";
import useUser from "../hooks/useUser";
import useApiCall from "../hooks/CancellableApiCall";
import {Navigate, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {EmployeeAwards} from "../components/profile/EmployeeAwards";
import {AccountResponse} from "../data/AccountResponse";
import {ProfileResponse} from "../data/ProfileResponse";
import {EmployeeObservationReports} from "../components/profile/EmployeeObservationReports";
import {ReactElement, ReactNode, useState} from "react";
import {generateProfilePath, isSteamId, steamIdToCommunityId} from "../utils";
import {LoadingContent} from "../components/LoadingContent";
import {AwardedAwardResponse} from "../data/AwardResponse";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DvrIcon from '@mui/icons-material/Dvr';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {EmployeeUserRanks} from "../components/profile/EmployeeUserRanks";
import {EmployeeRecords} from "../components/profile/EmployeeRecords";
import {EmployeeActivity} from "../components/profile/EmployeeActivity";
import {EmployeeRankLogs} from "../components/profile/EmployeeRankLogs";

function ProfilePageWithRedirect() {
    const params = useParams()

    if (params.communityId && isSteamId(params.communityId)) {
        let communityId = steamIdToCommunityId(params.communityId)
        return (
            <Navigate to={generateProfilePath(communityId)}/>
        )
    } else {
        return <ProfilePage communityId={params.communityId}/>
    }
}

interface ProfilePageProps {
    communityId: string | undefined
}

function ProfilePage(props: ProfilePageProps) {
    const userData = useUser()

    const loadData = useApiCall<ProfileResponse>({
        url: `/api/user/${props.communityId}/profile`
    })

    const loadActivity = useApiCall<boolean>({
        url: `/api/activity/${props.communityId}/active`
    })

    return <LoadingContent isLoading={loadData.isLoading || !loadData.data || userData.isLoading || !userData.user}>
        <ProfilePageContent self={userData.user!} data={loadData.data!} inactivity={loadActivity.data!}/>
    </LoadingContent>
}

interface ProfilePageContentProps {
    self: AccountResponse
    data: ProfileResponse
    inactivity: boolean
}

interface TabContent {
    title: string
    path?: ProfilePageTab
    icon: ReactElement
    content: ReactNode
}

export type ProfilePageTab =
    undefined
    | "awards-medals"
    | "observation-reports"
    | "infractions"
    | "activity"
    | "user-ranks"
    | "user-moderation"
    | "rank-logs"

const ProfilePageContent = (props: ProfilePageContentProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const params = useParams()
    const user = useUser()
    const [loadAwards, setLoadAwards] = useState(false)
    const awards = useApiCall<AwardedAwardResponse[]>({
        initialUrl: `/api/user/${props.data.communityId}/awards`,
        deferLoading: !loadAwards,
    })

    const tabs: TabContent[] = []
    tabs.push({
        title: props.data.nick,
        icon: <Info/>,
        content: <EmployeeDetails data={props.data}/>
    })
    if (user.hasPermissions("observationReport:list") || props.self.communityId === props.data.communityId) {
        tabs.push({
            title: "Observation Reports",
            path: "observation-reports",
            icon: <Assignment/>,
            content: <EmployeeObservationReports self={props.self} user={props.data}/>
        })
    }
    tabs.push({
        title: "Awards & Medals",
        path: "awards-medals",
        icon: <MilitaryTech/>,
        content: <EmployeeAwards awards={awards.data ?? props.data.awards} startLoading={() => setLoadAwards(true)}/>
    })
    if (user.hasPermissions("inactivityNotice:view") || props.self.communityId === props.data.communityId) {
        tabs.push({
            title: "Activity",
            path: "activity",
            icon: <AccessTimeFilledIcon/>,
            content: <EmployeeActivity communityId={props.data.communityId} self={props.self} isOwn={props.self.communityId === props.data.communityId}/>
        })
    }
    if (props.self.communityId === props.data.communityId) {
        tabs.push({
            title: "Records",
            path: "infractions",
            icon: <FindInPageIcon/>,
            content: <EmployeeRecords/>
        })
    }
    if (user.hasPermissions("user:viewRolesPage")) {
        tabs.push({
            title: "User Ranks",
            path: "user-ranks",
            icon: <AdminPanelSettingsIcon/>,
            content: <EmployeeUserRanks employee={props.data}/>
        })
    }
    if (user.hasPermissions("user:rank")) {
        tabs.push({
            title: "User Rank Logs",
            path: "rank-logs",
            icon: <DvrIcon/>,
            content: <EmployeeRankLogs communityId={props.data.communityId}/>
        })
    }

    const tabIndex = tabs.findIndex((t) => t.path === params.tab) ?? 0

    function changeTab(index: number) {
        const tabValue = tabs[index].path
        const newUrl = generateProfilePath(props.data.communityId, tabValue)
        navigate(newUrl)
    }

    return (

        <Grid container spacing={2} sx={{padding:"20px"}}>

            <ProfileInfo data={props.data} inactivityNotice={props.inactivity}/>

            <Grid container sx={{pt:2}} spacing={2}>
                <Grid item xs={12}>
                        <TabContext value={tabIndex.toString()}>
                            <Paper sx={{marginBottom:2}} variant={"outlined"}>
                                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                                    <TabList onChange={(_, newValue) => {
                                        setSearchParams(searchParams)
                                        changeTab(parseInt(newValue))
                                    }} variant="scrollable"
                                             scrollButtons="auto">
                                        {tabs.map((data, idx) =>
                                            <Tab icon={data.icon} label={data.title} value={idx.toString()} key={data.title}/>
                                        )}
                                    </TabList>
                                </Box>
                            </Paper>
                            <Paper variant={"outlined"}>
                            {tabs.map((data, idx) =>
                                <TabPanel value={idx.toString()} key={data.title}>
                                    {tabIndex === idx ? data.content : <></>}
                                </TabPanel>
                            )}
                            </Paper>
                        </TabContext>
                </Grid>

            </Grid>

        </Grid>

    )

}

export default ProfilePageWithRedirect;