import * as React from 'react';
import {TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator} from '@mui/lab';
import Typography from '@mui/material/Typography';
import {Alert, Avatar, Button, Paper, Snackbar, Stack, useTheme} from "@mui/material";
import {formatTimestamp} from "../../utils";
import FiberManualRecordOutlined from '@mui/icons-material/FiberManualRecordOutlined';
import {RecentActivityResponse} from "../../data/RecentActivityResponse";
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import {Link as RouterLink} from "react-router-dom";
import {RankResponse} from "../../data/RankResponse";

import '../../assets/rank-icons/styles.css';
import {makeApiCall, UseApiCallResponseData} from "../../hooks/CancellableApiCall";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {UserContextProps} from "../../hooks/useUser";
import {useCallback, useState} from "react";
import {AlertType} from "../../data/AlertType";

const defaultBackgroundColor = "#ff0000"
const backgroundColorsByType = {
    promotion: "#39CC65",
    award: "#29b6f6",
    announcement: "#30C0B4",
}

const defaultIcon = <ErrorOutlinedIcon fontSize="large"/>
let defaultIcons = {
    promotion: <FiberManualRecordOutlined fontSize="large"/>,
    award: <MilitaryTechOutlinedIcon/>,
    announcement: <CampaignOutlinedIcon/>,
}

const referenceButtonInfo = {
    promotion: ["profile", "/user/"],
    award: ["profile", "/user/"],
    announcement: ["announcement", "/announcement/"],
}

interface PoliceEventProps {
    event: RecentActivityResponse
    policeRanks?: RankResponse[]
    user: UserContextProps
    recentActivityApiCall?: UseApiCallResponseData<RecentActivityResponse[]>
}


export default function PoliceEvent(props: PoliceEventProps) {
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState<AlertType>("success")
    const [alertText, setAlertText] = useState("")
    const theme = useTheme()
    const event = props.event

    const setAlert = useCallback((type: AlertType, text: string) => {
        setAlertType(type)
        setAlertText(text)
        setShowAlert(true)
    }, [setAlertType, setAlertText, setShowAlert])

    let rankIconName: string | undefined = undefined

    if (event.type === "promotion") {
        let message = event.message
        let userRank = message.split(" to ")[1]

        props.policeRanks?.forEach(rank => {
            if (rank.displayName === userRank) {
                rankIconName = rank.iconName
            }
        })
    }

    let backgroundColor = backgroundColorsByType[event.type as keyof typeof backgroundColorsByType] ?? defaultBackgroundColor

    let icon

    if (event.type === "promotion") {
        icon = rankIconName ? <i className={`rank timeline-rank ${rankIconName}`}/> : defaultIcons.promotion
    } else if (event.type === "award") {
        icon = defaultIcons["award"]
    } else if (event.type === "announcement") {
        icon = defaultIcons["announcement"]
    } else {
        icon = defaultIcon
    }

    let referenceButton = <></>

    if (backgroundColor !== defaultBackgroundColor) {
        let referenceUrl = referenceButtonInfo[event.type as keyof typeof referenceButtonInfo][1] + event.referenceId ?? "/"
        referenceButton = <Button component={RouterLink} to={referenceUrl}
                                  variant="text" color="info" sx={{height: 25, mt: "4px", float: "right"}}
        >
            {referenceButtonInfo[event.type as keyof typeof referenceButtonInfo][0]}
        </Button>
    }

    return <TimelineItem key={event.id}>
        <TimelineSeparator>
            <Avatar
                sx={{backgroundColor: backgroundColor}}
                src={event.iconUrl}
            >
                {icon ?? defaultIcon}
            </Avatar>

            <TimelineConnector
                sx={{
                    backgroundColor: "rgba(182,182,192,0.45)"
                }}
            />
        </TimelineSeparator>
        <TimelineContent sx={{px: 2, mt: "-7px", mb: "40px"}}>
            <Paper sx={{p: 1}} variant="outlined">
                <Stack direction="column">
                    <Typography variant="body1" component="span">
                        {event.message}
                    </Typography>
                </Stack>
            </Paper>
            {referenceButton}
            <Stack sx={{pt: 1}} direction="row">
                <Typography color={theme.palette.text.secondary} variant="caption">
                    {formatTimestamp(event.date)}
                </Typography>
            </Stack>
        </TimelineContent>
        {props.user.hasPermissions("recentActivity:delete") ?
            <HighlightOffOutlinedIcon color="error" fontSize="small" onClick={() => {
                makeApiCall({
                    url: `/api/recent-activity/${props.event.id}`,
                    method: "DELETE",
                    onLoadedCallback: () => {
                        if (props.recentActivityApiCall) {
                            props.recentActivityApiCall.refresh()
                        }
                        setAlert("success", "Recent Activity deleted")
                    },
                    onError: () => {
                        setAlert("error", "Failed to delete")
                    }
                })
            }}/> : <></>}
        <Snackbar open={showAlert} autoHideDuration={5000} onClose={() => setShowAlert(false)}>
            <Alert severity={alertType} onClose={() => setShowAlert(false)} sx={{width: "100%"}} variant="filled">
                {alertText}
            </Alert>
        </Snackbar>
    </TimelineItem>
}