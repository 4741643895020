import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React, {useEffect, useState} from "react";
import {ObservationReportDetailResponse} from "../../data/ObservationReportDetailResponse";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContent from "@mui/material/DialogContent";
import {Box, InputLabel, LinearProgress, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {reduceLargeTextInput} from "../../utils";

interface ObservationReportViewFormProps {
    id: number
}

export const ObservationReportViewForm = (props: ObservationReportViewFormProps) => {
    const [open, setOpen] = useState(false)
    const [shouldLoad, setShouldLoad] = useState(false)
    const [hasBeenLoaded, setHasBeenLoaded] = useState(false)

    const loadObservationReport = useApiCall<ObservationReportDetailResponse>({
        initialUrl: `/api/observation-report/${props.id}`,
        deferLoading: !shouldLoad
    })

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (hasBeenLoaded || !shouldLoad) { return }
        setHasBeenLoaded(true)
        loadObservationReport.refresh()
    }, [open, loadObservationReport, hasBeenLoaded, shouldLoad]);

    let formContent = <></>
    if (open) {
        let orData = loadObservationReport.data
        let totalScore = (orData?.leadershipScore ?? 0) + (orData?.communicationScore ?? 0) +
            (orData?.investigativeScore ?? 0) + (orData?.knowledgeScore ?? 0) + (orData?.rankScore ?? 0)

        formContent =
            <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        width: 'fit-content'
                    }}
                >
                    <LoadingContent showLoadingSkeleton={true} isLoading={loadObservationReport.isLoading || !loadObservationReport.data}>
                        <DialogContent>
                            <InputLabel>Positives</InputLabel>
                            <Typography variant={"caption"}>{reduceLargeTextInput(orData?.positive ?? "", 100)}</Typography>
                        </DialogContent>
                        <DialogContent>
                            <InputLabel>Room for Improvement</InputLabel>
                            <Typography variant={"caption"}>{reduceLargeTextInput(orData?.improvement ?? "", 100)}</Typography>
                        </DialogContent>
                        <DialogContent>
                            <InputLabel>Duration of Patrol</InputLabel>
                            <Typography variant={"caption"}>{orData?.duration} Minutes</Typography>
                        </DialogContent>
                        <DialogContent>
                            <InputLabel>Summary</InputLabel>
                            <Typography variant={"caption"}>{reduceLargeTextInput(orData?.summary ?? "", 100)}</Typography>
                        </DialogContent>
                        <DialogContent>
                            <InputLabel>Leadership Score ({orData?.leadershipScore})</InputLabel>
                            <InputLabel>Communication Score ({orData?.communicationScore})</InputLabel>
                            <InputLabel>Investigation Score ({orData?.investigativeScore})</InputLabel>
                            <InputLabel>Law / Policy Score ({orData?.knowledgeScore})</InputLabel>
                            <InputLabel>Rank Score ({orData?.rankScore})</InputLabel>
                            <InputLabel>Total Score ({totalScore} / 24)</InputLabel>
                            <LinearProgress variant={"determinate"} color={"secondary"} value={(totalScore * 4) + 4}/>
                        </DialogContent>
                    </LoadingContent>
                </Box>
            </Dialog>
    }

    return (
        <>
            <VisibilityIcon color={"info"} onClick={() => {
                setShouldLoad(true)
                setOpen(true)
            }}/>
            {formContent}
        </>
    )
}