interface UserApplicationStatusInter {
    key: string
    displayName: string
}

export const userApplicationStatus: UserApplicationStatusInter[] = [
    { key: "pending", displayName: "In Progress" },
    { key: "passed", displayName: "Passed" },
    { key: "failed", displayName: "Failed" },
    { key: "pending_marker", displayName: "Manual Marking Required" },
    { key: "withdrawn", displayName: "Withdrawn" },
    { key: "administrative_fail", displayName: "Administrative Fail" },
]

export const UserApplicationStatus: Map<string, UserApplicationStatusInter> = new Map(userApplicationStatus.map(i => [i.key, i]))