import {useEffect, useRef} from "react";

interface UseTimerProps {
    interval: number
    callback: () => void
}

interface UseTimerResult {

}


export const useTimer : (props: UseTimerProps) => UseTimerResult = (props) => {
    const callback = useRef(props.callback)

    useEffect(() => {
        callback.current = props.callback
    }, [props.callback])

    useEffect(() => {
        const intervalRef = setInterval(() => {
            callback.current()
        }, props.interval)

        return () => {
            clearInterval(intervalRef)
        }
    }, [props.interval])

    return {}
}