import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {LoadingContent} from "../LoadingContent";
import React from "react";
import useApiCall from "../../hooks/CancellableApiCall";
import {formatTimestamp} from "../../utils";
import {UserRankLogResponse} from "../../data/UserRankLogResponse";
import {UserRankChangeType} from "../../data/UserRankChangeType";

interface EmployeeRankLogsProps {
    communityId: string,
}

interface EmployeeRankLogsContentProps {
    logs: UserRankLogResponse[]
}

function LogRow(props: {log: UserRankLogResponse}) {
    return <TableRow>
        <TableCell> {UserRankChangeType.get(props.log.type)?.displayName} </TableCell>
        <TableCell> {props.log.oldRankDisplayName} </TableCell>
        <TableCell> {props.log.rankDisplayName} </TableCell>
        <TableCell> {formatTimestamp(props.log.date)} </TableCell>
    </TableRow>
}

function EmployeeRankLogsContent(props: EmployeeRankLogsContentProps) {
    return <>
    <Box sx={{overflowX:"auto"}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Old Rank</TableCell>
                    <TableCell>New Rank</TableCell>
                    <TableCell>Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.logs?.map(log => {
                            return <LogRow key={log.id} log={log}/>
                        }
                    )
                }
            </TableBody>
        </Table>
    </Box>
    </>
}

export const EmployeeRankLogs = (props: EmployeeRankLogsProps) => {
    const logData = useApiCall<UserRankLogResponse[]>({
        url: `/api/rank/${props.communityId}/logs`,
    })

    return <LoadingContent isLoading={
        logData.isLoading || !logData
    }>
        <EmployeeRankLogsContent logs={logData.data!!}/>
    </LoadingContent>
}