import useApiCall from "../../hooks/CancellableApiCall";
import {LoadingContent} from "../LoadingContent";
import React from "react";
import {UserContextProps} from "../../hooks/useUser";
import {RecordNoteResponse} from "../../data/RecordNoteResponse";
import {Box, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {RecordNoteRow} from "./EmployeeInfractions";
import {IssueRecordNoteForm} from "./IssueRecordNoteForm"
import {ProfileResponse} from "../../data/ProfileResponse";

interface EmployeeNotesProps {
    user: UserContextProps
    employee: ProfileResponse
    searchRoot: string
}


interface EmployeeNotesContentProps extends EmployeeNotesProps {
    notes: RecordNoteResponse[]
}

function EmployeeNotesContent(props: EmployeeNotesContentProps) {
    let managementRow = <></>
    if (props.user.hasPermissions(["professionalStandards:modifyRecordNote"])) {
        managementRow = <TableCell>Actions</TableCell>
    }

    return <Stack spacing={4}>
        <Box sx={{overflowX: "auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Type of Note</TableCell>
                        <TableCell>Date of Note</TableCell>
                        <TableCell>Reason for Note</TableCell>
                        <TableCell>Supervisor</TableCell>
                        {managementRow}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.notes?.map((note) => {
                        return <RecordNoteRow showSupervisor={true} searchRoot={props.searchRoot} key={note.id} note={note} user={props.user}/>
                    })}
                </TableBody>
            </Table>
        </Box>
        <IssueRecordNoteForm employee={props.employee} searchRoot={props.searchRoot}/>
    </Stack>
}

export const EmployeeNotes = (props: EmployeeNotesProps) => {
    const loadNotes = useApiCall<RecordNoteResponse[]>({
        initialUrl: `/api/records/notes/${props.employee.communityId}`
    })

    return (
        <LoadingContent isLoading={loadNotes.isLoading}>
            <EmployeeNotesContent searchRoot={props.searchRoot} user={props.user} employee={props.employee} notes={loadNotes.data!!}/>
        </LoadingContent>
    )
}